'use strict';

define('vb/private/action/actionHelper',[
  'vb/binding/expression', 'vb/private/constants', 'acorn',
], (Expression, Constants, Acorn) => {
  /**
   * list of allowed scopes under $base
   * @type {string[]}
   */
  const ALLOWED_BASE_SUB_SCOPES = ['application', 'flow', 'page', 'fragment', 'global'];

  /**
   * This helper class is used by assign variable functions to get values from variable expressions and perform
   * pick operations
   */
  class ActionHelper {
    /**
     * Creates a new Context for an action so that additional actions within the action chain can be invoked.
     * @param {Object} availableContexts available contexts to create a new context with (required)
     * @param {Action} action the action for which we are creating a new context (required)
     * @param additionalContexts Additional contexts to add onto the available contexts
     */
    static createNewContext(availableContexts, action, additionalContexts = {}) {
      if (availableContexts === null || availableContexts === undefined) {
        throw new Error('availableContexts must be provided to create a new context ');
      }

      if (action === null || action === undefined) {
        throw new Error('action must be provided to create a new context ');
      }

      // copy the contexts (shallow clone)
      const contexts = availableContexts.clone();

      contexts[Constants.ContextName.CURRENT] = {};

      // add the new ones
      Object.entries(additionalContexts).forEach((entity) => {
        // eslint-disable-next-line prefer-destructuring
        contexts[entity[0]] = entity[1];
      });

      // if we have the original metadata key name, make an accessor on the available contexts with the given alias
      // currently this creates a top-level property with this name (ex. 'myAction.data' is bindable)
      const alias = action.alias || action.metadataKey;
      if (alias) {
        contexts.addAccessor(alias, () => contexts[Constants.ContextName.CURRENT]);
      }

      return contexts;
    }

    /**
     * Parse the given expression and analyze its abstract syntax tree. The return value is a stack containing
     * name-value pairs of all the evaluated properties. The bottom of the stack should be one of the built-in
     * scope variables. For example, given the expression $page.variables.target, the stack would look at follow:
     *
     * stack:
     * 0: { name: '$page', value: [page context] }
     * 1: { name: 'variables', value: [namespace object] }
     * 2: { name: 'target', value: [value of target] }
     *
     * @param expr the expression string to parse and analyze
     * @param contexts the available contexts used for evaluating scope variables
     * @returns {[*]} the stack containing name-value pairs of all the evaluated properties
     */
    static analyzeExprAst(expr, contexts) {
      const ast = Acorn.parse(expr, { ecmaVersion: 'latest' });
      if (ast.body.length !== 1) {
        throw new Error(`The target of an assignment must be a single expression: ${expr}`);
      }

      const stmt = ast.body[0];
      if (stmt.type !== 'ExpressionStatement') {
        throw new Error(`The target of an assignment must be an expression: ${expr}`);
      }

      const stack = [{ name: 'contexts', value: contexts }];
      ActionHelper.analyzeExprNode(stmt.expression, expr, stack);

      // remove the contexts
      stack.splice(0, 1);

      return stack;
    }

    /**
     * Analyze an expression node.
     *
     * @param exprNode an expression node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    static analyzeExprNode(exprNode, expr, stack) {
      const type = exprNode.type;

      if (type === 'Identifier') {
        ActionHelper.evalAndPushPropValue(exprNode.name, stack);
      } else if (type === 'MemberExpression') {
        ActionHelper.analyzeObjNode(exprNode.object, expr, stack);
        ActionHelper.analyzePropNode(exprNode.property, expr, stack);
      } else {
        throw new Error(`Invalid expression: ${this.getExprFragment(exprNode, expr)}`);
      }
    }

    /**
     * Evaluate the property value for the given propName by peeking at the stack. The result is pushed
     * onto the stack as a name-value pair.
     *
     * @param propName the property name
     * @param stack the stack to peak and push the result name-value pair
     */
    static evalAndPushPropValue(propName, stack) {
      const parentObj = stack[stack.length - 1];
      const value = parentObj && parentObj.value ? parentObj.value[propName] : undefined;
      stack.push({ name: propName, value });
    }

    /**
     * Analyze an object node.
     *
     * @param objNode and object node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    static analyzeObjNode(objNode, expr, stack) {
      const type = objNode.type;

      if (type === 'Identifier') {
        ActionHelper.evalAndPushPropValue(objNode.name, stack);
      } else if (type === 'MemberExpression') {
        ActionHelper.analyzeExprNode(objNode, expr, stack);
      } else {
        throw new Error(`Invalid object expression: ${this.getExprFragment(objNode, expr)}`);
      }
    }

    /**
     * Analyze a property node.
     *
     * @param propNode a property node
     * @param expr the expression string
     * @param stack the stack containing name-value pairs of all the evaluated properties
     */
    static analyzePropNode(propNode, expr, stack) {
      const type = propNode.type;
      let propName;

      if (type === 'Literal') {
        propName = propNode.value;
      } else if (type === 'Identifier') {
        propName = propNode.name;
      } else {
        // if the property node is not a literal or identifier, extract the expression fragment for the property
        // node and directly evaluate it
        const contexts = stack[0].value;
        const propExpr = ActionHelper.getExprFragment(propNode, expr);
        propName = Expression.createFromString(propExpr, contexts)();
      }

      ActionHelper.evalAndPushPropValue(propName, stack);
    }

    /**
     * Extract an expression fragment from the given expr using the node's start and end indices.
     *
     * @param exprNode an expression node
     * @param expr the expression string from which to extract the fragment
     * @returns {string}
     */
    static getExprFragment(exprNode, expr) {
      return expr.substring(exprNode.start, exprNode.end);
    }

    /**
     * Return an error for missing namespace in the expression
     *
     * @param  {String} targetExpr the target expression
     * @return {Error}             the error object
     */
    static getMissingNamespaceError(targetExpr) {
      return new Error(`No namespace provided for target expression ${targetExpr}.`);
    }

    /**
     * Verify that an expression with base is valid. This method is called for expression having
     * $base.x or $extension.base.x
     * @param  {String} targetExpr the target expression
     * @param  {Array} stack       an array of object with name and value for each level of the expression
     * @return {Object}            the next scope to be validated
     */
    static checkBase(targetExpr, stack) {
      let scope = stack[0].value;

      if (stack.length < 3) {
        throw ActionHelper.getMissingNamespaceError(targetExpr);
      }

      const baseName = stack[1].name;
      if (baseName !== Constants.VariableNamespace.VARIABLES) {
        if (!ALLOWED_BASE_SUB_SCOPES.includes(baseName)) {
          throw ActionHelper.getInvalidNamespaceError(baseName, targetExpr);
        }

        // Change the scope to be at $base.application, $base.flow or $base.page
        scope = stack[1].value;

        // and remove page, flow or application from stack
        stack.splice(1, 1);
      }

      return scope;
    }

    /**
     * Return an error for an invalid namespace in the expression
     *
     * @param  {String} namespace   the invalid namespace
     * @param  {String} targetExpr  the target expression
     * @return {Error}              the error object
     */
    static getInvalidNamespaceError(namespace, targetExpr) {
      return new Error(`Invalid namespace ${namespace} in target expression ${targetExpr}.`);
    }

    /**
     * Return an error for missing variable name in the expression
     *
     * @param  {String} targetExpr the target expression
     * @return {Error}             the error object
     */
    static getMissingVariableNameError(targetExpr) {
      return new Error(`No variable name provided in target expression ${targetExpr}.`);
    }
  }

  return ActionHelper;
});

