'use strict';

define('vb/private/action/jsonChainRunner',['vb/private/action/baseChainRunner', 'vb/private/action/actionChain', 'vb/private/utils'],
  (BaseChainRunner, JsonActionChain, Utils) => {
    class JsonChainRunner extends BaseChainRunner {
      /**
       * Create an action chain instance from the JSON descriptor.
       *
       * @param chainSource JSON descriptor for the chain
       * @returns {*}
       * @overrides BaseChainRunner.createChain
       */
      createChain(chainSource) {
        return new JsonActionChain(BaseChainRunner.extractChainId(this.chainId), chainSource);
      }

      /**
       * Load the JSON descriptor file for the chain located at the given chainPath.
       *
       * @param chainPath location of the source file for the chain
       * @returns {Promise}
       * @overrides BaseChainRunner.loadChainSource
       */
      // eslint-disable-next-line class-methods-use-this
      loadChainSource(chainPath) {
        return Utils.getRuntimeEnvironment()
          .then((re) => re.getTextResource(`${chainPath}.json`))
          .then((source) => Utils.parseJsonResource(source));
      }

      /**
       * Since the result of a JSON chain is already an outcome, simply return the outcome.
       *
       * @param result
       * @returns {{result: *, name: string}}
       * @overrides BaseChainRunner.coerceResultToOutcome
       */
      // eslint-disable-next-line class-methods-use-this
      coerceResultToOutcome(result) {
        return result;
      }

      /**
       * Since any error thrown from a JSON chain means there's a bug in the application code or
       * VBRT, we simply rethrow the error.
       *
       * @param error error to coerce
       * @returns {{name: string, result: {message: {summary}, error, payload}}|{result, name}|*}
       */
      // eslint-disable-next-line class-methods-use-this,no-unused-vars
      coerceErrorToOutcome(error) {
        throw error;
      }

      /**
       * For JSON chain, always rethrow the error.
       *
       * @param error the error to be reported
       * @param context the context object for the chain
       * @param rethrowError if true, rethrow the error
       * @returns {Promise}
       * @overrides BaseChainRunner.handleError
       */
      // eslint-disable-next-line class-methods-use-this,no-unused-vars
      handleError(error, context, rethrowError) {
        throw error;
      }

      /**
       * For backwards compatibility, a JSON chain is executed inside its calling container unless
       * the behavior is overridden by other factor.
       *
       * @returns {boolean}
       */
      // eslint-disable-next-line class-methods-use-this
      shouldUseCallingContainerForExecution() {
        return true;
      }
    }

    return JsonChainRunner;
  });

