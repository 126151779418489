'use strict';

define('vb/private/urlMapper',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/serviceUtils',
  'vb/private/services/servicesLoader',
  'vb/private/services/serviceConstants',
  'urijs/URI',
], (Constants, Log, Utils, ServiceUtils, ServicesLoader, ServiceConstants, URI) => {
  const logger = Log.getLogger('/vb/private/urlMapper');

  const DEFAULT_SKIP_CATALOG_TYPES = ['ics'];

  // map both 'backends' and 'services'
  const CATALOG_OBJECTS_TO_SKIP = [];

  const ADF_BC_DESCRIBE_SUFFIX = '/describe';

  // fewest number of segments that we need for a match, when doing sub-mapping, where we remove segments to allow
  // for more permissive matching (FA services may reference other services that require this, for the dyn ui case).
  // if the 'segmentMinimum' configuration is lower than this, it will use a minimum of 1.
  // note that, if the service is defined with a base URL with fewer segments than this, it will still match;
  // this minimum only limits the sub-mappings.
  const SEGMENT_MINIMUM_MATCH = 2;

  /**
   * this is to handle the Dynamic UI case where it is fetching service metadata (via operationRef/x-link).
   * When matching to "services", we don't normally include the information in its "paths" object, if any.
   * But if we intercept a /describe fetch, we should merge the "paths" headers, stored in the 'metadata' object,
   * with the normal services headers.
   *
   * @todo: JET should be able to add the proper 'accept' header to /describe fetch, but there could be other stuff
   *
   * @param m the mapping data; this is modified
   * @returns {{metadata}|*} the passed in mapping data, possibly modified
   */
  function useMetadata(m) {
    const mapping = m; // eslint
    if (mapping && mapping.metadata) {
      /**
       *  only apply the metadata for a /describe if
       * 1) the "paths" operation also ends with "/describe"
       * 2) there are static headers in the x-vb.headers
       */
      const requestInit = mapping || {};
      if (mapping.metadata.path && mapping.metadata.path.endsWith(ADF_BC_DESCRIBE_SUFFIX)) {
        const mdHeaders = (mapping.metadata.extensions && mapping.metadata.extensions.headers) || {};
        // if we have at ;least one static header
        if (Object.keys(mdHeaders).length > 0) {
          const metadata = ServiceUtils.getExtensionsFromMetadata('', mapping.metadata, requestInit);

          delete metadata.headers[Constants.Headers.VB_INFO_EXTENSION]; // unnecessary, reconstructed by requester
          // just take the headers, we dont need anything else
          mapping.headers = metadata.headers;
        }
      }
    }
    return mapping;
  }

  const SPECIAL_CASE_MAP = {
    [ADF_BC_DESCRIBE_SUFFIX]: {
      // this uses the "paths" (metadata) x-vb section if it ends with /describe (in addition to server object x-vb)
      augmentMapping: useMetadata,
    },
  };

  /*
   * for sorting mappings, using:
   * - all 'submapping' mappings should appear after non-submapping (note 1)
   * - urls with an equal number of segment are sorted by namespace (note 2)
   * - urls with an equal number of segments and equal namespaces are sorted alphabetically
   * - urls with more segments appear before urls with fewer (more exact match)
   *
   * (this function needs to be bound to the UrlMapper, see UrlMapper constructor)
   * note 1:
   * "submappings" are the URLs we create by removing segments from what we have from a fully resolved URL.
   * For example, if the resolution of a service and backends results in:
   *   https://some/backend/another/backend/service/stuff
   * submappings would include
   *   https://some/backend/another/backend/service
   *   https://some/backend/another/backend/
   *   etc...
   *
   * note 2:
   * When an extension adds a one granular mapping for a URL, use it in preference to the base's.
   * Otherwise, prefer base for equal-length segment matches.
   *
   * @param a
   * @param b
   * @returns {number}
   */

  /**
   * Compare to mappings objects. See above for the sorting rules.
   *
   * @param {*} a
   * @param {*} b
   * @param {*} namespaces
   * @param {string} [aProp = 'url'] Name of the URL property to compare
   * @param {string} [bProp = 'url'] Name of the URL property to compare
   * @returns
   */
  function compareUrls(a, b, namespaces, aProp = 'url', bProp = 'url') {
    // make sure both URLs end on '/' so we can compare their segments correctly
    const urlA = Utils.addTrailingSlash(a[aProp]);
    const urlB = Utils.addTrailingSlash(b[bProp]);

    const asegs = urlA.split(Constants.PATH_SEPARATOR).length;
    const bsegs = urlB.split(Constants.PATH_SEPARATOR).length;

    if (asegs === bsegs) {
      // sort by namespaces if they have the same number of segments
      if (namespaces) {
        const aNS = namespaces.indexOf(a.namespace);
        const bNS = namespaces.indexOf(b.namespace);
        const aWeight = (aNS >= 0) ? namespaces.length - aNS : aNS;
        const bWeight = (bNS >= 0) ? namespaces.length - bNS : bNS;
        if (aWeight !== bWeight) {
          return Math.sign(aWeight - bWeight);
        }
      }

      // sort alphabetically, if the have the same number of segments and namespace
      // eslint-disable-next-line no-nested-ternary
      return urlA === urlB ? 0 : (urlA < urlB ? -1 : 1);
    }
    return bsegs - asegs;
  }

  /**
   * Compare to mappings objects. See above for the sorting rules.
   *
   * @param {*} a
   * @param {*} b
   * @param {string} aProp
   * @param {*} bProp
   * @returns {number}
   */
  function compareMappingsByUrl(a, b, aProp = 'url', bProp = 'url') {
    // submappings should have lower priority
    if (a.submapping || b.submapping) {
      if (a.submapping && !b.submapping) {
        return 1;
      }
      if (b.submapping && !a.submapping) {
        return -1;
      }
    }
    return compareUrls(a, b, this.namespaces, aProp, bProp);
  }

  /**
   * utility for mapping intercepted URLs that do not already have 'vb-info-extension' information.
   *
   * those can be either:
   * - requireJS-loaded URLs, via XHR or <script>, which will not typically have a mapping
   * - REST urls, which we will match against the current Catalog "services"
   *
   * the returned mapping will be the same information normally included in the 'vb-info-extension'
   */
  class UrlMapper {
    /**
     *
     * @param {ProtocolRegistry} protocolRegistry
     * @param {string} activeProfile
     * @param {Object} swConfig window.SERVICE_WORKER_CONFIG
     */
    constructor(protocolRegistry, activeProfile, swConfig = {}) {
      this.protocolRegistry = protocolRegistry;

      this.protocolRegistry.registryModified.add(() => this.handleProtocolRegistryChange());

      // this prioritizes possible mappings, by making referenced catalog 'protocol chains' higher priority.
      this.protocolRegistry.opened.add((originalUrl, catalogObject, namespace) => this
        .handleCatalogReference(originalUrl, catalogObject, namespace));

      // these are configurable, but NOT PUBLIC
      this.catalogTypesToSkip = (swConfig.urlMapping && swConfig.urlMapping.typesToSkip) || DEFAULT_SKIP_CATALOG_TYPES;
      this.segmentMinimum = (swConfig.urlMapping && swConfig.urlMapping.segmentMinimum) || SEGMENT_MINIMUM_MATCH;

      ServicesLoader.opened.add((id, urlInfoList, namespace) => this
        .handleServiceReferences(urlInfoList, namespace));

      // URL mapping is contructed by iterating over the resolved objects of the ProtocolRegistry. After that mapping
      // is built, that mapping is (potentially) overriden with URLs of services that were loaded by VB RT.
      // Those resolved objects may be different from the ones from the ProtocolRegistry as the server variables
      // could be different. We cache those values in this _externalMapping so we can re-apply them after URL mapping
      // gets reconstructed from the ProtocolRegistry.
      this._externalMapping = {};

      this.namespaces = []; // a priority-order list of namespace names
      this.comparitor = compareMappingsByUrl.bind(this);
    }

    /**
     * returns the "tree" of catalog self-references, with urls fully expanded,
     * but flattened into two arrays: 'backends', and 'services'.
     *
     * for example, a 'services' that references a 'backends' ONE that references a 'backends' TWO would return:
     * services.one.url = vb-catalog://backends/one/sss
     * backends.one.url = vb-catalog://backends/two/ooo
     * backends.two.url = https://fake/ttt
     *
     * {
     *   backends: [ {name:one, url:https://fake/ttt/ooo, ... }, {name:two, url:https://fake/ttt, ...}],
     *   services: [ {name:one, url:https://fake/ttt/ooo/sss, ... }}]
     * }
     *
     * note that more than one item may have the same "url"; currently, we just match the first one,
     * but we may enhance that, to look at other criteria, if needed.
     *
     * @returns {Promise}
     */
    getUrlMap() {
      if (!this.urlMapPromise) {
        this.urlMapPromise = this.protocolRegistry.getTree()
          .then((tree) => {
            // first, collect one catalog object for each URL - take the one with the longest 'chain'
            // for example, backend 'foo' can reference backend 'bar', but not add anything to the URL.
            // so when matching URL prefixes, we assume we want the credentials for "foo".
            const objectsForUrl = {};
            tree.forEach((catalogObject) => {
              // build the priority list, first ones take precendence
              this.addNamespace(catalogObject.namespace);

              const type = catalogObject.type || (catalogObject.chain[0] && catalogObject.chain[0].type);

              if (type) {
                if (CATALOG_OBJECTS_TO_SKIP.indexOf(type) === -1) {
                  objectsForUrl[type] = objectsForUrl[type] || {};

                  const existing = objectsForUrl[type][catalogObject.url];
                  if (!existing || existing.chain.length < catalogObject.chain.length) {
                    objectsForUrl[type][catalogObject.url] = catalogObject;
                  }
                }
              } else {
                logger.warn(`unknown catalog object type found during url mapping ${JSON.stringify(catalogObject)}`);
              }
            });

            // now process the pared-down set of urls-mapped-to-catalogObject
            const mappings = {};
            Object.keys(objectsForUrl).forEach((type) => {
              mappings[type] = mappings[type] || [];

              Object.keys(objectsForUrl[type]).forEach((url) => {
                // at this point,the catalogObject is the raw structure that includes {services, backends, metadata},
                // so we create a simplified mapping entry, that contains the same information we use in the
                // services definition, when we merge the services header with the backend headers, etc.
                const catalogObject = objectsForUrl[type][url];

                // skip ICS, or what is specified; as of now, this config is not documented (not public)
                if (this.catalogTypesToSkip.indexOf(catalogObject.serviceType) >= 0) {
                  return;
                }

                if (url !== catalogObject.url) {
                  logger.warn('url mismatch during mapping, continuing');
                }

                const obj = UrlMapper.createMappingFromCatalogObject(catalogObject, type);

                this.addMapping(mappings[type], obj);
              });
            });
            // now sort by length
            Object.keys(mappings).forEach((type) => {
              mappings[type].sort(this.comparitor);
            });
            // now add external mapping from the callbacks
            this._addAllExternalMappings(mappings);
            return mappings;
          })
          .catch((err) => {
            logger.warn('Unable to initialize url mapping from catalog, so catalog will not be used.', err.toString());
            return {
              [ServiceConstants.ExtensionTypes.SERVICES]: [],
            };
          });
      }
      return this.urlMapPromise;
    }

    /**
     * create our priority-order namespace list; assumes the tree gives us objects in this order
     *
     * @param {string} ns
     * @private
     */
    addNamespace(ns) {
      if (this.namespaces.indexOf(ns) < 0) {
        this.namespaces.push(ns);
      }
    }

    /**
     * called from listener of ProtocolRegistry
     *
     * @param catalogObject
     * @param {string} type of the catalog object. Value can be 'backends' or 'services.
     * @returns {Object|null} a mapping object, or null if catalog object should not be aded to the map.
     * @private
     */
    static createMappingFromCatalogObject(catalogObject, type) {
      // normalize the url
      const url = UrlMapper.normalizeUrl(catalogObject.url);

      const catalogExtension = ServiceUtils.transformResolvedCatalogObject(catalogObject, catalogObject.name, url, {});

      const metadata = (catalogExtension.services && catalogExtension.services.metadata);
      const namespace = catalogExtension.namespace;
      const version = catalogExtension.version;

      const mappingData = {
        url,
        type,
        metadata,
        namespace,
        version,
      };

      if (type === ServiceConstants.ExtensionTypes.BACKENDS) {
        if (!catalogObject.catalogUrl) {
          return null;
        }

        // Catalog URL that was resolved into the real URL
        // vb-catalog://backend/hcmApi => https://atgcdr02.fa.dc1.c9dev2.oraclecorp.com/hcmRestApi/11.13.18.05/
        // we want to match catalog URL and fetch the resolved one
        mappingData.urlToFetch = Utils.addTrailingSlash(url);
        // add a slash at the end of 'vb-catalog://backend/hcmApi/' so that we match it correctly with the requested url
        mappingData.url = Utils.addTrailingSlash(catalogObject.catalogUrl);
      }

      return Object.assign({}, catalogExtension.mergedExtensions, mappingData);
    }

    /**
     * called from the listener of ServiceLoader
     *
     * @param {Object} servicesObject
     * @param {string} namespace 'base'or <ext ID>
     * @returns {Object}
     * @private
     */
    static createMappingFromServicesObject(servicesObject, namespace) {
      const url = UrlMapper.normalizeUrl(servicesObject.url);
      return Object.assign({}, servicesObject, { url, type: ServiceConstants.ExtensionTypes.SERVICES, namespace });
    }

    /**
     * the public method, for getting a mapping information, is it exists.
     *
     * The mapping will contain everything in the combined "x-vb" for the found service/catalog object,
     * plus the typical additional properties (baseUrl, serviceName, etc).
     *
     * @todo: this currently includes a 'urlToFetch', in case the mapping wants to 'transform' the URL,
     * as is the case when we use the proxy to make an HTTP request.
     * However, the UrlMapperClient currently does NOT use this property, so reverse mapping
     * of HTTP requests is currently NOT supported.
     *
     * @param urlToMap
     * @returns {Promise}
     */
    getUrlMapping(urlToMap, mapBackends) {
      // this removes any default port on the URL (:443, etc.)
      const url = UrlMapper.normalizeUrl(urlToMap);

      return this.getUrlMap()
        .then((map) => {
          // we first look in the "services" of the catalog.json, for potential matches.
          const servicesMaps = map[ServiceConstants.ExtensionTypes.SERVICES] || [];
          const servicesMapping = this._findServiceMapping(servicesMaps, urlToMap, url);

          let found = servicesMapping;
          let backendMapping;

          if (!servicesMapping || servicesMapping.submapping) {
            const backendMaps = map[ServiceConstants.ExtensionTypes.BACKENDS] || [];
            // always try to resolve vb-catalog:// URLs, but match the resolved backends only when asked to
            backendMapping = this._findBackendMapping(backendMaps, urlToMap, mapBackends ? url : null);

            if (backendMapping) {
              if (servicesMapping) {
                // We have partial service matching and backend matching.
                // If we have matches for both backend and service select the 'better' one.
                // We are using sorting fucntion so smaller is better.
                if (compareUrls(backendMapping, servicesMapping, this.namespaces, 'matchedUrl', 'matchedUrl') < 0) {
                  found = backendMapping;
                  // remove urlToFetch as it only contains resolved backend's vb-catalog:// URL
                  delete backendMapping.urlToFetch;
                }
              } else {
                found = backendMapping;
              }
            }
          }

          // if we found it, we might need to add additional headers for mobile, http proxy
          // note: this will never happen for PWA, because a fetch for HTTP will never ake it to the
          // fetch handler plugins. but that might be a reasonable restriction?
          // otherwise, if we want consistent behavior, we should to disallow HTTP self-fetch.
          /**/
          if (found) {
            return this.isAnonymousPageAccessAllowed()
              .then((isPageAnonymousAllowed) => {
                // Use urlToMap as a parameter, as found.url may be just a substring of the urlToMap
                // that was used for mapping. found.urlToFetch is set only for mapping vb-catalog:// URLs.
                const urlToFetch = ServiceUtils.getHeadersAndUrlForPreprocessing(
                  found.headers,
                  found.urlToFetch || urlToMap,
                  isPageAnonymousAllowed,
                );

                if (urlToFetch) {
                  found.urlToFetch = urlToFetch;
                }

                // move the info ahead of warning to give it some context
                logger.info(`found mapping for ${url}`);

                if (found.headers[Constants.Headers.PROTOCOL_OVERRIDE_HEADER]) {
                  logger
                    .warn('URL mapping found an HTTP request; this will not work for Progressive Web Apps',
                      'Requests should be served from HTTPS');
                }

                // clean it up a little, remove our internal values
                delete found.metadata;

                logger.info(`mapping: ${JSON.stringify(found)}`);
                if (servicesMapping && found === backendMapping) {
                  // we found both service and backend mappings, but backend one won
                  logger.info(`mapping to a service ignored: ${JSON.stringify(servicesMapping)}`);
                }

                // clean it up more after logging
                delete found.submapping;
                delete found.matchedUrl;

                return found;
              });
          }
          return undefined;
        })
        .then((found) => {
          const mapping = found;

          // remove the header, only because its unnecessary; the mapping is the same information
          if (mapping && mapping.headers[Constants.Headers.VB_INFO_EXTENSION]) {
            delete mapping.headers[Constants.Headers.VB_INFO_EXTENSION];
          }
          return mapping;
        });
    }

    /**
     * Find a match in services mappings
     *
     * @param {Object} mappings
     * @param {string} urlToMap
     * @param {string} url
     * @returns {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    _findServiceMapping(mappings, urlToMap, url) {
      let found;
      mappings.some((obj) => {
        // first, check the 'paths' url.
        // normally, JET dynamic UI will have already loaded this but we should support
        // explicit fetch( '<some path>/describe') calls
        let matchesMetadataUrl = false;
        let matchedUrl;
        if (obj.metadata && obj.metadata.path) {
          matchesMetadataUrl = url.startsWith(obj.url + obj.metadata.path);
        }

        // now check the 'base' url for the service
        if (matchesMetadataUrl || url.startsWith(obj.url)) {
          found = Object.assign({ }, obj);
          found.headers = Object.assign({}, found.headers);

          // check if we need to use the 'metadata' from the service
          if (matchesMetadataUrl) {
            matchedUrl = obj.url + obj.metadata.path;
            useMetadata(found);
          } else {
            matchedUrl = obj.url;
            Object.keys(SPECIAL_CASE_MAP)
              .some((suffix) => {
                if (url.endsWith(suffix)) {
                  SPECIAL_CASE_MAP[suffix].augmentMapping(found);
                  return true; // stop looking
                }
                return false;
              });
          }
          found.matchedUrl = matchedUrl;
        }
        return found;
      });
      return found;
    }

    /**
     * Find a match in backends mappings
     *
     * @param {Object} mappings
     * @param {string} urlToMap
     * @returns {Object}
     */
    _findBackendMapping(mappings, urlToMap, url) {
      let found;
      // search for vb-catalog:// URL matches first and calculate resolved URL to fetch
      mappings.some((obj) => {
        if (urlToMap.startsWith(obj.url)) {
          const subpath = urlToMap.substring(obj.url.length);
          const urlToFetch = obj.urlToFetch + subpath;

          found = Object.assign({ }, obj, { urlToFetch });
          found.headers = Object.assign({}, found.headers);
          found.matchedUrl = obj.url;
        }
        return found;
      });

      // find a backend whose resolved URL matches urlToMap
      if (url && !found) {
        // generate sorted list of backends by their resolved URLs
        let secondaryMap = mappings.$secondary;
        if (!secondaryMap) {
          secondaryMap = mappings.slice().sort(
            (a, b) => compareUrls(a, b, this.namespaces, 'urlToFetch', 'urlToFetch'),
          );
          mappings.$secondary = secondaryMap;
        }

        secondaryMap.some((obj) => {
          if (url.startsWith(obj.urlToFetch)) {
            found = Object.assign({ }, obj);
            found.headers = Object.assign({}, found.headers);
            found.matchedUrl = obj.urlToFetch;
            // urlToFetch is only used when we are handling vb-catalog:// URLs
            // which is done in the above mappings.some(...) code.
            delete found.urlToFetch;
          }
          return found;
        });
      }
      return found;
    }

    /**
     * Callback for ProtocolRegistry changes.
     *
     * @private
     */
    handleProtocolRegistryChange() {
      this.urlMapPromise = null;
    }

    /**
     * The callback invoked when new catalog (catalog.json) is loaded by the ProtocolRegistry.
     *
     * Use references to the catalog to make those urls earlier in the matching list,
     * since there can be multiple paths (chains) through the catalog to the same path.
     *
     * @param {string} originalUrl
     * @param {Object} catalogObject
     * @param {string} namespace
     * @returns {Promise<>}
     * @private
     */
    handleCatalogReference(originalUrl, catalogObject, namespace) {
      // only need to look at catalog references that actually use a protocol,
      // and have a 'chain' ov service/backend objects. otherwise, it did not really vector through the catalog.
      if (!catalogObject || !catalogObject.chain || !catalogObject.chain.length) {
        return Promise.resolve();
      }

      return this.getUrlMap()
        .then((map) => {
          // Iterating over the chain does not really make sense. This is because the catalogOject.url which is used for
          // mapping is resolved correctly in the context of the whole chain.
          // If we iterate over all elements in the chain we use the same url over and over, but this.addMapping()
          // saves us from overriding existing ones.

          // Do the same here as we do in the getUrlMap():
          const type = catalogObject.type || (catalogObject.chain[0] && catalogObject.chain[0].type);
          if (type && CATALOG_OBJECTS_TO_SKIP.indexOf(type) === -1) {
            const mappingData = UrlMapper.createMappingFromCatalogObject(catalogObject, type);
            this._addExternalMapping(map, type, mappingData);
          }
        });
    }

    /**
     * The callback invoked when new service definition (openapi3.json) is loaded by the OpenApiServiceDefFactory.
     *
     * The service listener was called because VB opened it, so put the info in our mappings.
     *
     * @param urlInfoList {Array<{url, headers, authentication, ...}>}
     * @param namespace {string}
     * @returns {Promise<>}
     * @private
     */
    handleServiceReferences(urlInfoList, namespace) {
      if (Array.isArray(urlInfoList)) {
        return this.getUrlMap()
          .then((map) => {
            urlInfoList.forEach((urlInfo) => {
              const mappingData = UrlMapper.createMappingFromServicesObject(urlInfo, namespace);
              this._addExternalMapping(map, ServiceConstants.ExtensionTypes.SERVICES, mappingData);
            });
          });
      }
      return Promise.resolve();
    }

    /**
     * Adds mapping object which is result of the catalog or service being opened.
     *
     * @param {Object} map
     * @param {string} type
     * @param {Object} mappingData
     * @private
     */
    _addExternalMapping(map, type, mappingData) {
      if (type && mappingData) {
        // cache it to reapply it on top of the registry tree
        const externalMap = this._externalMapping[type] || (this._externalMapping[type] = []);
        if (map) {
          // eslint-disable-next-line no-param-reassign
          const typeMap = map[type] || (map[type] = []);
          if (this.addMapping(typeMap, mappingData)) {
            externalMap.push(mappingData);
          }
        } else {
          externalMap.push(mappingData);
        }
      }
    }

    /**
     * Adds back all extra mappings after we re-load from the the ProtocolRegistry tree.
     *
     * @param {Object} mappings
     * @private
     */
    _addAllExternalMappings(mappings) {
      Object.keys(this._externalMapping).forEach((type) => {
        const externalTypeMappings = this._externalMapping[type];
        const typeMap = mappings[type] || (mappings[type] = []);

        externalTypeMappings.forEach((mappingData) => {
          this.addMapping(typeMap, mappingData);
        });
      });
    }

    /**
     * creates an internal mapping, called from init, and listeners.
     *
     * the entire map contains properties of arrays, where the arrays are separated by 'type': 'services' or 'backends'.
     * The array is sorted using a simple reverse alphabetic sort based on URL,
     * so longer URLs appear earlier in the list, and just to give some expected order to the search.
     *
     * matching longer fist means, more granular (more segments) first.
     *
     * @param mapArray the appropriate 'services' or 'backends' array to add to
     * @param mappingData
     * @private
     */
    addMapping(mapArray, mappingData) {
      //
      if (mapArray) {
        const uri = new URI(mappingData.url);

        if (mappingData.type === ServiceConstants.ExtensionTypes.SERVICES
          && uri.protocol() === Constants.VbProtocols.CATALOG) {
          logger.warn('Skip URL mapping for a service that resolves to vb-catalog.', mappingData.url);
          return false;
        }

        const found = mapArray && mapArray.find((o) => (o.url === mappingData.url));

        // do not add if it is already mapped, unless its one of our shortened ones.
        // we want to replace shortened ones with real ones, when possible.
        if (!found || found.submapping) {
          // we want to add a mapping for the full url, then the url-less-one-segment, then less-two-segments, etc
          mapArray.push(mappingData);

          // do not add submapping for the vb-catalog:// URLs
          if (uri.protocol() !== Constants.VbProtocols.CATALOG) {
            let segmentCount = uri.segment().length;

            // the config property is one-based; convert to zero-based, because URI does not count host as a segment
            const minimum = Math.max(this.segmentMinimum, 1) - 1;

            for (; segmentCount - minimum > 0; segmentCount -= 1) {
              uri.segment(segmentCount - 1, ''); // remove the last segment

              const shortenedMapping = Object
                .assign({ submapping: true }, mappingData, { url: uri.toString() });
              mapArray.push(shortenedMapping);
            }
          }

          // sort by segment count
          mapArray.sort(this.comparitor);
          // make sure secondary list is re-created on demand on next access
          delete mapArray.$secondary;
          return true;
        }
      }
      return false;
    }

    /**
     * get the auth for the current page.  defer loading router until needed, to avoid pulling in JET early
     * @returns {Promise<boolean>}
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    isAnonymousPageAccessAllowed() {
      return Utils.getResource('vb/private/stateManagement/router')
        .then((Router) => {
          const page = Router.getCurrentPage();
          return page && !page.isAuthenticationRequired();
        });
    }

    /**
     * remove the default port, to mormalise comparisons. use the default Request behavior, if possible.
     * On IE11, the polyfill does not remove the port.
     * @param {string} urlToClean=''
     * @returns {string}
     */
    static normalizeUrl(urlToClean = '') {
      let result;
      if (UrlMapper.portRemovedByRequest) {
        result = URI.parse(new Request(urlToClean).url);
      } else {
        result = URI.parse(urlToClean);
        if (result.port) {
          if (result.protocol === 'https' && result.port === '443') {
            result.port = null;
          } else if (result.protocol === 'http' && result.port === '80') {
            result.port = null;
          }
        }
      }
      // VBS-21077: host name is not case sensitive
      result.hostname = result.hostname && result.hostname.toLowerCase();
      // should we do same for the protocol?
      // result.protocol = result.protocol && result.protocol.toLowerCase();
      let url = URI.build(result).toString();

      // Request may have added a trailing slash, if it's just a host
      url = Utils.removeTrailingSlash(url);
      return url;
    }
  }

  // check if Request automatically removes the defaulty port - the polyfill on IE11 does not
  try {
    UrlMapper.portRemovedByRequest = new Request('https://foo:433/bar').url.indexOf(':433') < 0;
  } catch (err) {
    // We get here because the fetch polyfill has not been loaded on IE11, so we simply
    // set portRemoveByRequest to false.
    UrlMapper.portRemovedByRequest = false;
  }
  return UrlMapper;
});

