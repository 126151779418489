'use strict';

define('vb/private/vx/baseExtensionAdapter',[
  'vb/private/vx/noFetchExtensionAdapter',
  'vb/private/configuration',
  'vb/private/constants',
  'vbc/private/monitorOptions',
  'vbc/private/log',
], (
  NoFetchExtensionAdapter,
  Configuration,
  Constants,
  MonitorOptions,
  Log,
) => {
  const logger = Log.getLogger('/vb/stateManagement/vx/extensionAdapter', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  class BaseExtensionAdapter extends NoFetchExtensionAdapter {
    /**
     * @param {String} registryUrl  the url of the extension manager
     * @param {Object} vbInitConfig
     */
    constructor(registryUrl, vbInitConfig = globalThis.vbInitConfig || {}) {
      super(vbInitConfig);

      this.log = logger;
      /** @type {String} */
      this.registryUrl = registryUrl;
    }

    /**
     * Return the promise responsible for loading the 3 digests runtime. requirejsInfo and appUiInfo
     * WARNING: The order of each element in the array is important.
     * @returns {Promise<Array<Promise<Array<Object>>>>}
     */
    async initiateFetchManifest() {
      return this.loadDigests([
        Constants.Digest.RUNTIME,
        Constants.Digest.REQUIREJS_INFO,
        Constants.Digest.APP_UI_INFO,
      ]);
    }

    /**
     * Calculate the preferred version to use when loading extension
     * This function is only called once during the lifetime of an application
     *
     * @return {String} the version
     */
    // eslint-disable-next-line class-methods-use-this
    getExtensionVersion() {
      // Precedence is given to dev version that is specified using _preferExtensionVersion query parameter.
      // If query param is not specified, use the FA sandbox id.
      const queryParams = new URL(globalThis.location).searchParams;

      // The query param name used by DT to pass the extension version to use
      // in the preview (vbdt:preferExtensionVersion)
      let version = queryParams.get(Constants.dtExtVersionQueryParam);

      if (!version) {
        // The query param name used by FA for integration tests (fre:preferExtensionVersion)
        version = queryParams.get(Constants.freExtVersionQueryParam);

        if (!version) {
          version = Configuration.getSandboxId();
          if (version) {
            // Add the $ in front of the FA sandbox id and encode it
            version = `$${encodeURIComponent(version)}`;
          }
        }
      }

      return version;
    }

    /**
     * @param {string} requestUrl
     * @param {string} message
     * @return Promise<Object>
     */
    getFetchManifestPromise(requestUrl, message) {
      const mo = new MonitorOptions(
        MonitorOptions.SPAN_NAMES.LOAD_EXTENSION_MANIFEST, `extension manifest load ${requestUrl}`,
      );

      return this.log.monitor(mo, (registryLoadTimer) => fetch(requestUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Response not OK when fetching extension manifest at: ${requestUrl}`);
          }

          return response.json().then((result) => {
            this.log.greenInfo(message, 'loaded.', registryLoadTimer());
            return result;
          });
        })
        .catch((error) => {
          registryLoadTimer(error);

          throw error;
        }));
    }

    /**
     * Return a URL object initalized with the registry URL string
     *
     * @return {URL}
     */
    getUrl() {
      return new URL(this.registryUrl);
    }
  }

  return BaseExtensionAdapter;
});

