'use strict';

define('vbsw/private/plugins/tokenRelayHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils', 'vbsw/private/constants',
  'vbsw/private/plugins/authPreprocessorHandlerPlugin', 'vbc/private/log',
  'urijs/URI',
], (FetchHandlerPlugin, Utils, Constants, AuthPreprocessorHandlerPlugin, Log, URI) => {
  const logger = Log.getLogger('/vbsw/private/plugins/tokenRelayHandlerPlugin');

  /**
   * Handler plugin for handling token relay.
   */
  class TokenRelayHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params) {
      super(context);

      // used to cached in-memory version of the token for each token relay url
      this.cachedTokenPromises = {};

      // used to keep track of which token is currently invalid
      this.invalidateTokenPromises = {};

      // keeps track of tokens cached by the server that need to be invalidated
      this.invalidateServerCaches = {};

      // tolerance for the clock skew which can be configured by the app
      this.jwtClockSkewTolerance = (params && params.jwtClockSkewTolerance) || Constants.DEFAULT_CLOCK_SKEW_TOLERANCE;
    }

    static get tokenRelayUrlHeader() {
      return Constants.TOKEN_RELAY_URL_HEADER;
    }

    static get tokenRelayAuthenticationHeader() {
      return Constants.TOKEN_RELAY_AUTH_HEADER;
    }

    /**
     * This method is meant to be subclassed to add additional headers specified in tokenRelayAuthentication to
     * the token relay request.
     *
     * @param request the token relay request to add the headers
     * @param tokeRelayAuthentication contains additional headers to add to request
     * @param requestUrl the url for the original request
     */
    processTokenRelayAuthentication(request, tokeRelayAuthentication, requestUrl) {}

    /**
     * Get the authorization token header either from cache or from the token relay service.
     *
     * @param tokenRelayUrl the url for the token relay service
     * @param tokenRelayAuthentication additional authentication metadata for token relay
     * @param requestUrl the url for the original request
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @returns {Promise}
     */
    getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2, tokenCacheKey) {
      return this.getCachedToken(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2, tokenCacheKey)
        .then((cachedToken) => {
          if (cachedToken) {
            if (Utils.checkJwtExpiration(cachedToken.expiration, this.jwtClockSkewTolerance)) {
              // the token has expired, invalidate it and fetch a new one
              return this.invalidateCachedToken(tokenRelayUrl, undefined, tokenCacheKey).then(() => {
                logger.info('Token for', tokenRelayUrl, 'has expired. Fetching a new token.');

                return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication,
                  requestUrl, tokenRelay2, tokenCacheKey);
              });
            }

            // return the actual auth header
            return cachedToken.token.authenticationHeader;
          }

          return null;
        });
    }

    /**
     * Get the access token from cache or from the token relay service.
     *
     * @param {string} tokenRelayUrl the url for the token relay service
     * @param {object} tokenRelayAuthentication additional authentication metadata for token relay
     * @param {string} requestUrl the url for the original request
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @param {string} tokenCacheKey key to the access token cache
     * @param {boolean} runOAuth2CodeGrantFlow only run the code grant flow if true
     * @returns {Promise}
     */
    getCachedToken(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2, tokenCacheKey,
      runOAuth2CodeGrantFlow = true) {
      // cache the promise for getting the token so we don't make multiple calls to the token relay service
      let cachedTokenPromise = this.cachedTokenPromises[tokenCacheKey];

      if (!cachedTokenPromise) {
        // first try retrieving the token from the state cache
        cachedTokenPromise = this.stateCache.get(tokenCacheKey).then((cachedToken) => {
          if (cachedToken) {
            return cachedToken;
          }

          // TODO: temporary code for debugging purpose. Remove this in the future.
          const uri = new URI(tokenRelayUrl);
          if (uri.hostname().startsWith('192')) {
            uri.hostname('localhost');
            // eslint-disable-next-line no-param-reassign
            tokenRelayUrl = uri.href();
          }

          // The server cache is invalidated by sending a token request with Cache-Control:no-cache header
          const addNoCacheHeader = this.invalidateServerCaches[tokenCacheKey];
          delete this.invalidateServerCaches[tokenCacheKey];

          const tokenRelayRequest = Utils.createTokenRelayRequest(tokenRelayUrl, tokenRelay2, addNoCacheHeader);

          // process additional metadata specified in tokeyRelayAuthentication
          this.processTokenRelayAuthentication(tokenRelayRequest, tokenRelayAuthentication, requestUrl);

          // use the fetchHandler to fetch the token so the request can be modified by the plugins such as
          // csrfTokenHandlerPlugin
          return this.fetchHandler.handleRequest(tokenRelayRequest).then((response) => {
            if (response.ok) {
              return response.json()
                .then((token) => this.cacheAuthToken(tokenCacheKey, token, tokenRelay2));
            }

            //
            // Initiate the OAuth2 code grant flow as follows:
            // 1. Call DefaultSecurityProvider.vbRunOAuth2CodeGrantFlow passing in the redirect url specified in
            //    the JSON response of the token relay call.
            // 2. The vbRunOAuth2CodeGrantFlow method will open a new tab and navigate to the redirect url which is
            //    the third-party authorization endpoint.
            // 3. Once the user is successfully authenticated and authorized, the third-party endpoint will redirect
            //    back to the token relay callback endpoint with the access token. The callback endpoint will then
            //    cache the access token.
            // 4. The callback endpoint will then redirect back to the application in the new tab with a special flag,
            //    'vbrtAuthTokenMode'. This flag will instruct VBRT to bootstrap into a special mode where it will
            //    simply send a message back to the parent window indicating the oauth flow has completed and then
            //    closes tab.
            // 5. Once the oauth flow is completed, this plugin will then retry the token relay request to fetch
            //    the cached access token.
            //
            // eslint-disable-next-line max-len
            // @see https://confluence.oraclecorp.com/confluence/pages/viewpage.action?spaceKey=ABCS&title=Visual+Builder+-+OAuth+2.0+Auth+Code+grant+type
            //
            if (runOAuth2CodeGrantFlow && response.status === 400
              && tokenRelayAuthentication.type === 'oauth2_code_grant') {
              return response.json()
                .then((codeGrantDesc) => {
                  const redirectUrl = codeGrantDesc['o:vb-code-grant-redirect'];
                  const params = tokenRelayAuthentication.authorize.params;

                  // post a message to the security provider to initiate the oauth2 code grant flow
                  const msg = {
                    method: 'vbRunOAuth2CodeGrantFlow',
                    args: [
                      redirectUrl,
                      params,
                    ],
                  };
                  return Utils.postMessage(undefined, msg)
                    .then(() => {
                      logger.info('OAuth2 Code Grant Flow completed.');

                      // delete the promise so we can fetch the access token from the token relay endpoint
                      // after running the oauth flow
                      delete this.cachedTokenPromises[tokenCacheKey];
                      return this.getCachedToken(tokenRelayUrl, tokenRelayAuthentication, requestUrl,
                        tokenRelay2, tokenCacheKey, false);
                    });
                });
            }

            throw new Error(response.statusText);
          });
        }).catch((err) => {
          // log the error for debugging purpose
          logger.error(err);

          // delete the promise if there's any error so we don't cache the failure state
          delete this.cachedTokenPromises[tokenCacheKey];
        });

        this.cachedTokenPromises[tokenCacheKey] = cachedTokenPromise;
      }

      return cachedTokenPromise;
    }

    /**
     * Invalidate the cached token for the given tokenRelayUrl. This method will return a promise that will
     * resolve to true if a request should be retried and false otherwise.
     *
     * @param tokenRelayUrl the url for the cached token to invalidate
     * @param wwwAuthHeader
     * @param tokenCacheKey Key to the access token cache
     * @returns {Promise.<Boolean>}
     */
    invalidateCachedToken(tokenRelayUrl, wwwAuthHeader, tokenCacheKey) {
      let invalidateTokenPromise = this.invalidateTokenPromises[tokenCacheKey];

      if (!invalidateTokenPromise) {
        // first, delete the token from the cache
        invalidateTokenPromise = this.stateCache.delete(tokenCacheKey).then(() => {
          // BUFP-21346: The invalidate request currently does not work so we are bypassing it and relying on always
          // fetching a fresh token using the no-cache header.
          if (wwwAuthHeader) {
            const body = {
              headers: {
                'WWW-Authenticate': wwwAuthHeader,
              },
            };

            const options = {
              method: 'POST',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            };
            const request = new Request(`${tokenRelayUrl}/invalidate`, options);

            // make the invalidate request to the token relay service
            return this.fetchHandler.handleRequest(request)
            // only retry if we get a status 307
              .then((response) => response.status === 307)
              .catch((err) => {
                // log the error and don't retry
                console.error(err);
                return false;
              });
          }

          logger.info('Authorization token for', tokenRelayUrl, 'is invalidated');

          return Promise.resolve(true);
        });

        this.invalidateTokenPromises[tokenCacheKey] = invalidateTokenPromise;
      }

      return invalidateTokenPromise.then((retry) => {
        // delete the invalidateTokenPromise
        delete this.invalidateTokenPromises[tokenCacheKey];

        // delete the cachedTokenPromise so the token can be refreshed
        delete this.cachedTokenPromises[tokenCacheKey];

        // remember that we need to invalidate the server cache on retry
        this.invalidateServerCaches[tokenCacheKey] = true;

        return retry;
      });
    }

    /**
     * The cached token is a wrapped version of the JWT token containing the extracted expiration
     * time and calculated server skew. This method will return a promise that resolves to the
     * wrapped token.
     *
     * @param tokenCacheKey Key to the access token cache
     * @param token the JWT token
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @returns {Promise}
     */
    cacheAuthToken(tokenCacheKey, token, tokenRelay2) {
      let authenticationHeader = token.authenticationHeader;
      let expiration = null;
      if (tokenRelay2) {
        // token.token_type is 'bearer' but the header prefix needs to be 'Bearer'
        authenticationHeader = `Bearer ${token.access_token}`;
        // TRAP service access token response provides expiration time explicitly
        // (https://www.rfc-editor.org/rfc/rfc6749#section-4.4.3)
        if (token.expires_in) {
          expiration = {
            time: Utils.getEpochTime() + token.expires_in,
            skew: 0,
          };
        }
      } else {
        authenticationHeader = token.authenticationHeader;
        // extract expiration from a JSON Web Token
        expiration = Utils.extractJwtExpiration(token.authenticationHeader);
      }
      const cachedToken = {
        token: { authenticationHeader },
        expiration,
      };

      return this.stateCache.put(tokenCacheKey, cachedToken).then(() => cachedToken);
    }

    /**
     * Append the token from the token relay service
     *
     * @param request the request to which to append the CSRF token
     * @returns {Promise}
     */
    handleRequestHook(request) {
      // get the url for the token relay service from the request header
      const tokenRelayUrl = request.headers.get(Constants.TOKEN_RELAY_URL_HEADER);

      if (tokenRelayUrl) {
        const tokenRelayAuthHeader = request.headers.get(Constants.TOKEN_RELAY_AUTH_HEADER);
        const tokenRelayAuthentication = tokenRelayAuthHeader ? JSON.parse(tokenRelayAuthHeader) : undefined;
        const tokenRelay2 = tokenRelayUrl ? request.headers.get(Constants.TRAP_2_ENABLED_HEADER) === 'true' : false;
        const cacheKey = this.getTokenCacheKey(request, tokenRelayUrl);

        return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, request.url, tokenRelay2, cacheKey)
          .then((authHeader) => {
            if (authHeader) {
              const { headers } = request;
              const altAuthHeaderName = headers.get(Constants.ALT_AUTHORIZATION_HEADER_NAME);
              const authHeaderName = altAuthHeaderName || 'Authorization';

              headers.set(authHeaderName, authHeader);
            }

            // failed to get the token and just let the original request fail
            return Promise.resolve();
          });
      }

      return Promise.resolve();
    }

    /**
     * Handles 401 response as result of a non-JWT token expiring. The returned promise will resolve
     * to true if the request needs to be retried and false otherwise.
     *
     * @param response
     * @param origRequest
     * @param request
     * @param client
     * @returns {Promise<Boolean>}
     */
    handleResponseHook(response, origRequest, request, client) {
      return Promise.resolve().then(() => {
        if (Utils.shouldRefreshAccessToken(response)) {
          const authHeader = request.headers.get('Authorization');

          logger.info('401 response detected for', origRequest.url, 'with authorization header', authHeader);

          if (authHeader) {
            // extract the token relay url from the request
            const tokenRelayUrl = AuthPreprocessorHandlerPlugin.getTokenRelayUrlFromRequest(origRequest);

            logger.info('Look up token relay url', tokenRelayUrl);

            if (tokenRelayUrl) {
              const cacheKey = this.getTokenCacheKey(request, tokenRelayUrl);

              const cachedTokenPromise = this.cachedTokenPromises[cacheKey] || Promise.resolve();

              return cachedTokenPromise.then((cachedToken) => {
                // if the auth header and the cached header match, that means the token has expired
                // so invalidate the token and retry the request
                if (cachedToken
                  && cachedToken.token.authenticationHeader === authHeader) {
                  logger.info('Invalidating cached authorization token');

                  return this.invalidateCachedToken(tokenRelayUrl, undefined, cacheKey).then(() => true);
                }

                return false;
              });
            }
          }
        }

        return false;
      });
    }

    /**
     * Based on the Request and the tokenRelay URL builds a key that can be used for
     * caching access token.
     *
     * @param {Request} request
     * @param {string} tokenRelayUrl
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    getTokenCacheKey(request, tokenRelayUrl) {
      // at RT token relay URL is unique per service, but at DT '$dt_service' is used for multiple service connections

      // at RT service connection can be bound only to one backend, so the request URL does not add any more
      // specificity to the cache key
      let reqKey = '';
      // at RT service connection can have only one auth type so we don't need the auth block
      // to play part in the cache key
      let authKey = '';

      // TODO: this should be handled by the DT's dtTokenRelayHandlerPlugin
      if (tokenRelayUrl.includes('/$dt_service')) {
        reqKey = new URI(request.url).domain();
        const tokenRelayAuthHeader = request.headers.get(Constants.TOKEN_RELAY_AUTH_HEADER);
        authKey = tokenRelayAuthHeader || '';
      }

      return tokenRelayUrl + reqKey + authKey;
    }
  }

  return TokenRelayHandlerPlugin;
});

