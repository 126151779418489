'use strict';

define('vb/action/builtin/callChainAction',[
  'vb/action/action',
  'vb/private/action/actionChainRunner',
  'vb/private/utils',
  'vb/private/log',
],
(Action, ActionChainRunner, Utils, Log) => {
  /**
   * This action is used to invoke another action chain.
   */
  class CallChainAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = Log.getLogger('/vb/action/action/CallChainAction');
      this.scopes = null;
      this.context = null;
    }

    perform(parameters) {
      return new Promise((resolve, reject) => {
        const { id, chain, params } = parameters;
        const actionId = id || chain;

        if (!actionId) {
          this.log.error('Required \'id\' parameter missing.');
          reject();
        }

        this.log.info('Calling action chain', actionId, 'with parameters:', params);

        // We need to create a new context using targetContainer (which is the current container) as the
        // calling container for action chain to be invoked. For example, when a page chain calls a flow chain,
        // the calling container is the page and the target container is the flow. From the flow chain’s perspective,
        // the target container would be the current container when executing the chain. Now, the same flow chain
        // then calls another flow chain, it needs to set up the calling container using the current container
        // which is the target container of the calling context.
        const container = this.context.targetContainer;
        const newContext = {
          application: container.application,
          parent: container.parent,
          container,
          originalContext: this.context.originalContext, // pass along the original context
        };

        return ActionChainRunner.create({ chainId: id, chain })
          .run(params, newContext, this.scopes, {
            rethrowError: true,
            coerceResultToOutcome: true,
            coerceErrorToOutcome: true,
            skipParameterEvaluation: true, // prevent double evaluation since parameters have already been evaluated
          })
          .catch(reject)
          .then(resolve);
      });
    }

    setContext(scopes, context) {
      this.scopes = scopes;
      this.context = context;
    }
  }

  return CallChainAction;
});

