'use strict';

define('vb/private/types/utils/serviceDataProviderRestHelper',[
  'knockout',
  'vb/private/utils',
  'vb/private/helpers/rest',
  'vb/private/services/transformsUtils',
  'vb/private/types/dataProviderConstants',
], (ko, Utils, RestHelper, TransformsUtils, DPConstants) => {
  /**
   * Stores Endpoint runtime state. Stores any state for an endpoint identified by its scope:endpointId and that can
   * be shared across multiple usages of this endpoint loaded in a given scope. Currently there are 2 properties
   * that can be stored here -
   * - capabilities : capabilities by feature as define by JET DataProvider.
   * - totalSize: canonical totalSize
   * @type {{}}
   */
  const ENDPOINT_RT_STATE = {};
  const TOTALSIZE_KEY = 'totalSize';
  /**
   * Helper specifically used with SDP so it can control restHelper behavior.
   *
   * @see {RestHelper}
   */
  class ServiceDataProviderRestHelper extends RestHelper {
    static storeInCache(name, key, value) {
      ENDPOINT_RT_STATE[name] = ENDPOINT_RT_STATE[name] || {};
      const keyValue = ENDPOINT_RT_STATE[name][key];
      if (ko.isObservable(keyValue)) {
        keyValue(value);
      } else {
        ENDPOINT_RT_STATE[name][key] = value;
      }
    }

    static retrieveFromCache(name, key) {
      return (ENDPOINT_RT_STATE[name] && ENDPOINT_RT_STATE[name][key]);
    }

    /**
     * depending on capabilities not all transforms need to be run
     * @returns {*}
     */
    getRequestTransformsToRun(configuration) {
      if (configuration.fetchConfiguration) {
        const fetchCall = configuration.fetchConfiguration.capability;
        const fetchCallParams = configuration.fetchConfiguration.fetchParameters;
        if (fetchCall === 'fetchByKeys' && fetchCallParams.keys.size > 0
          && this.transformRequestFuncMap.fetchByKeys
        ) {
          // transforms author defines a fetchByKeys function use it
          const retXform = ['fetchByKeys'];

          const ctx = configuration.fetchConfiguration.context;
          if (ctx) {
            if (ctx.capabilities && ctx.capabilities.fetchByKeys &&
              ctx.capabilities.fetchByKeys.implementation === 'iteration' && 
              ctx.transformsContext && ctx.transformsContext['temp-for-2404-vb-includePaginateXForm'] === true
            ) {
              // optIn to use paginate transform so that the row of key beyond first batch can be returned
              // this is an internal temporary flag to unblock fetchByKeys encounter infinite loop
              retXform.push('paginate');
            }

          if (ctx.transformsContext && ctx.transformsContext['vb-includeSelectXForm'] === true
            ) {
              // optIn to use select transform so that all fetch methods are in sync
              retXform.push('select');
            }
          }
          return retXform;
        }
      }
      const transformFuncKeys = Object.keys(this.transformRequestFuncMap);
      // call select transform first and body last
      return transformFuncKeys
        .filter(TransformsUtils.excludeFetchByKeys)
        .sort(TransformsUtils.selectTransformFirst)
        .sort(TransformsUtils.bodyTransformLast); // to allow other transforms to update body
    }

    /**
     * Only for SDPs we may need to resolve the external references found in the response metadata.
     * Checks transforms requirenments method to see if it requires fully resolved endpoint metadata.
     *
     * BOSS Transforms needs this resolved metadata to calculate endpoint capabilities.
     *
     * @param {Object} transformsMetadataFuncs Value returned by Endpoint.getMetadataTransforms()
     * @param {Object} configuration
     * @param {string} configuration.url
     * @param {Object} configuration.parameters
     * @param {Object} configuration.endpointDefinition Endpoint metadata from the OpenApi doc
     * @param {Object} configuration.initConfig

     * @returns
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    transformsUseResponsesMetadata(transformsMetadataFuncs, configuration) {
      return TransformsUtils.transformsUseResponsesMetadata(transformsMetadataFuncs, configuration);
    }

    // eslint-disable-next-line class-methods-use-this
    storeState(name, key, value) {
      ServiceDataProviderRestHelper.storeInCache(name, key, value);
    }

    // eslint-disable-next-line class-methods-use-this
    retrieveState(name, key) {
      return ServiceDataProviderRestHelper.retrieveFromCache(name, key);
    }

    /**
     * Gets and stores the capabilities for the endpoint. This method should be called once per scope#endpoint
     * @param name
     * @return {Promise<*>} resolves with the capabilities or undefined
     */
    getAndStoreCapabilities(name) {
      let caps = {};
      let capsPromise = this.retrieveState(name, DPConstants.CAPABILITIES_KEY);
      if (!capsPromise) {
        let capabilitiesFunc;
        capsPromise = new Promise((resolve) => {
          this._getEndpoint().then((endpoint) => {
            if (endpoint) {
              // endpoint.getConfig() loads the endpoint and any existing transforms
              endpoint.getConfig(this._allParams, { ignoreMissingParams: true })
                .then((config) => {
                  const transformsMetadataFuncs = endpoint.getMetadataTransforms();
                  capabilitiesFunc = transformsMetadataFuncs && transformsMetadataFuncs.capabilities;

                  return Promise.all([
                    config,
                    transformsMetadataFuncs,
                    capabilitiesFunc && endpoint.getMetadata(false), // fetch metadata only if we need it
                  ]);
                })
                .then(([config, transformsMetadataFuncs, endpointMetadata]) => {
                  // inital endpoint metadata does not have external references resolved
                  let epMetadata = endpointMetadata;
                  const configuration = {
                    url: config.url,
                    parameters: this._flattenedParams(), // this is always a new object
                    endpointDefinition: epMetadata,
                    initConfig: {},
                  };

                  // if transform needs external refs resolved it will need to indicate that
                  if (capabilitiesFunc && this.transformsUseResponsesMetadata(transformsMetadataFuncs, configuration)) {
                    // expand the responses metadata
                    epMetadata = endpoint.getMetadata(true);
                  }
                  return Promise.all([
                    configuration,
                    epMetadata,
                  ]);
                })
                .then(([configuration, endpointMetadata]) => {
                  if (capabilitiesFunc) {
                    // update configuration with the (potentially) expanded references found in the endpoint definition
                    // eslint-disable-next-line no-param-reassign
                    configuration.endpointDefinition = endpointMetadata;
                    Object.assign(configuration.initConfig, this.initRequestMap);

                    caps = capabilitiesFunc.call(null, configuration);
                  }
                  // the capability object should always be stored in the cache to replace the promise object
                  // that is put in the cache earlier.
                  // If there is no capabilitiesFunc, then the capability from the endpoint is an empty
                  // object, otherwise it is the actual capability object returned from the associated endpoint
                  // transform. Either way, the plain object should be put in the cache.
                  ServiceDataProviderRestHelper.storeInCache(name, DPConstants.CAPABILITIES_KEY, caps);
                  const totalSize = ko.observable(-1);
                  ServiceDataProviderRestHelper.storeInCache(name, TOTALSIZE_KEY, totalSize);
                  resolve(caps);
                })
                .catch((e) => {
                  this.log.warn('ServiceDataProviderRestHelper', this.id, 'error getting capabilities for endpoint',
                    JSON.stringify(this.endpointReference), e);
                  resolve(caps);
                });
            } else {
              this.log.error('ServiceDataProviderRestHelper', this.id, 'error fetching endpoint details',
                JSON.stringify(this.endpointReference));
              resolve(caps);
            }
          }).catch((e) => {
            this.log.error('ServiceDataProviderRestHelper', this.id, 'error fetching full URL for endpoint',
              JSON.stringify(this.endpointReference), e);
            resolve(caps);
          });
        });
        ServiceDataProviderRestHelper.storeInCache(name, DPConstants.CAPABILITIES_KEY, capsPromise);
      }
      return capsPromise;
    }
  }

  return ServiceDataProviderRestHelper;
});

