'use strict';

define('vb/private/stateManagement/flow',[
  'vb/private/stateManagement/container', 'vb/private/stateManagement/page',
  'vb/private/stateManagement/pageInExtension',
  'vb/private/stateManagement/router', 'vb/private/utils', 'vb/private/constants',
  'vb/private/stateManagement/context/flowContext',
  'vb/private/stateManagement/flowExtension',
  'vb/private/services/services',
  'vb/private/stateManagement/stateMonitor', 'vb/errors/httpError',
  'urijs/URI',
  'vb/private/log',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/private/stateManagement/baseModuleViewModel',
  'vb/private/translations/bundleUtils',
], (Container, Page, PageInExtension, Router, Utils, Constants, FlowContext, FlowExtension,
  Services, StateMonitor, HttpError, URI, Log, LoadMonitorOptions, BaseModuleViewModel, BundleUtils) => {
  const logger = Log.getLogger('/vb/stateManagement/flow', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  class Flow extends Container {
    constructor(id, parent, path, className = 'Flow') {
      super(id, parent, className);

      let rPath;
      if (path) {
        // used for directly loading the flow from its physical file location
        rPath = path;
      } else if (parent) {
        // Use the parent flow to calculate the path
        const parentFlow = this.getParentFlow();
        rPath = parentFlow.calculateRequirePath(id);

        // id is an alias so deduce the real flow id from path
        const name = rPath.substr(0, rPath.length - 1);
        const index = name.lastIndexOf(Constants.PATH_SEPARATOR);
        this._name = index < 0 ? name : name.substr(index + 1);
      } else {
        // app flow don't have a require path and the name is the same as id which is 'app'.
        rPath = '';
      }

      // from this point on the path value cannot be modified.
      Object.defineProperties(this, {
        path: {
          value: rPath,
          enumerable: true,
        },
      });

      this.loadedPromise = null; // Initialized in loadFlow
      /**
       * @type {Object}
       */
      this.pages = {};

      /**
       * @type {Object}
       */
      this.defaultPage = null; // Initialized in loadFlow

      this.services = null; // Initialized in createServices
    }

    static get PageClass() {
      return Page;
    }

    static get FlowClass() {
      return Flow;
    }

    static get extensionClass() {
      return FlowExtension;
    }

    /**
     * @type {String}
     */
    get name() {
      return this._name || super.name;
    }

    /**
     * The name of the resource to be used to load the flow.
     * @type {String}
     */
    get fullName() {
      return `${this.name}-flow`;
    }

    /**
     * The application owning this flow, for regular flow it's the application, for packageFlow
     * it's the appPackage
     * @type {Flow}
     */
    get owningApp() {
      return this.application;
    }

    /**
     * Given a flow id, calculate the path for this flow relative to the application
     *
     * @param  {string} id a flow id
     * @return {string} the flow path
     */
    calculateRequirePath(id) {
      // Defining a flow alias is optional. If the flow id is not in flows,
      // look for it in the parent flow 'flows' folder.
      let path = this.definition.flows[id] || `flows/${id}`;

      // Make sure it is terminated with '/'
      path = Utils.addTrailingSlash(path);

      // If the path is an URL just use it otherwise
      // if the path starts with /, it's relative to the application, otherwise
      // it's relative to the parent flow location.
      const uri = URI.parse(path);
      // only replace the path when not a full URL
      if (!uri.protocol) {
        if (path[0] === '/') {
          path = `${this.owningApp.path}${path.substr(1)}`;
        } else {
          path = `${this.path}${path}`;
        }
      }

      return path;
    }

    createFlow(id, container) {
      return new (this.constructor.FlowClass)(id, container);
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getFlowDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getFlowFunctions';
    }

    /**
     * The enter callback for ojRouter state transition used the
     * getRouterConfigureCallBack below
     * @param  {String} stateId
     * @return {Promise}
     */
    routerStateEnterCallback(stateId) {
      return Promise.resolve().then(() => {
        let cont;

        if (this.defaultPage && this.defaultPage.excludeFromUrl === true) {
          const hiddenPage = this.pages[this.defaultPage.id];

          // when the defaultPage changes and the page is hidden, the current default page
          // never receive the vbExit event because it's not using a router to maintain the state.
          // So force the exit on the current page and the enter on the new page.
          // This scenario occurs when App UI changes the default root page (shell_)
          let promise = Promise.resolve();

          const { defaultPage } = this.definition;
          if (defaultPage && defaultPage.id !== this.defaultPage.id) {
            const currentDefaultPage = this.pages[defaultPage.id];
            if (currentDefaultPage) {
              promise = currentDefaultPage.exit();
            }
          }
          promise.then(() => {
            hiddenPage.enter();
          });

          cont = hiddenPage.getContainer(stateId);
        } else {
          // Call getContainer instead of loadContainer because the container has
          // been created in canEnter.
          cont = this.getContainer(stateId);
        }

        return cont && cont.enter();
      });
    }

    /**
     * Return the first flow up in the parent hierarchy.
     * For flow, it's this.parent.parent, for page it's this.parent for
     * application it's null.
     * When the flow is the defaultFlow of an App UI, the parent flow is the App UI
     * which is the immediate parent of the flow.
     *
     * @return {Flow} the first flow in the parent hierarchy
     */
    getParentFlow() {
      const { parent } = this;
      return (parent instanceof Flow) ? parent : (parent && parent.getParentFlow());
    }

    isDefault() {
      return this.parent.isDefaultFlowId(this.id);
    }

    // eslint-disable-next-line class-methods-use-this
    isDefaultFlowId() {
      return false;
    }

    /**
     * Load the flow when the defaultPage property is not a page id but a flow id.
     * In that case the default page of the flow is used as the default page of the application.
     * If the defaultFlowId is specified, it is used as the starting flow in the shell.
     *
     * @param  {String} flowId        the id of the flow to use for shell
     * @param  {String} defaultFlowId the id of the flow to use in the shell page
     * @return {Promise} a promise of a shell page
     */
    processFlowDefaultPage(flowId, defaultFlowId) {
      if (!flowId) {
        return Promise.reject(new Error(`Invalid application defaultPage "${this.defaultPage.id}"`));
      }
      // If the page is not found, it could be a fow, so remove the tentative page.
      // When the defaultPage is of the form shellFlowId/shellFlow, the page is not created and
      // calling deletePage does nothing.
      this.deletePage(flowId);

      // The id can be a flowId, in which case the page is the default page of the flow
      const flowPath = this.calculateRequirePath(flowId);

      // Load the metadata using the Flow descriptor loader, not "this" which is the application.
      const flowRequirePath = `${this.getResourceFolder()}${flowPath}${flowId}`;

      return Flow.prototype.descriptorLoader(flowRequirePath).then((definition) => {
        const pageId = (typeof definition.defaultPage === 'object')
          ? definition.defaultPage.id : definition.defaultPage;
        if (!pageId) {
          throw new Error(`The default page for flow ${flowId} is not defined.`);
        }

        const page = this.createPage({ id: pageId }, flowPath);
        this.pages[pageId] = page;

        return page.loadDescriptor().then((pageDef) => {
          this.defaultPage.excludeFromUrl = true;
          this.defaultPage.id = pageId;
          // Give precedence to a routerFlow defined as the 2nd segment of the defaultPage property
          this.router.defaultStateId = defaultFlowId || pageDef.routerFlow;

          return page.loadAndStart();
        });
      });
    }

    /**
     * Depending on the value of the flow defaultPage property, the default page
     * could be a page "pageId" or a flow "flowId" or a shellFlow id with a flowId
     * "shellFlowId/flowId"
     *
     * @return {Promise}
     */
    processDefaultPage() {
      const pageId = this.defaultPage && this.defaultPage.id;
      if (!pageId) {
        return Promise.resolve();
      }

      // Set the default page as the router default state so this page is loaded
      // when no pages are defined in the URL.
      const segments = pageId.split('/');
      const id = segments[0];

      // If the path is of the form flowId/pageId, the first segment can only be a flow
      // so don't bother trying to load a page that doesn't exist, it speeds up the
      // loading if the fndShell.
      if (segments.length > 1) {
        return this.processFlowDefaultPage(id, segments[1]);
      }

      // Try to load a page with this id first
      return this.getOrCreatePageFromInfo(this.defaultPage).loadDescriptor()
        .then((definition) => {
          let result;

          // To exclude a page in the URL, set the router state to the default flow
          // and the start the page
          if (this.defaultPage.excludeFromUrl === true) {
            // Update the router default state to the default flow of the page
            this.router.defaultStateId = definition.routerFlow;

            result = this.pages[id].loadAndStart();
          } else {
            // Update the router default state to the new page id
            this.router.defaultStateId = id;
          }

          return result;
        })
        .catch((error) => {
          let promise;

          if (HttpError.isFileNotFound(error)) {
            if (this.defaultPage.extension) {
              // In case of error loading the hostRootPage, remove the hostRootPage from
              // the appUiInfo and process the default page again so that it use the default shell
              const info = this.appUiInfos.getInfo(this.defaultPage.appUiId);
              delete info.hostRootPage;
              // Clean up the bad page instance
              this.pages[id].dispose();
              this.log.error('Cannot find host root page', id, 'in extension', this.defaultPage.extension.id);
              // Restart the process
              promise = this.processDefaultPage();
            } else {
              // If the page doesn't exist, the defaultPage might be a flow
              promise = this.processFlowDefaultPage(id, segments[1]);
            }
          } else {
            // Other type of errors mean the page is available but cannot be accessed like
            // by example if the page is secured.
            this.router.defaultStateId = id;
          }

          return promise;
        });
    }

    loadFlow() {
      this.loadedPromise = this.loadedPromise || this.loadDescriptor()
        .then(() => {
          this.defaultPage = this.definition.defaultPage;
        })
        .catch((error) => {
          this.callSecurityProvider(error);
          throw error;
        });

      return this.loadedPromise;
    }

    /**
     * Fully loads the flow, including variable definitions, chains, etc.
     *
     * @returns {Promise} A promise when application loading completes
     */
    load() {
      // Starts loading metadata, the module functions and extensions simultaneously
      // Start the flow load timer
      const mo = new LoadMonitorOptions(this.loadSpanName, this.getResourcePath(), this);
      return logger.monitor(mo, (flowLoadTimer) => this.loadFlow()
        .then(() => this.loadFunctionModule())
        .then(() => {
          this.initializeActionChains();

          // Setup the component event listeners
          this.initializeEvents();

          this.initRouter();

          // record an application activated state change
          StateMonitor.recordStateChange(StateMonitor.RuntimeState.CONTAINER_ACTIVATED, this);

          return this.createServices();
        })
        .then(() => {
          logger.greenInfo(this.getResourcePath(), 'loaded.', flowLoadTimer());
        })
        .catch((error) => {
          flowLoadTimer(error);
          // In case or error during loading, by example when the flow doesn't exist,
          // remove the flow
          this.dispose();
          throw error;
        }));
    }

    /**
     * Returns the model object for the flow container. The model contains all the accessible $ properties
     * of the flow container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *   $layout: {}
     * }
     *
     * @return {Promise<Object>} a promise that resolve with the model object.
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        this.viewModelPromise = Promise.all([
          this.load(), this.loadFunctionModule(),
        ])
          // load() eventually triggers loadTranslationBundles().  None of the setup needs to block on the load of the
          // bundles, but we need to make sure the bundles have been loaded before we try to construct $translations.
          .then(() => BundleUtils.whenBundlesReady())
          .then(() => {
            const viewModel = new BaseModuleViewModel(this);
            // Flow viewModel does not have the vbBridge property
            delete viewModel[Constants.componentBridge];

            return viewModel;
          });
      }

      return this.viewModelPromise;
    }

    /**
     * initialize the services object, but don't load (this is overridden by Application to set a flag)
     * @returns {Promise}
     *
     * @private
     */
    createServices() {
      return Promise.resolve()
        .then(() => {
          const serviceFileMap = this.definition.services || {};
          const options = {
            relativePath: this.getResourceFolder(),
            serviceFileMap,
            expressionContext: this.getAvailableContexts(),
            isUnrestrictedRelative: false,
            protocolRegistry: this.application.protocolRegistry,
            extensionRegistryGetter: () => this.application.extensionRegistry,
          };

          this.services = new Services(options);
        });
    }

    /**
     * Return true when the page id is a of a page excluded from the URL
     * @param {String} pageId
     * @return {Boolean}
     */
    isPageHiddenFromUrl(pageId) {
      return (this.defaultPage.id === pageId && this.defaultPage.excludeFromUrl === true);
    }

    /**
     * Initialize the descriptor object default value
     */
    initDefault(definition) {
      const def = definition;
      def.flows = def.flows || {};

      const { defaultPage } = def;

      // For old flow format where defaultPage is a string id, convert to the new format
      // of { id: <pageId>, excludeFromUrl: true/false }
      if (typeof defaultPage === 'string' || !defaultPage) {
        def.defaultPage = {
          id: defaultPage,
          excludeFromUrl: false,
        };
      }

      super.initDefault(def);
    }

    /**
     * Returns the FlowContext constructor used to create the '$' expression context
     * Override Container.ContextType
     * @type {FlowContext}
     */
    static get ContextType() {
      return FlowContext;
    }

    /**
     * Initializes the flow by initializing the variables and calling the enter event.
     * This is called by enter and overridden by appPackage
     *
     * @return {Promise}
     */
    initialize() {
      return this.initAllVariableNamespace()
        .then(() => this.invokeEvent(Constants.ENTER_EVENT));
    }

    /**
     * Called by the router in response to the enter callback
     * Implemented by Page and Flow
     *
     * @return {Promise}
     */
    enter() {
      return this.initialize()
        .then(() => {
          this.lifecycleState = Constants.ContainerState.ENTERED;
        });
    }

    /**
     * The place to initialize builtins variables.
     */
    initializeBuiltins() {
      super.initializeBuiltins();

      // Create the built-in path to store the base URL for this flow
      this.createConstant(Constants.PATH_VARIABLE, {
        type: 'string',
        defaultValue: `${this.absoluteUrl}${this.path}`,
      }, Constants.VariableNamespace.BUILTIN);
    }

    // eslint-disable-next-line class-methods-use-this
    defineCurrentPageBuiltinVariable() {
      return {
        type: 'string',
        defaultValue: null,
      };
    }

    defineInfoBuiltinVariable() {
      return {
        id: this.definition.id,
        description: this.definition.description,
      };
    }

    initializeVariables() {
      const parentContainer = this.parent;
      if (parentContainer && parentContainer instanceof Page) {
        const parentPage = parentContainer;
        // Assign the parent page selectedFlow variable
        // Uses the variable setValueInternal because it's a readonly variable and the regular
        // assignment will fail.
        if (parentPage.initializePromise) {
          parentPage.initializePromise.then(() => {
            // The action chain tester creates a fake shell page that doesn't have a scope and will cause the following
            // code to throw an exception during test execution so we need to guard against it.
            if (parentPage.scope) {
              const currentFlowVar = parentPage.scope.getVariable(Constants.CURRENT_FLOW_VARIABLE,
                Constants.VariableNamespace.BUILTIN);
              currentFlowVar.setValueInternal(this.id);
            }
          });
        }
      }

      return super.initializeVariables();
    }

    /**
     * Find the first parent container that has a moduleConfig property and returns it
     *
     * @return {function}  The parent moduleConfig which is a ko observable
     */
    getParentModuleConfig() {
      const container = this.parent;
      let parentModuleConfig;

      if (container) {
        // Since the parent can be a flow like in the case of App UI with no root page, the
        // moduleConfig can be more than one level up
        parentModuleConfig = container.moduleConfig || container.getParentModuleConfig();
      }

      return parentModuleConfig;
    }

    exit() {
      return this.invokeEvent(Constants.EXIT_EVENT).then(() => {
        // When a flow exit and this flow does not have a default flow to fallback on,
        // refresh the parent page so the nested content become empty.
        // Check for parent since it can be null when the flow is the application.
        // Note that this shouldn't be in dispose, since dispose can be called when the
        // flow has not been entered yet, like in the load error case.
        const parentPage = this.parent;
        if (parentPage && parentPage.router && !parentPage.router.defaultStateId) {
          parentPage.moduleConfig(Constants.blankModuleConfig);
          if (parentPage.scope) {
            const currentFlowVar = parentPage.scope.getVariable(Constants.CURRENT_FLOW_VARIABLE,
              Constants.VariableNamespace.BUILTIN);
            currentFlowVar.setValueInternal(null);

            // clear any outstanding busy state on the router
            Router.clearBusyState();
          }
        }
        // Mark the flow "exited". This tells the nested page to dispose of the flow when page is "disconnected"
        this.lifecycleState = Constants.ContainerState.EXITED;
      });
    }

    createPage(pageInfo, path) {
      if (pageInfo.extension) {
        return new PageInExtension(pageInfo, this, path);
      }

      return new (this.constructor.PageClass)(pageInfo.id, this, path);
    }

    /**
     * Load a nested page given its id.
     *
     * @param  {String} id the id of the page
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolve to a Page instance
     */
    loadContainer(id, navContext) {
      if (navContext && navContext.isCancelled()) {
        return Promise.resolve();
      }

      return Promise.resolve().then(() => {
        if (this.defaultPage.excludeFromUrl === true) {
          if (this.defaultPage.id !== id) {
            return this.loadPageFromInfo(this.defaultPage, navContext)
              .then((page) => page.loadContainer(id, navContext));
          }
        }

        return this.loadPageFromInfo({ id }, navContext);
      });
    }

    /**
     * Retrieve the cached instance of the nested container.
     * For flows, the return value is a page instance.
     * @param  {String} id the id of the page to retrieve
     * @return {Container} the page instance
     */
    getContainer(id) {
      if (this.defaultPage.excludeFromUrl === true) {
        return this.pages[this.defaultPage.id].getContainer(id);
      }
      return this.pages[id];
    }

    /**
     * Load a page given its id.
     *
     * @param  {String} id the id of the page to load
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolve to a page instance or undefined if the navigation was cancelled
     */
    loadPageFromId(id, navContext) {
      return this.loadPageFromInfo({ id }, navContext);
    }

    loadPageFromInfo(pageInfo, navContext) {
      const page = this.getOrCreatePageFromInfo(pageInfo, navContext);

      // Load the page
      return page.loadAndStart(navContext);
    }

    /**
     * Delete a page from the cache given its id
     * @param  {String} pageId the id of the page to delete
     */
    deletePage(pageId) {
      delete this.pages[pageId];
    }

    /**
     * Get the page instance from its id. If the page is not already in the cache, creates it.
     *
     * @param  {String} id the id of the page to get
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Page} a page instance
     */
    getOrCreatePageFromId(id, navContext) {
      return this.getOrCreatePageFromInfo({ id }, navContext);
    }

    /**
     * Gets the or create page from a ShellPageInfo.
     *
     * @param      {ShellPageInfo}  pageInfo    the page information
     * @param      {NavigationContext}  navContext the context of the current navigation chain
     * @return     {Page}  page instance
     */
    getOrCreatePageFromInfo(pageInfo, navContext) {
      let page = this.pages[pageInfo.id];
      if (!page) {
        // TODO: We might need to check if the page exist in the definition here
        // and throw an error if it doesn't.
        // if (!this.containsPage(pageId)) {
        //   throw new Error(`Page ${pageId} does not exist in flow ${flow.id}.`);
        // }
        page = this.createPage(pageInfo);
        this.pages[pageInfo.id] = page;

        // Keep track of created pages during the navigation process.
        // This is to know which pages to dispose if the navigation is cancelled.
        if (navContext) {
          navContext.pages.push(page);
        }
      }

      return page;
    }

    /**
     * Build the title that will be used for this page.
     * Walk up the flow hierarchy and gather the title of all pages.
     *
     * @param {String} title the base of the title
     * @return {String} the title
     */
    buildTitle(title) {
      return this.parent.buildTitle(title);
    }

    /**
     * Assign the value of the $flow.currentPage variable
     *
     * @param {String}  pageId  The page identifier
     */
    updateFlowCurrentPageVariable(pageId) {
      // Uses the variable setValueInternal because it's a readonly variable and the regular
      // assignment will fail.
      const currentPageVar = this.scope
        .getVariable(Constants.CURRENT_PAGE_VARIABLE, Constants.VariableNamespace.BUILTIN);
      currentPageVar.setValueInternal(pageId);
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const map = {
        [Constants.FLOW_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.application,
      };

      // 'application' is either the App UI owning this flow or the unified application
      map[Constants.APPLICATION_PREFIX] = this.owningApp;

      return map;
    }

    /**
     *
     * @returns {Services}
     */
    getServices() {
      return this.services;
    }

    /**
     * From an application hierarchy point of view the parent of a flow is not a page
     * but the parent flow, so skip the parent page and go directly to the parent flow.
     *
     * @return {Boolean} true if the authentication is required, false otherwise
     */
    isAuthenticationRequired() {
      // security.access is initialized to non-null value in initDefault.
      const { requiresAuthentication } = this.definition.security.access;

      // if requiresAuthentication is defined, it takes precedence over the parent value
      if (requiresAuthentication !== undefined) {
        return requiresAuthentication;
      }

      return this.parent
        ? this.getParentFlow().isAuthenticationRequired()
        : super.isAuthenticationRequired();
    }

    dispose() {
      Object.values(this.pages).forEach((page) => page.dispose());

      this.loadedPromise = null;

      // Remove ourself from the parent flow cache
      if (this.parent) {
        delete this.parent.flows[this.id];
      }

      super.dispose();
    }
  }

  return Flow;
});

