/* eslint-disable no-param-reassign */

'use strict';

define('vb/private/services/catalogRegistry',[
  'signals',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/services/serviceConstants',
  'vb/private/services/swaggerUtils',
  'vb/private/services/trapData',
  'vb/private/services/uriTemplate',
  'vbc/private/constants',
  'urijs/URI',
], (
  signals,
  Log,
  Utils,
  Constants,
  ServiceConstants,
  SwaggerUtils,
  TrapData,
  UriTemplate,
  CommonConstants,
  URI,
) => {
  const logger = Log.getLogger('/vb/private/services/catalogRegistry');

  // the 'base' path for the values in the config object for token replacement (ex. services.catalog.<etc>).
  const TOKEN_VARIABLES_PATH = ['services', 'catalog'];
  const TOKEN_COMMON_VARIABLES_PATH = ['services', 'catalog', 'common'];

  const BACKEND_OVERRIDE = `${CommonConstants.VbProtocols.CATALOG}://${ServiceConstants.ExtensionTypes.BACKENDS}/`;

  /**
   * Follows the protocol indirection and resolves the server variables
   *
   * @param {object} serverObj
   * @param {string} serverUrl
   * @param {object} catalogHandlerConfig
   * @param {object} originalUrlInfo
   * @param {string} catalogObjectType
   * @param {string} catalogObjectName
   * @param {object} serverVariables
   * @returns {{url: string, template: string, variables: object}}
   */
  // eslint-disable-next-line max-len
  const resolvedUrl = (serverObj, serverUrl, catalogHandlerConfig, originalUrlInfo, catalogObjectType, catalogObjectName, serverVariables) => {
    // create a new URL by appending the original 'path' part to the found server url
    // can't use URI().segment(), because it will encode the braces in the tokens!
    // At this point it is OK for the serverUrl to be a template like 'http://foo:{port}'.
    const serverUri = Utils.uriSafeOperation((SafeURI) => new SafeURI(serverUrl));

    // get the prefix without the query string; we need to manage those ourselves, to preserve templates (?x={foo})
    const uriPrefix = serverUri.clone().search('').toString();
    const endsWith = uriPrefix.endsWith(Constants.PATH_SEPARATOR);

    // need to remove the service/backend 'id' from the path portion
    const pathNoLeadingSlash = originalUrlInfo.path.startsWith(Constants.PATH_SEPARATOR)
      ? originalUrlInfo.path.substring(1)
      : originalUrlInfo.path;
    const pathParts = pathNoLeadingSlash.split(Constants.PATH_SEPARATOR);
    pathParts.shift();

    let pathSuffix = pathParts.join(Constants.PATH_SEPARATOR);
    if (pathSuffix.startsWith(Constants.PATH_SEPARATOR)) {
      pathSuffix = pathSuffix.substring(1);
    }

    let template;
    if (pathSuffix) {
      const delim = !endsWith ? Constants.PATH_SEPARATOR : '';
      template = `${uriPrefix}${delim}${pathSuffix}`;
    } else {
      // remove trailing slashes if URI added it
      template = endsWith && (uriPrefix === `${serverUrl}${Constants.PATH_SEPARATOR}`)
        ? uriPrefix.substring(0, uriPrefix.length - 1) : uriPrefix;
    }
    // check if there are query parms;  not using URIjs because we are preserving template strings ("{mytemplate}");
    // template replacement is handled at the end.
    const qparams = [originalUrlInfo.query, serverUri.query()].filter((q) => !!q);
    if (qparams.length) {
      template = `${template || ''}?${qparams.join('&')}`;
    }

    const rootTokenPath = TOKEN_VARIABLES_PATH.slice();
    rootTokenPath.push(...[catalogObjectType, catalogObjectName]);

    // look for services: { catalog: { backends: { myBackendId: { myVar } } }
    const rootTokens = rootTokenPath.reduce((accum, current) => accum[current] || {}, catalogHandlerConfig) || {};

    // look services: { catalog: { common: { myVar } }
    const commonTokens = TOKEN_COMMON_VARIABLES_PATH
      .reduce((accum, current) => accum[current] || {}, catalogHandlerConfig)
      || {};

    // now look for config['services.catalog.backends.myBackendId.XXX'] or config['services.catalog.common.XXX']
    const commonFlatTokens = {};
    const rootFlatTokens = {};
    const commonPrefix = TOKEN_COMMON_VARIABLES_PATH.join('.');
    const rootPrefix = rootTokenPath.join('.');
    Object.keys(catalogHandlerConfig || {}).forEach((name) => {
      if (name.startsWith(commonPrefix)) {
        const value = catalogHandlerConfig[name];
        if (value !== undefined && value !== null) {
          commonFlatTokens[name.substring(commonPrefix.length + 1)] = value;
        }
      }
      if (name.startsWith(rootPrefix)) {
        const value = catalogHandlerConfig[name];
        if (value !== undefined && value !== null) {
          rootFlatTokens[name.substring(rootPrefix.length + 1)] = value;
        }
      }
    });

    let requestVariableMessage = '';
    const requestVariables = {};

    // now get the default and the request-specific values for the "variables"
    const defaults = {};
    Object.keys(serverObj.variables || {}).forEach((varName) => {
      // default
      if (serverObj.variables[varName].default) {
        defaults[varName] = serverObj.variables[varName].default;
      }

      // we are only using the request-specific server variables that
      //  - are valid
      //  - correspond to actual variables declared on the definition
      const varValue = serverVariables[varName];
      if (SwaggerUtils.isValidServerVariableValue(varValue, serverObj, varName)) {
        requestVariableMessage += `\n  '${varName}': '${varValue}'`;
        requestVariables[varName] = varValue;
      }
    });

    if (requestVariableMessage) {
      logger.info(
        'The server url of',
        `'${catalogObjectType}/${catalogObjectName}'`,
        'has been modified by the following request-specific variables:',
        requestVariableMessage,
      );
    }

    // .. then construct a one-level object, with flattened sub-object property names (level1.level2....)
    // AND the 'common' names
    const variables = Object.assign(
      defaults,
      Utils.flatten(commonTokens),
      Utils.flatten(rootTokens),
      commonFlatTokens,
      rootFlatTokens,
      requestVariables,
    );

    const uriTemplate = new UriTemplate(template || '', {}, true); // do not append extra values to url
    const url = uriTemplate.replace(variables);
    return { url, template, variables };
  };

  // if the transforms is defined on the catalog, it's better to resolve it's path now to avoid errors later.
  // What is happening here:
  // - the catalog path is typically something like 'services/catalog.json' and the transforms path something like
  //   './serviceName/transforms.js'
  // - The code is meant to resolve the relative transforms path against the catalog path, normalizing it.
  const adjustTransforms = (catalogPath, transformsObject) => {
    if (transformsObject) {
      if (transformsObject.path && transformsObject.path[0] === '.') {
        transformsObject.path = new URI(transformsObject.path, catalogPath).path();
      }

      if (transformsObject.originalPath && transformsObject.originalPath[0] === '.') {
        transformsObject.originalPath = new URI(transformsObject.originalPath, catalogPath).path();
      }
    }
  };

  // The catalog servers are modified to provide a .getUrl(...) method instead of a .url property so that we can
  // replace the serverVariables that may be specified per fetch (with the path and query parameters).
  const adjustServers = (servers) => {
    if (Array.isArray(servers)) {
      servers.forEach((server) => {
        if (typeof server.getUrl !== 'function') {
          const unresolvedUrl = server.url;
          delete server.url;

          // eslint-disable-next-line max-len
          server.getUrl = (catalogHandlerConfig, originalUrlInfo, catalogObjectType, catalogObjectName, serverVariables, resolvedVariables = {}) => {
            // If no argument is specified, return the unresolvedUrl, which should only apply for logs and tests.
            if (!catalogHandlerConfig) {
              return unresolvedUrl;
            }

            const serverUrlInfo = resolvedUrl(
              server,
              unresolvedUrl,
              catalogHandlerConfig,
              originalUrlInfo,
              catalogObjectType,
              catalogObjectName,
              serverVariables || {},
            );

            if (serverVariables && serverVariables[Symbol.for('RestHelper.addServerUrlInfo')]) {
              serverVariables[Symbol.for('RestHelper.addServerUrlInfo')](serverUrlInfo);
            }
            // return resolved variables via resolvedVariables parameter
            Object.assign(resolvedVariables, serverUrlInfo.variables || {});
            return serverUrlInfo.url;
          };
        }
      });
    }
  };

  // See CatalogRegistry.isVisibleExtension
  const isVisibleExtension = (extension, candidateId) => extension.id === candidateId
    || extension.getRequiredExtensions().some((ext) => isVisibleExtension(ext, candidateId));

  /**
   * @typedef {Object} OverrideInfo
   * @property {string} protocol
   * @property {string} type
   * @property {string} extensionId
   * @property {string} name
   */
  /**
   *
   * @param {string} override vb-catalog://backends/extA:aBackend
   * @returns {OverrideInfo}
   */
  const parseOverridesUrl = (override) => {
    const urlInfo = URI.parse(override);

    const catalogType = urlInfo.hostname;
    const pathParts = (urlInfo.path || '').split(Constants.PATH_SEPARATOR);
    const qualifiedName = pathParts[1] || '';

    // Check if the backendId encodes a namespace.
    const {
      prefix: namespace = Constants.ExtensionNamespaces.BASE,
      main: name,
    } = Utils.parseQualifiedIdentifier(qualifiedName);

    return {
      protocol: urlInfo.protocol,
      type: catalogType,
      extensionId: namespace,
      name,
    };
  };

  const mergeOverrides = (target, overrides) => {
    Object.keys(overrides || {}).forEach((overridePath) => {
      try {
        // overridePath is of this format vb-catalog://backends/extA:myBackend
        const overridenData = overrides[overridePath];
        const {
          protocol, // 'vb-catalog'
          type, // 'backends' | 'services'
          extensionId: id = Constants.ExtensionNamespaces.BASE,
          name,
        } = parseOverridesUrl(overridePath);

        if (type && name && protocol === Constants.VbProtocols.CATALOG && overridenData.url) {
          // for now only overriden 'backends' are processed
          const overridesForType = target[type] || (target[type] = {});
          const namespace = overridesForType[id] || (overridesForType[id] = {});
          namespace[name] = overridenData;
        } else {
          logger.warn('Invalid catalog override', overridePath);
        }
      } catch (e) {
        logger.error('Error processing service catalog override', overridePath, e);
      }
    });
  };

  /**
   * A class to support multiple catalog.json (and potentially catalog-x.jsons in the future).
   *
   * All Catalogs will be registered with this class, and loaded by this class.
   * Catalog's are associated with their 'namespace', which is either 'base', or the extension ID.
   * There is always a 'base' catalog.json.
   *
   * Clients (CatalogHandler) will ask this for names of the services and backends for a given catalog,
   * or for all catalogs, in priority order.
   */
  class CatalogRegistry {
    constructor(overrides) {
      // per instance overrides of the servers for registry objects (services/backends)
      // the value is populated from initParams['services.server.overrides']
      // We do not want to use default param value as it will not be used when the value is given and is 'null'
      this.overrides = overrides || {};

      /**
       * @type Promise<any>
       */
      this.loadPromise = Promise.resolve();
      this.pending = [];
      this.catalogs = {};

      // add new namespace with every registered catalog
      this.namespaces = [];

      this._localCatalogOverridesPromise = null;
      this._remoteCatalogOverridesPromise = null;
      this._catalogOverridePromise = null;

      // Set by the application, enables the catalog to access extensions.
      this.extensionRegistry = null;

      this.registryModified = new signals.Signal();
    }

    /**
     * Add a catalog.json path to the list of catalogs to be loaded, associated by namespace.
     *
     * At the next access to any of the public APIs below, any newly registered catalogs will be loaded.
     * @param name
     * @param path
     */
    register(name, path) {
      if (this.namespaces.includes(name)) {
        logger.warn('catalog already loaded, skipping', name, path);
        return;
      }

      this.namespaces.push(name);
      this.pending.push({ path, name });
      if (this.pending.length === 1) {
        this.registryModified.dispatch();
      }
    }

    /**
     * Object describing the backend and service names.
     * @typedef {Object} Names
     * @property {any[]} [backends]
     * @property {any[]} [services]
     * @property {any[]} [services]
     */

    /**
     * Get a list of names, so we can programmatically iterate resolutions.
     * This is used by ExtensionServices to get names specific to its namespace (extension ID)
     *
     * @returns {Promise<Names>}
     *
     * @see ExtensionServices
     */
    getNames(namespace = CatalogRegistry.ROOT_CATALOG, catalogObjectType = '') {
      // RIS TRAP overrides do not introduce new names, they just provide configuration for existing ones
      // Local server overrides (dev's configuration) may introduce new names
      return this.loadPending()
        .then(() => this.getNamesSync(namespace, catalogObjectType));
    }

    /**
     * Object describing the backend and service names for a catalog.
     * @typedef {Names} ExtNames
     * @property {string} namespace
     */

    /**
     * Returns an array of objects describing the backend and service names for each catalog.
     *
     * @see getNames
     * example:
     * [{
     *   "namespace": "base",
     *   "version": "1.0",
     *   "backends": ["fa"],
     *   "services": ["crmRestApi"]
     * },
     * {
     *   "namespace": "extB",
     *   "version": "2.1",
     *   "backends": ["simplecatalog"],
     *   "services": ["simplecatalog"]
     * }]
     *
     * @returns {Promise<ExtNames[]>}
     */
    getAllNames() {
      // RIS TRAP overrides do not introduce new names, they just provide configuration for existing ones
      // Local server overrides (dev's configuration) may introduce new names
      return this.loadPending()
        .then(() => this.getNamespaces().map((namespace) => {
          const names = this.getNamesSync(namespace);
          names.namespace = namespace;
          names.version = this.catalogs[namespace].version;
          return names;
        }));
    }

    /**
     * Get a list of names, so we can programmatically iterate resolutions.
     * This is used by ExtensionServices to get names specific to its namespace (extension ID)
     *
     * @param {string} [namespace='base']
     * @param {string} [catalogObjectType]
     * @returns {ExtNames[]}
     */
    getNamesSync(namespace = CatalogRegistry.ROOT_CATALOG, catalogObjectType = '') {
      switch (catalogObjectType) {
        case 'metadata':
        case 'serviceTypes':
        case 'backends':
        case 'services': {
          const catalog = this.catalogs[namespace] || {};
          return ({
            [catalogObjectType]: Object.keys(catalog[catalogObjectType] || {}),
          });
        }
        default: {
          const catalog = this.catalogs[namespace] || {};
          return ({
            backends: Object.keys(catalog.backends || {}),
            services: Object.keys(catalog.services || {}),
          });
        }
      }
    }

    /**
     * used by getAllNames, returns all keys for the base, and any extension, catalogs.
     * Always includes 'base', may contain <extension Id> keys.
     *
     * @returns {string[]}
     * @private
     */
    getNamespaces() {
      return this.namespaces;
    }

    /**
     * Get all catalog objects for the named catalog
     *
     * @param {string} [namespace='base'] Name (aka namespace) of the catalog to return
     * @param {string} [catalogObjectType] backends|services|serviceTypes|metadata
     * @returns {Promise<Object>}
     * @private
     */
    getCatalogObjects(namespace = CatalogRegistry.ROOT_CATALOG, catalogObjectType) {
      return this.loadPendingForType(catalogObjectType)
        .then(() => this.getCatalogObjectsSync(namespace, catalogObjectType));
    }

    /**
     *
     * @param {string} namespace
     * @param {string} [catalogObjectType]
     * @returns
     */
    getCatalogObjectsSync(namespace = CatalogRegistry.ROOT_CATALOG, catalogObjectType) {
      return this.catalogs[namespace];
    }

    /**
     * Gets metadata object found in the catalog of the 'namespace' extension.
     *
     * @param {string} [namespace = 'base']
     * @returns {Promise<Object>}
     */
    getMetadata(namespace = CatalogRegistry.ROOT_CATALOG) {
      return this.loadPending()
        .then(() => (this.catalogs[namespace] || {}).metadata);
    }

    /**
     * Gets metadata objects found in the catalog of the 'namespace' extension and all of
     * its required extensions.
     *
     * @param {string} [namespace] If not specified metadata for all extensions is returned.
     * @returns {Promise<Object>} Map of extension IDs to metadata objects found in catalogs.
     */
    getAllMetadata(namespace) {
      return this.loadPending()
        .then(() => {
          const metadata = {};

          let check = (candidateId) => true; // eslint-disable-line no-unused-vars
          if (namespace) {
            const requiredExtensions = this.getRequiredExtensionIds(namespace);
            check = (candidateId) => (candidateId === namespace || requiredExtensions.includes(candidateId));
          }

          Object.keys(this.catalogs || {}).forEach((extensionId) => {
            const catalog = this.catalogs[extensionId];
            if (catalog && catalog.metadata && check(extensionId)) {
              metadata[extensionId] = catalog.metadata;
            }
          });
          return metadata;
        });
    }

    /**
     * Tell the CatalogRegistry to update it internal state so that follow up sycnhronous getter calls
     * for the objects of the given type will return correct data.
     *
     * @param {string} [catalogObjectType] backends|services|serviceTypes|metadata
     * @returns {Promise} promise that the catalog registry is updated
     *                    to synchronously retrieve objects of the given type
     */
    loadPendingForType(catalogObjectType) {
      return this.loadPending();
    }

    /**
     * Loads the registered catalogs, that have not been loaded since the last time this was called.
     * Allows catalogs to be added after the vb-protocol has been initialized / used.
     *
     * @returns {Promise<void>}
     */
    loadPending() {
      if (this.pending.length === 0) {
        return this.loadPromise;
      }
      // clear the pending list
      const pending = this.pending;
      this.pending = [];
      // ... and load
      // wait for the previous loads to finish, load pending catalogs and merge the results
      this.loadPromise = this.loadPromise
        .then(() => {
          const promises = pending.map(({ path, name }) => {
            if (this.catalogs[name]) {
              logger.warn('catalog already loaded, skipping', name, path);
              return null;
            }

            // Apply local catalog overrides  immediately as they can introduce new
            // services & backends that have not yet be configured at
            // remote catalog overrides (i.e. on the  RIS TRAP service)
            return Promise.all([this.load(path), this.getLocalCatalogOverrides()])
              .then(([catText, catalogOverrides = {}]) => {
                try {
                  const catalog = SwaggerUtils.parseServiceText(catText);

                  if (catalog.backends) {
                    const backendsOverrides = (catalogOverrides.backends && catalogOverrides.backends[name]) || {};
                    Object.keys(catalog.backends).forEach((backendId) => {
                      const backend = catalog.backends[backendId];
                      const override = backendsOverrides[backendId];
                      if (override) {
                        backend.servers = [Utils.cloneObject(override, {})];
                      }
                      // override applies only to the server; we leave the rest of the backend definition intact
                      // for catalog backends we do not want to adjust transforms. we need to keep information
                      // around that the relative path is relative to its origin (i.e. the catalog)
                      adjustServers(backend.servers);
                    });
                  }

                  if (catalog.services) {
                    const servicesOverrides = (catalogOverrides.services && catalogOverrides.services[name]) || {};
                    Object.keys(catalog.services).forEach((serviceId) => {
                      const service = catalog.services[serviceId];
                      const override = servicesOverrides[serviceId];
                      if (override) {
                        service.servers = [Utils.cloneObject(override, {})];
                      }
                      // override applies only to the server; we leave the rest of the service definition intact
                      const transforms = service.info && service.info['x-vb'] && service.info['x-vb'].transforms;
                      adjustTransforms(path, transforms);
                      adjustServers(service.servers);
                    });
                  }
                  // remember catalog.path so we can resolve relative paths for the backend transforms
                  catalog.path = path;
                  catalog.version = this.getExtensionVersion(name);
                  catalog.namespace = name;

                  this.catalogs[name] = catalog;
                } catch (e) {
                  // log it, but continue loading other catalogs
                  logger.error('Invalid JSON for catalog', name, path, e);
                }
              });
          });
          return Promise.all(promises);
        })
        .then(() => this.catalogs)
        .catch((e) => {
          logger.error('Error loading catalogs', e);
          return this.catalogs;
        });

      return this.loadPromise;
    }

    /**
     *
     * @param path
     * @returns {Promise<string>}
     */
    // eslint-disable-next-line class-methods-use-this
    load(path) {
      return Utils.getRuntimeEnvironment()
        .then((rtEnv) => rtEnv.getServiceExtensionCatalog(path));
    }

    /**
     * Returns the server override of the catalog object identified by
     * type, namespace and id: backends/base:crmRestApi, services/extA:petstore.
     *
     * @param {string} type 'backends' or 'services'
     * @param {string} namespace extension ID or 'base'
     * @param {string} id name of the catalog object (a service or a backend)
     * @returns {Promise<object|null>}
     */
    getCatalogOverride(type, namespace, id) {
      return this.getCatalogOverrides()
        .then(() => this.getCatalogOverrideSync(type, namespace, id));
    }

    /**
     * Returns the server override of the catalog object identified by
     * type, namespace and id: backends/base:crmRestApi, services/extA:petstore.
     *
     * @param {string} type 'backends' or 'services'
     * @param {string} namespace extension ID or 'base'
     * @param {string} id name of the catalog object (a service or a backend)
     * @returns {object|null}
     */
    getCatalogOverrideSync(type, namespace, id) {
      const catalogOverrides = this.catalogOverrides || {};
      const serverOverride = catalogOverrides[type]
        && catalogOverrides[type][namespace]
        && catalogOverrides[type][namespace][id];
      if (serverOverride) {
        const server = Utils.cloneObject(serverOverride, {});
        adjustServers([server]);
        return server;
      }
      return null;
    }

    /**
     * Gets list of server overrides defined in the DT. Those backends should always
     * be accessed through the VB TRAP service.
     * @returns {Promise<object>}
     */
    _getLocalCatalogOverrides() {
      this._localCatalogOverridesPromise = this._localCatalogOverridesPromise || Promise.resolve()
        .then(() => Utils.getRuntimeEnvironment())
        .then((rtEnv) => Promise.all([rtEnv, rtEnv.getCatalogOverride()]))
        .then(([rtEnv, localServers]) => {
          let useDefaultTrap = false;
          if (localServers) {
            // handle RtEnv returning either string or JSON object
            // make a clone as we are modifying values
            localServers = typeof localServers === 'string'
              ? JSON.parse(localServers) : Utils.cloneObject(localServers, {});

            // make sure all local overrides use default/builtin TRAP
            Object.keys(localServers || {}).forEach((override) => {
              if (override.startsWith(BACKEND_OVERRIDE)) {
                const metadata = localServers[override];
                // set 'x-vb' : { defaultTrap: true }
                const extension = metadata[ServiceConstants.VB_EXTENSIONS]
                  || (metadata[ServiceConstants.VB_EXTENSIONS] = {});
                // indicate to the services that if we need to use TRAP for this backend
                // we should be using default (VB built in) TRAP service
                extension.defaultTrap = true;
                useDefaultTrap = true;
              }
            });
          }
          return Promise.all([localServers, useDefaultTrap && rtEnv.getLocalTrapConfiguration()]);
        })
        .then(([localServers, localServersConfig]) => {
          if (localServersConfig) {
            TrapData.setDefaultTrapConfig(localServersConfig);
          }
          return localServers;
        })
        .catch((error) => {
          logger.warn('failed to load locally overriden servers', error);
          return undefined;
        });
      return this._localCatalogOverridesPromise;
    }

    /**
     * Gets the catalog like structure defined by the local server overrides.
     *
     * Returned structure:
     *  {
     *    'backends' {
     *      'namespace1': {
     *        'name1': <server-def>,
     *        'name12': <server-def>
     *      },
     *      'namespace2': {
     *        'name2': <server-def>
     *      },
     *    },
     *    'services' {
     *      'namespace1': {
     *        'name3': <server-def>
     *      }
     *    }
     *  }
     *
     * @returns {Promise<object>}
     */
    getLocalCatalogOverrides() {
      return this._getLocalCatalogOverrides()
        .then((localOverrides = {}) => {
          const catalogOverrides = {};
          mergeOverrides(catalogOverrides, this.overrides);
          mergeOverrides(catalogOverrides, localOverrides);
          return catalogOverrides;
        });
    }

    /**
     * Gets list of overrides stored on the RIS TRAP service. This is where authentication info lives.
     *
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    _getRemoteCatalogOverrides() {
      this._remoteCatalogOverridesPromise = this._remoteCatalogOverridesPromise || Promise.resolve()
        .then(() => Utils.getRuntimeEnvironment())
        .then((rtEnv) => rtEnv.getTrapCatalogOverrides())
        .catch((error) => {
          logger.warn('failed to load servers details from TRAP service', error);
          return undefined;
        });
      return this._remoteCatalogOverridesPromise;
    }

    /**
     * Returns data structure with overrides of the catalog objects.
     * Data stucture format is same as for this.catalogs.
     *
     * @returns {Promise<Object>}
     */
    getCatalogOverrides() {
      if (!this._catalogOverridePromise) {
        this._catalogOverridePromise = Promise.resolve()
          .then(() => Promise.all([this._getLocalCatalogOverrides(), this._getRemoteCatalogOverrides()]))
          .then(([localServers, trapServers]) => {
            const catalogOverrides = {};

            mergeOverrides(catalogOverrides, this.overrides);

            const overrides = Object.assign({}, trapServers, localServers);
            mergeOverrides(catalogOverrides, overrides);

            this.catalogOverrides = catalogOverrides;
          })
          .catch((err) => {
            logger.error('Error loading service catalog overrides', err);
            this.catalogOverrides = {};
          })
          .then(() => this.catalogOverrides)
          .finally(() => {
            this.registryModified.dispatch();
          });
      }
      return this._catalogOverridePromise;
    }

    // -------------------------------------------------------------------------------------------------------------
    // The following methods do not really belong to this class.
    // They do not access state of this class. They should be in the ExtensionRegistry or related utils
    //

    /**
     * @param {string} extensionId
     * @param {string} candidateId
     * @return {boolean} true if 'extensionId' is the id of an existing extension and if 'candidateId' refers to either
     *                   base or to an extension that can be reached from the extension with 'extensionId' (i.e., if
     *                   this extension requires the one identified by 'candidateId').
     */
    isVisibleExtension(extensionId, candidateId) {
      if (this.extensionRegistry) {
        const extension = this.extensionRegistry.getExtensionById(extensionId);
        if (extension) {
          if (candidateId === Constants.ExtensionNamespaces.BASE) {
            return true;
          }

          if (extensionId !== Constants.ExtensionNamespaces.BASE) {
            // The 'isVisibleExtension' invoked here is recursive. Also it doesn't need to obtain the extension
            // from the extensionId as done above.
            return isVisibleExtension(extension, candidateId);
          }
        }
      }
      return false;
    }

    getExtensionVersion(extensionId) {
      const ext = this.extensionRegistry && this.extensionRegistry.getExtensionById(extensionId);
      let version = (ext && ext.version);
      if (!version && extensionId === Constants.CatalogNamespaces.BASE) {
        version = '1.0';
      }
      return version;
    }

    /**
     * @param {string} extensionId
     * @return {string[]} an array with the ids of the extensions whose catalog can be used by the extension
     *                            identified by the specified id.
     */
    getRequiredExtensionIds(extensionId) {
      if (this.extensionRegistry) {
        const extension = this.extensionRegistry.getExtensionById(extensionId);
        if (extension) {
          return extension.getRequiredExtensions().map((ext) => ext.id);
        }
      }
      return [];
    }

    /**
     *
     */
    dispose() {
      this.registryModified.removeAll();
      delete this.extensionRegistry;

      this.loadPromise = Promise.resolve();
      this._localCatalogOverridesPromise = null;
      this._remoteCatalogOverridesPromise = null;
      this._catalogOverridePromise = null;

      this.catalogs = {};
      this.namespaces = [];
    }
  }

  // an internal name for the base/root catalog
  CatalogRegistry.ROOT_CATALOG = Constants.CatalogNamespaces.BASE;

  return CatalogRegistry;
});

