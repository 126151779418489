/* eslint-disable no-param-reassign */

'use strict';

define('vb/private/vx/extensionRegistry',[
  'vb/private/vx/extension',
  'vb/private/configLoader',
  'vb/private/vx/appUiInfos',
  'compare-versions',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
], (Extension, ConfigLoader, AppUiInfos, compareVersions, Constants, Utils, Log) => {
  const logger = Log.getLogger('/vb/private/vx/extensionRegistry');
  /**
   * The regex used to find and extract the APP UIs id in an extension
   * App UIs are always located in a self/applications folder and the descriptor
   * is app.json.
   * @type {RegExp}
   */
  const appPackageRegex = new RegExp(`^${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}\
/${Constants.DefaultPaths.APPLICATIONS}(.*)/app.json$`);

  // const serviceRegex = /^services\/self\/([\w_$-]*)\/openapi[\w.]*\.json$/;
  const serviceRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/([\\w_$-]*)/openapi[\\w.]*\\.json$`);
  // const catalogRegex = /^services\/self\/catalog.json$/;
  const catalogRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/catalog.json$`);

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class ExtensionRegistry {
    /**
     * This is called from ConfigLoader.js
     */
    constructor(registryConfig) {
      this._extensionsPromise = null; // Initialized in getExtensions()
      this.appUiInfos = null; // Initialized in initRegistry()

      /**
       * A map of extension object keyed by their id
       * The only purpose is to provide a fast response to getExtensionById()
       * @type {Object}
       */
      this._extensions = {};

      this._digestState = registryConfig && registryConfig.digestState;

      /**
       * True when the application has an extension registry
       * @type {Boolean}
       */
      this._hasRegistry = registryConfig && registryConfig.hasRegistry();

      this.log = logger;

      if (this._hasRegistry) {
        this._fetchManifestPromise = registryConfig.fetchManifestPromise
          .catch(() => Utils.getResource('vb/private/vx/registryConfig')
            .then((RegistryConfig) => {
              // Create a RegistryConfig for a full digest
              const fullRegistryConfig = new RegistryConfig(globalThis.vbInitConfig, Constants.DigestState.FULL);
              this._extensionsPromise = null;
              this._digestState = Constants.DigestState.FULL;

              return fullRegistryConfig.fetchManifestPromise;
            }));
      } else {
        this.log.info('No extension registry defined.');

        // There is no extension manager defined
        // for this application so the manifest is an empty array.
        this._extensionsPromise = Promise.resolve([]);
      }
    }

    /**
     * The regex to find openapi3 files;
     * The () group is used to capture the service name from the path.
     * @return {RegExp}
     */
    static get serviceRegex() {
      return serviceRegex;
    }

    /**
     * The regular expresion to find a catalog in a list of extension files
     * @return {RegExp}
     */
    static get catalogRegex() {
      return catalogRegex;
    }

    /**
     * True when the application has a registry (webapp versus appUi)
     * @type {Boolean}
     */
    get hasRegistry() {
      return this._hasRegistry;
    }

    /**
     * Tell the registry to get the full digest if only a partial digest is loaded.
     * @return {Boolean} true if the registry was updated
     */
    async update() {
      // Only transition from partial digest to full digest
      if (this._digestState === Constants.DigestState.FULL) {
        return false;
      }

      // This need to be done first in case subsequent call to update are made
      this._digestState = Constants.DigestState.FULL;

      // Get around require circular dependency
      const RegistryConfig = await Utils.getResource('vb/private/vx/registryConfig');
      // Create a RegistryConfig for a full digest
      const fullRegistryConfig = new RegistryConfig(globalThis.vbInitConfig, Constants.DigestState.FULL);

      // Update the promise to the full digest
      this._fetchManifestPromise = fullRegistryConfig.fetchManifestPromise;
      this._extensionsPromise = this._getExtensionPromise();

      await this.initRegistry();
      return true;
    }

    /**
     * Load extension manager and design time manifest and merge them
     * Only called by subclass
     * @return {Promise<Array<Promise>>}
     */
    _loadManifest() {
      // Load the manifest from the extension manager and from DT and replace
      // the extensions and requirejsInfo with the ones from the DT manifest.
      return Utils.getRuntimeEnvironment().then((re) => re.getExtensionManifest())
        .then((dtManifest) => {
          if (dtManifest) {
            return this._fetchManifestPromise
              .then(([runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise]) => {
                // Replace or append manifest extensions using DT manifest
                if (runtimeDigestPromise) {
                  runtimeDigestPromise = runtimeDigestPromise
                    .then((extensions) => {
                      Utils.replaceOrAppendToArray(dtManifest.extensions, extensions);
                      return extensions;
                    });
                }

                // Replace or append manifest requirejsInfo using DT extensions requirejsInfo
                if (requirejsInfoDigestPromise) {
                  requirejsInfoDigestPromise = requirejsInfoDigestPromise
                    .then((requirejsInfo) => {
                      Utils.replaceOrAppendToArray(dtManifest.requirejsInfo, requirejsInfo);
                      return requirejsInfo;
                    });
                }

                // Replace or append manifest appUiInfo using DT extensions appUiInfo
                if (appUiInfoDigestPromise) {
                  appUiInfoDigestPromise = appUiInfoDigestPromise
                    .then((appUiInfo) => {
                      Utils.replaceOrAppendToArray(dtManifest.appUiInfo, appUiInfo);
                      return appUiInfo;
                    });
                }

                return [runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise];
              });
          }

          return this._fetchManifestPromise;
        });
    }

    /**
     * Constructor for a v2 extension
     * @param  {Object} def             definition from the manifest
     * @param  {Array} bundleIds        array of bundle ids
     * @param  {Array} bundledResources array of bundled resources
     * @param  {Object} componentsRequirejs
     * @return {Extension} the new extension
     */
    createExtension(def, appUiInfo, bundleIds, bundledResources, componentsRequirejs) {
      return new Extension(def, appUiInfo, bundleIds, bundledResources, componentsRequirejs, this);
    }

    async _getLoadManifestPromise() {
      // eslint-disable-next-line prefer-const
      let [runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise] = await this._loadManifest();

      // replace the appUiInfo array into a map keyed by extension id
      appUiInfoDigestPromise = appUiInfoDigestPromise.then((appUiInfo) => {
        const newAppUiInfo = {};

        if (appUiInfo) {
          appUiInfo.forEach((info) => {
            info.metadata.version = info.version;
            newAppUiInfo[info.id] = info.metadata;
          });
        }

        return newAppUiInfo;
      });

      // Create a requirejs config with the bundle info from the digest
      requirejsInfoDigestPromise = requirejsInfoDigestPromise.then((requirejsInfo) => {
        const bundles = {};
        const bundlesInfo = {};
        const bundledResources = {};
        // Master list of external components (absolute path)
        const externalComponents = {};
        // Per-extension list of components
        const components = {};

        requirejsInfo.forEach((info) => {
          bundlesInfo[info.id] = [];
          const metadata = info.metadata;

          if (metadata) {
            if (metadata.configurations) {
              // Check bundles defined in the extension
              const build = metadata.configurations.build;
              if (build) {
                if (build.bundles && typeof build.bundles === 'object') {
                  const bundleIds = bundlesInfo[info.id];
                  Object.keys(build.bundles).forEach((bundleId) => {
                    bundleIds.push(bundleId);
                  });
                  Object.assign(bundles, build.bundles);
                }

                if (build.bundledResources) {
                  bundledResources[info.id] = build.bundledResources;
                }
              }
            }

            if (metadata.components) {
              // entries in the components section might used expression like window.vbInitConfig or $initParams
              const componentsDef = ConfigLoader.getEvaluatedSafe(metadata.components);
              Object.entries(componentsDef || {}).forEach(([name, componentInfo]) => {
                const paths = componentInfo.requirejs && componentInfo.requirejs.paths;
                if (Utils.isObject(paths) && Object.keys(paths).length > 0) {
                  let path = paths[name];
                  // For reference component, the path key if not the component name.
                  // For example the name can be "oj-ref-moment" but for the path, the name is "moment"
                  if (!path) {
                    // the logic is to take the first entry, supposedly the only one
                    name = Object.keys(paths)[0];
                    path = paths[name];
                  }

                  if (Utils.isAbsoluteUrl(path)) {
                    // NOTE: If the logic for "keep the newest version of the component" changes, please make
                    // sure src/sw/extensionCacheController's component version checking is synched with it.
                    if (!componentInfo.version) {
                      this.log.warn('Component', name, 'does not have a version property, ignoring it.');
                    } else if (!externalComponents[name]
                        // Only keep the newest version of the component
                        || compareVersions(componentInfo.version, externalComponents[name].version) > 0) {
                      externalComponents[name] = componentInfo;
                    }
                  } else {
                    components[info.id] = Utils.mergeObject(components[info.id] || {}, componentInfo.requirejs);
                  }
                }
              });
            }
          }
        });

        // Configure the requirejs path and bundle info of all external components immediately
        // For each component only the latest version is used
        // Component local to extensions will be configured when the extension is initialized
        const paths = {};
        Object.entries(externalComponents).forEach(([name, comp]) => {
          this.log.info('Registering component', name, 'version', comp.version);
          Object.assign(paths, comp.requirejs.paths);
          Object.assign(bundles, comp.requirejs.bundles);
        });

        ConfigLoader.setConfiguration({ paths, bundles });

        // Remember the external components, so we can make sure extension component references have
        // the correct version for caching.
        const isCachingEnabled = Extension.isCachingEnabled();
        if (isCachingEnabled) {
          this.externalComponents = externalComponents;
        }

        // The bundlesInfo will be used during the extension creation the map the bundle URL
        // Local components will be initialized later when the extension is loaded
        return { bundlesInfo, bundledResources, components };
      });

      return [runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise];
    }

    /**
     * Calculate the base path of the extended resource given the container
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    getBasePath(path, container = { extensionId: '' }) {
      return `${container.extensionId}/${path}`;
    }

    /**
     * For v2, the base path is prefixed with 'ui/'
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForUi(path, container) {
      return `${Constants.DefaultPaths.UI}${this.getBasePath(path, container)}`;
    }

    /**
     * Get the base path for the given container. If the container is a layout,
     * getBasePathForLayout will be called, otherwise, getBasePathForUi is called.
     *
     * @param path
     * @param container
     * @returns {string}
     */
    getBasePathForContainer(path, container) {
      return path.startsWith(Constants.DefaultPaths.LAYOUTS) ? this.getBasePathForLayout(path, container)
        : this.getBasePathForUi(path, container);
    }

    /**
     * Retrieve the base path for an extension layout in v2
     * Convert dynamicLayouts/{path} or dynamicLayouts/self/{path} when the container
     * is in an App UI to dynamicLayouts/{extId}/{path}
     * extId is the extension id of the container (could be base)
     * @param  {String} origPath
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    getBasePathForLayout(origPath, container) {
      let path = origPath;

      // The object being extended is either in dynamicLayouts/... or ui/...
      // It is possible the path does not start with ui only when the object being extended is
      // in the unified app. In this case we need prefix the path with ui to find the extension
      // Note that extension id ('base' or an other id) is inserted in path only when needede a few line below
      if (!path.startsWith(Constants.DefaultPaths.LAYOUTS) && !path.startsWith(Constants.DefaultPaths.UI)) {
        path = `${Constants.DefaultPaths.UI}${path}`;
      }

      const pathElements = Utils.addTrailingSlash(path).split('/');

      // If the extensionId is base, we need to insert base into the path,
      // e.g., dynamicLayouts/layoutId => dynamicLayouts/base/layoutId.
      if (container.extensionId === 'base') {
        pathElements.splice(1, 0, 'base');
      } else if (pathElements[1] !== 'base') {
        // substitute the extension id, e.g., dynamicLayouts/self/foo -> dynamicLayouts/extA/foo,
        // except for dynamicLayouts/base/foo
        pathElements[1] = container.extensionId;
      }

      return pathElements.join('/');
    }

    /**
     * Retrieve the promise to an array of Extension object
     * @return {Promise<Array<Extension>>}
     */
    async _getExtensionPromise() {
      try {
        const manifestPromises = await this._getLoadManifestPromise();
        const [extArray, requirejsInfo, appUiInfo] = await Promise.all(manifestPromises);

        this.log.info('Found', extArray.length, 'extension(s) in the', this._digestState, 'manifest.');

        // From this point to when endDeferRequireConfig is called, all calls
        // to ConfigLoader.setConfiguration are cached.
        ConfigLoader.startDeferRequireConfig();

        try {
          const extensions = [];
          const fullArray = [];

          extArray.forEach((definition) => {
            const extensionId = definition.id;
            const currentExtension = this._extensions[extensionId];
            // Only consider new extensions
            if (!currentExtension) {
              const extension = this.createExtension(definition,
                appUiInfo && appUiInfo[extensionId],
                requirejsInfo && requirejsInfo.bundlesInfo[extensionId],
                requirejsInfo && requirejsInfo.bundledResources[extensionId],
                requirejsInfo && requirejsInfo.components[extensionId]);

              if (!extension.isValid()) {
                this.log.error('Invalid manifest for extension:', extensionId, 'version:', extension.version);
              } else {
                extensions.push(extension);
                fullArray.push(extension);
                this._extensions[extensionId] = extension;
              }
            } else {
              fullArray.push(currentExtension);
            }
          });

          const promises = extensions
            // if this extension extends the application, load the bundle or
            // create a require mapping so the resources are available.
            // Extensions with only App UI definition are loaded on demand.
            .filter((extension) => extension.extendsBaseArtifact())
            .map((extension) => extension.init());

          await Promise.all(promises);

          return fullArray;
        } finally {
          ConfigLoader.endDeferRequireConfig();
        }
      } catch (err) {
        // Swallow the error so that it doesn't break the application, but no extension will be loaded
        this.log.error('Error loading extension registry, no App UI will be loaded', err);
        return [];
      }
    }

    /**
     * Returns a promise that resolves with an array of extensions or an empty array
     * @return {Promise<Array<Extension>>} a promise that resolve to an array of extension
     */
    getExtensions() {
      this._extensionsPromise = this._extensionsPromise || this._getExtensionPromise();
      return this._extensionsPromise;
    }

    /**
     * Retrieve a map of AppUiInfo for all the App UI available in all the extensions
     * @return {Promise<AppUiInfos>} a promise that resolve with an AppUiInfos
     */
    async _getAppUiInfos() {
      // For previewing root page, DT needs to disable App UIs
      const disableAppUis = await Utils.getRuntimeEnvironment().then((re) => re.disableAppUis());
      // App UIs are disable by returning the empty appUiInfos
      if (disableAppUis === true) {
        return new AppUiInfos();
      }

      const appUiInfos = this.appUiInfos || new AppUiInfos();

      const extensions = await this.getExtensions();
      // Traverse the array of extension from first to last. The extension manager is responsible
      // for properly ordering this array of extensions given the dependencies in the extension manager.
      extensions.forEach((extension) => {
        const infos = (extension.appUiInfo && Object.values(extension.appUiInfo)) || [];
        if (infos.length > 0) {
          infos.forEach((info) => {
            // Skip this appUi if it has already be added by the the partial digest
            if (!appUiInfos.exist(info.id)) {
              appUiInfos.add(info.id, extension, info);
            }
          });
        } else {
          const files = extension.files || [];

          // Look for the package json files
          files.forEach((file) => {
            const match = file.match(appPackageRegex);
            const id = match && match[1];
            if (id) {
              // Skip this appUi if it has already be added by the the partial digest
              if (!appUiInfos.exist(id)) {
                appUiInfos.add(id, extension);
              }
            }
          });
        }
      });

      return appUiInfos;
    }

    async initRegistry() {
      this.appUiInfos = await this._getAppUiInfos();
    }

    /**
     * Loads all the extension for a specific container given its path. It returns a promise
     * that resolves in an array of extensions object, either PageExtension or FlowExtension.
     * @param  {String} path the path of the object for which we are looking for extensions
     * @param  {Container} container the container for which the extension is being loaded
     * @return {Promise} a promise to an array of extension objects
     */
    loadContainerExtensions(path, container) {
      return this.getExtensions()
        .then((extensions) => {
          if (extensions.length === 0) {
            return [];
          }

          const promises = [];
          const basePath = this.getBasePathForContainer(path, container);
          const Clazz = container.constructor.extensionClass;
          // container name may not necessarily be the actual resourceName. So use the resourceName property instead
          const extPath = `${basePath}${container.extensionResourceName}${Clazz.resourceSuffix}`;
          const isOnline = Utils.isOnline();
          const isCachingEnabled = Extension.isCachingEnabled();

          // Traverse the array of extension from first to last. The extension manager is responsible
          // for properly ordering this array of extensions given the dependencies in the extension manager.
          extensions.forEach((extension) => {
            const files = extension.files || [];

            // If the manifest contains an extension for this artifact, creates an extension object for it
            // But only if we're online or the extension has been marked as available offline
            if ((isOnline || (isCachingEnabled && extension.offlineEnabled))
              && files.indexOf(extPath) >= 0) {
              // TODO: reduce params?
              const ext = new (Clazz)(extension, basePath, container);
              const promise = ext.load().then(() => ext);
              promises.push(promise);
            }
          });

          // All files are then loaded in parallel
          return Promise.all(promises);
        });
    }

    /**
     * Loads all the extensions for a specific V2 Bundle given its path. It returns a promise
     * that resolves in an array of V2 Bundle Extension objects.
     * @param  {String} path the path of the V2 Bundle Definition for which we are looking for extensions
     * @param  {BundleV2Definition} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise} a promise to an array of V2 Bundle Extension objects
     */
    loadTranslationExtensions(path, bundleDefinition) {
      return this.getExtensions().then((extensions) => {
        const promises = [];

        // Calculate the base path for translations resource extensions.
        const basePath = `${Constants.DefaultPaths.TRANSLATIONS}${this.getBasePath(path, bundleDefinition)}`;
        const extensionPath = `${basePath}-x`;
        const Clazz = bundleDefinition.constructor.extensionClass;
        const extPath = `${extensionPath}.js`;

        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          const files = extension.files || [];

          // If the manifest contains an extension for this artifact, creates an extension object for it
          if (files.indexOf(extPath) >= 0) {
            const ext = new (Clazz)(extension, extensionPath, bundleDefinition);
            const promise = ext.load().then(() => ext);
            promises.push(promise);
          }
        });

        // All files are then loaded in parallel
        return Promise.all(promises);
      });
    }

    /**
     * Retrieve a map of all extensions that define translation bundles.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return this.getExtensions().then((extensions) => {
        const results = {};
        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          // Look for the translations configuration files
          // translations-config are always located in translations folder and the descriptor
          // is translations-config.json.
          try {
            if (extension.fileExists('translations/translations-config.json')) {
              results[extension.id] = extension;
            }
          } catch (err) {
            // ignore
          }
        });

        return results;
      });
    }

    /**
     * Look up the extension identified by id.
     *
     * @param id extension id
     * @returns {Extension}
     */
    getExtensionById(id) {
      return this._extensions[id];
    }

    /**
     * This function checks if an extension depends directly or indirectly on another extension given their ids.
     *
     * @param {string} extensionId
     * @param {string} upstreamExtensionId
     * @returns {boolean} true if extensionId depends on upstreamExtensionId
     */
    isDependent(extensionId, upstreamExtensionId) {
      // extensions do not need to declare dependency on 'base'
      if (upstreamExtensionId === Constants.ExtensionNamespaces.BASE) {
        return true;
      }

      const checked = {};
      const processing = {};
      const toCheck = [];
      let current = extensionId;
      while (current) {
        if (current === upstreamExtensionId) {
          return true;
        }
        checked[current] = true;

        const extension = this.getExtensionById(current);
        if (extension && extension.dependencies) {
          Object.keys(extension.dependencies).forEach((depId) => {
            if (!checked[depId] && !processing[depId]) {
              toCheck.push(depId);
              processing[depId] = true;
            }
          });
        }
        current = toCheck.shift();
      }
      return false;
    }

    /**
     * Add requirejs mappings
     * @param {Object} paths
     */
    addRequireMapping(paths) {
      ConfigLoader.setConfiguration({ paths });
    }

    /**
     * Creates an ExtensionServices Object for the application extension, and creates a name/file map
     * from the contents of the extension.
     *
     * @param {String} extensionId
     * @param {Object} options the standard options used to construct a Services object.
     *
     * @returns {Promise}
     */
    loadServicesModel(extensionId, options) {
      // populated with the services we can find on the extension manifest
      const extensionServiceMap = {};

      return this.getExtensions()
        .then((extensions) => {
          const ext = extensions.find((ex) => ex.id === extensionId);
          if (ext) {
            ext.files.forEach((file) => {
              let name;
              let path;

              const match = file.match(this.constructor.serviceRegex);
              // [0] is the whole match, [1] is the first (and only) group
              if (match) {
                path = match[0];
                name = match[1];
              }

              if (name && path) {
                // we need to check if the extension has an explicit serviceFileMap declaration to ensure we are
                // not replacing it
                const declaredPath = options && options.serviceFileMap && options.serviceFileMap[name];
                if (!declaredPath) {
                  extensionServiceMap[name] = path;
                } else if (declaredPath !== path) {
                  // it would be weird to declare a path for a service that doesn't match its name,
                  // if one that did match its name already existed.
                  this.log.warn('Extension', extensionId, 'contains service metadata ', path,
                    '. The declared file will be used instead: ', declaredPath);
                }
              }
            });
          } else {
            // this should never happen
            this.log.warn('Unable to find extension services for extension, continuing: ', ext);
          }
          return this.findCatalog(ext);
        })
        .then((catalogPath) => {
          const optionsClone = Object.assign({ extensionServiceMap }, options);

          if (catalogPath) {
            optionsClone.extensions = optionsClone.extensions || {};
            optionsClone.extensions.catalogPaths = {
              [extensionId]: catalogPath,
            };
          }

          // ExtensionServices need to be loaded later than this module because
          // it forces JET to load before ojL10n is setup in ConfigLoader
          return Utils.getResource('vb/private/services/extensionServices')
            .then((ExtensionServices) => new ExtensionServices(optionsClone));
        });
    }

    /**
     * if there is a catalog.json in self/, returns the vx-mapped path to the file
     * ex:  vx/ext2/self/services/catalog.json
     * @param {Object} extension
     * @returns {string|undefined}
     */
    findCatalog(extension) {
      let found;
      extension.files.some((file) => {
        if (this.constructor.catalogRegex.test(file)) {
          found = `${Constants.EXTENSION_PATH}${extension.id}/${file}`;
        }
        return !!found;
      });
      return found;
    }
  }

  return ExtensionRegistry;
});

