'use strict';

define('vb/private/stateManagement/shellPageInfo',[
  'vb/binding/expression',
  'vb/private/utils',
], (Expression, Utils) => {
  /**
   * A class used to describe the shell page
   *
   * @property {String} id
   * @property {Boolean} excludeFromUrl
   * @property {Extension | undefined } extension the extension object that contain this shell page
   *
   */
  class ShellPageInfo {
    /**
     *
     * @param {Application} application
     * @param {String} appUiId
     */
    constructor(application, appUiId) {
      // By default the shell page comes from the default page of the application
      this.id = application.definition.defaultPage.id;

      // but if this is an App UI, the shell page could be the hostRootPage property
      if (appUiId) {
        this.appUiId = appUiId;
        const info = application.appUiInfos.getInfo(appUiId);

        /*
         * Retrieve the root page the host application should use. By default the root page
         * is defined in the defaultPage property in the descriptor but App UI have the ability
         * to change the root page using the hostRootPage property. That property is available
         * in the AppUiInfo structure given by the extension manager digest.
         * This value can also be an expression
         */
        const { hostRootPage } = info;
        if (hostRootPage) {
          // hostRootPage can be pageId or extId:pageId
          const {
            main, prefix,
          } = Utils.parseQualifiedIdentifier(hostRootPage, { prefixToken: ':', suffixToken: '' });
          this.id = main;
          // also keep track of the extension since the shell could be in an extension
          if (prefix) {
            this.extension = application.extensionRegistry.getExtensionById(prefix);
          } else {
            this.extension = application.appUiInfos.getExtension(appUiId);
          }
        }
      }

      // always skips the shell page in the URL
      this.excludeFromUrl = true;
    }
  }

  return ShellPageInfo;
});

