'use strict';

define('vb/action/builtin/resetDirtyDataStatusAction',[
  'vb/action/action',
  'vb/private/action/actionHelper',
  'vb/private/constants',
  'vb/private/log',
], (Action, ActionHelper, Constants, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/resetDirtyDataStatusAction');

  /**
   * Re-sets the dirty data state of all tracked variables in the current container's
   * scope and all of the contained containers and their extensions.
   */
  class ResetDirtyDataStatusAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * Sets the current available context.
     * @param context
     */
    setContext(context) {
      this.context = context;
    }

    /**
     * Inject the available contexts for expression evaluations.
     *
     * @param availableContexts the available contexts such as $application, $page, etc
     */
    setAvailableContext(availableContexts) {
      this.availableContexts = availableContexts;
    }

    /**
     * Re-sets the dirty data state of variables being tracked in the current
     * container's scope and all of the contained containers including their extensions.
     * @param parameters
     * @returns always returns a Success outcome
     */
    // eslint-disable-next-line no-unused-vars
    perform(parameters) {
      let exception;
      let variables = parameters.variables || [];

      // Implicitly treat the single variable as an array
      if (!Array.isArray(variables)) {
        variables = [variables];
      }

      // if no params were passed, reset the dirty data the original way, ie,
      // reset the dirty data state of all variables from the current container's scope
      // and all of the contained containers and their extensions.
      if (!variables.length) {
        const currentContainer = this.context.container;
        currentContainer.resetAllDirtyData();
        return Action.createSuccessOutcome();
      }

      variables.some((variable) => {
        try {
          this.resetDirtyData(variable);
          return false; // keep calling some()
        } catch (e) {
          // let the parent logger get this, so it actually has the action id
          exception = e;
          return true; // terminate the some()
        }
      });

      // this should just throw the error
      if (exception) {
        throw exception;
      }

      return Action.createSuccessOutcome();
    }

    /**
     * Analyzes the expression to determine the variable and then
     * resets the dirty data of the given variable.
     * @param expr the expression
     */
    resetDirtyData(expr) {
      const stack = ActionHelper.analyzeExprAst(expr, this.availableContexts);

      if (stack.length < 1) {
        throw new Error('Invalid empty expression.');
      }

      const containerContextName = stack[0].name;
      if (Constants.ALL_SCOPES.indexOf(containerContextName) !== -1) {
        let containerContext = null;

        if (containerContextName !== '$variables') {
          containerContext = stack[0].value;

          if (stack.length < 2) {
            throw ActionHelper.getMissingNamespaceError(expr);
          }

          // With the introduction of $base, the namespace following up can be page, flow or application
          if (containerContextName === '$base') {
            containerContext = ActionHelper.checkBase(expr, stack);
          } else if (containerContextName === '$extension') {
            const baseName = stack[1].name;
            if (baseName !== 'base') {
              throw ActionHelper.getInvalidNamespaceError(baseName, expr);
            }

            // Remove $extension from stack
            stack.splice(0, 1);

            containerContext = ActionHelper.checkBase(expr, stack);
          }

          if (!containerContext) {
            throw new Error(
              `${containerContextName} is not accessible in expression ${expr} from the current action chain.`,
            );
          }

          const namespace = stack[1].name;

          if (namespace !== Constants.VariableNamespace.VARIABLES) {
            throw new Error(
              `Cannot reset dirty data status, invalid namespace ${namespace} in expression ${expr}.`,
            );
          }

          if (stack.length < 3) {
            throw ActionHelper.getMissingVariableNameError(expr);
          }

          const variable = containerContext.getVariable(stack[2].name, Constants.VariableNamespace.VARIABLES);
          if (variable) {
            variable.resetDirtyData();
          }
        } else {
          // $variables is not a valid scope for resetting dirty data
          throw new Error(`$variables is not a valid scope in expression ${expr}.`);
        }
      } else {
        // invalid scope name
        throw new Error(`Invalid scope name ${containerContextName} in expression ${expr}.`);
      }
    }
  }

  return ResetDirtyDataStatusAction;
});

