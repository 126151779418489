'use strict';

define('vb/private/vx/extension',[
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/errors/httpError',
  'vbsw/private/serviceWorkerManager',
], (ConfigLoader, Constants, Utils, Log, LoadMonitorOptions, HttpError, ServiceWorkerManager) => {
  const logger = Log.getLogger('/vb/private/vx/extension', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  /**
   * The name given to the extension bundle stored in the extension.
   * @type {String}
   */
  const VB_APP_BUNDLE_NAME = 'vb-app-bundle.js';

  /**
   * a regex to find string starting with ui/base or dynamicLayouts/base or translations/base
   * @type {RegExp}
   */
  // eslint-disable-next-line max-len
  const baseRegex = new RegExp(`^${Constants.DefaultPaths.UI}base/|^${Constants.DefaultPaths.LAYOUTS}base/|^${Constants.DefaultPaths.TRANSLATIONS}base/`);

  class Extension {
    constructor(def, appUiInfo, bundleIds = [], bundledResources = [], componentsRequirejs = {}, registry) {
      this.id = def.id;

      this.registry = registry;

      this.version = def.version;

      // the baseUrl defined by the extension manager. This is where resources are retrieved from
      // the extension manager
      this.baseUrlDef = def.baseUrl;

      // the simplified base URL used to defined a requirejs mapping to baseUrlDef
      // this is used so resource can be accessed using a path starting with "vx/ext-id/..."
      this.baseUrl = `${Constants.EXTENSION_PATH}${this.id}/`;

      /** @type {Array<String>} */
      this.files = def.files || [];

      this._initPromise = null; // Initialized in init()
      /**
       * Resources common to an extension (like functions exposed via $modules) are stored here.
       * @type {any}
       */
      this.extensionResources = {};

      this.log = logger;

      this.appUiInfo = {};
      const appUis = (appUiInfo && appUiInfo.applications) || [];
      appUis.forEach((appUi) => {
        this.appUiInfo[appUi.id] = appUi;
      });

      // If any App UI is marked offline, mark the extension
      this.offlineEnabled = !!def.offline || !!appUis.find((appUi) => !!appUi.offline);

      /**
       * This is where is stored the components info from the digest.
       * It will not be used until the extension is initialized
       * @type {Object}
       */
      this.componentsRequirejs = componentsRequirejs;

      // When the bundle is declared in the digest, we only need to register
      // the requirejs mapping for it
      this.bundleUrls = [];
      if (bundleIds.length > 0) {
        const mapping = {};
        this.log.info('Extension', this.id, 'version:', this.version, 'is using', bundleIds.length, 'bundle');
        bundleIds.forEach((bundleId) => {
          const baseUrl = Utils.removeTrailingSlash(this.baseUrl);
          const url = bundleId.replace(baseUrl, this.baseUrlDef);
          mapping[bundleId] = url;
          // Remember the bundle urls so we can cache them
          this.bundleUrls.push(`${url}.js`);
        });
        this.registry.addRequireMapping(mapping);
        // Remember that this extension is using requirejs bundles so that forcing
        // loading the bundle during init() is not necessary
      }

      // For cleaned bundle, merge the bundledResources array with the files array
      if (bundledResources.length > 0) {
        if (this.files.length > 0) {
          // Use a Set to remove potential duplicate
          this.files = [...new Set(this.files.concat(bundledResources))];
        } else {
          this.files = [...bundledResources];
        }
      }

      this.dependencies = def.dependencies || {};
    }

    /**
     * Returns the full URL to access an artifact in this extension
     * @return {?String}
     */
    getAbsoluteUrl() {
      // in v2, 'ui/' is in the basePath
      // <baseUrl>/ui/self
      return `${this.baseUrlDef}/${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
    }

    /**
     * Given a set, recursively append to this set all the extensions that
     * either depends or are dependent on this extension.
     *
     * @param {Set}  allDependencies  All dependencies
     */
    getAllDependencies(allDependencies) {
      if (allDependencies.has(this)) {
        return;
      }

      allDependencies.add(this);

      const dependencies = [
        // Recurse into extensions that extends this extension
        ...this.getDependentExtensions(),
        // Recurse into extension that it depends on
        ...this.getRequiredExtensions(),
      ];

      dependencies.forEach((ext) => {
        ext.getAllDependencies(allDependencies);
      });
    }

    /**
     * Initialize this extension and all its dependent extensions
     *
     * @return {Promise} a promise that resolve when all related extension are initialized
     */
    init() {
      // init may be called multiple times.  _initPromise is set in internalInit().
      // Avoid caclulating dependencies if we've already been init'ed
      return this._initPromise || Promise.resolve().then(() => {
        const allDependencies = new Set();
        this.getAllDependencies(allDependencies);

        const exts = [...allDependencies];

        // If the extension is marked for offline enabled, cache it
        const cacheExtension = Extension.isCachingEnabled() && this.offlineEnabled;

        return Promise.all(exts.map((ext) => ext.internalInit(cacheExtension)));
      });
    }

    /**
     * Perform the internal tasks needed to initialize this extension
     * @param {boolean} cacheExtension should the extension be cached
     * @return {Promise} a promise that resolve when all tasks are performed
     */
    internalInit(cacheExtension) {
      if (!this._initPromise) {
        this.log.info('Initializing extension', this.id);
        this._initPromise = Promise.all([this._initBundles(cacheExtension), this._initRequireConfig()])
          .then(() => {
            // At this point the object cannot be mutated
            Object.freeze(this);
          });
      }

      return this._initPromise;
    }

    /**
     * Initialize the extension bundle given the bundle information in the manifest coming from
     * the requirejs-info digest and recurse in dependent extensions.
     * If no bundle information is given, call super to use the v1 method of forcing the bundle loading.
     *
     * @param {boolean} [cacheExtension] should the extension be cached
     * @return {Promise} a promise that resolve when the bundle and its dependent is initalized
     */
    _initBundles(cacheExtension) {
      // If no bundle info is given, force the loading using the bundle name
      if (!this.bundleUrls.length) {
        // If a bundle is defined in the list of files, use it.
        const bundleUrl = this.findBundleUrl();

        if (cacheExtension) {
          // Cache the extension for pwa, but we don't need to wait for its promise to resolve.
          this.cacheExtension(bundleUrl ? [bundleUrl] : undefined);
        }

        let promise;

        // Either load the extension bundle or create a require mapping vx/extId/
        if (bundleUrl) {
          promise = this.loadBundle(bundleUrl);
        } else {
          this.log.info('Applying extension', this.id, 'version:', this.version, 'without bundle');
          this.registry.addRequireMapping(this.buildMapping());
        }

        return promise;
      }

      return Promise.resolve()
        .then(() => {
          // If we don't call super._initBundles(), call cacheExtension() directly.
          if (cacheExtension) {
            // Cache the extension for pwa, but we don't need to wait for its promise to resolve.
            this.cacheExtension(this.bundleUrls);
          }
        });
    }

    /**
     * Caching is enabled if it is a WebApp PWA and vbInitConfig.PWA_CONFIG.disableCaching is NOT set to true
     * and vbInitConfig.PWA_CONFIG.disableExtensionCaching is NOT set to true
     * @returns {boolean}
     * @protected
     */
    static isCachingEnabled() {
      const swMgrInstance = ServiceWorkerManager.getInstance();
      return swMgrInstance.isExtensionCachingEnabled();
    }

    /**
     * Cache the Extension's bundleUrls if supplied, or its files.
     * @param {Array<string>} [bundleUrls] if caching bundles rather than the extension's files
     * @returns {Promise} a promise that resolves when the cache is initialized
     * @protected
     */
    cacheExtension(bundleUrls) {
      return Promise.resolve().then(() => {
        this.log.info('Initializing Cache for extension', this.id, 'version:', this.version);

        let resources;
        if (bundleUrls) {
          // Copy bundleUrls, because we are going to add to it
          resources = [].concat(bundleUrls);
          bundleUrls.forEach((bundleUrl) => {
            // cache the bundle's .map file too
            const bundleUrlMap = `${bundleUrl}.map`;
            const bundleMapPath = this.files.find((file) => file.indexOf(bundleUrlMap) > 0);
            if (bundleMapPath) {
              const bundleMapUrl = `${this.baseUrlDef}/${bundleMapPath}`;
              resources.push(bundleMapUrl);
            }
          });
        } else {
          // No bundles?  Get the list of resources to cache.
          // Let service worker do the concatentation of path + resource
          resources = this.files;
        }

        const swMgrInstance = ServiceWorkerManager.getInstance();
        return swMgrInstance.setupExtensionCache(this.id, this.version, this.baseUrlDef, resources)
          .catch((error) => {
            // Log error and continue
            this.log.error('Failed to initialize cache for extension', this.id, 'version:', this.version, error);
          });
      });
    }

    /**
     * Initializes the require configuration from the requirejs-info digest.
     */
    _initRequireConfig() {
      ConfigLoader.addRequirejsPaths(this.componentsRequirejs, this.baseUrl, this.id);
      // Since the registration is done, remove the declaration from the componentsRequirejs
      this.componentsRequirejs = {};
    }

    /**
     * Retrieve all the extensions that this extension depends on.
     * @return {Array<Extension>}
     */
    getRequiredExtensions() {
      const result = [];
      Object.keys(this.dependencies).forEach((extId) => {
        const ext = this.registry.getExtensionById(extId);
        if (ext) {
          result.push(ext);
        } else {
          this.log.warn('Extension', this.id, 'depends on extension', extId, 'that does not exist.');
        }
      });

      return result;
    }

    /**
     * Return all the extensions extending this extension
     * @return {Array<Extension>}
     * @private
     */
    getDependentExtensions() {
      // eslint-disable-next-line no-underscore-dangle
      return Object.values(this.registry._extensions).filter((ext) => ext.dependencies[this.id]);
    }

    /**
     * return true when at least one of the file extends a base object
     * @return {Boolean}
     */
    extendsBaseArtifact() {
      return this.files.some((file) => baseRegex.test(file));
    }

    /**
     * Verify the validity of the extension metadata returned by the extension manager
     * @return {Boolean}
     */
    isValid() {
      return (this.baseUrlDef && this.id && Array.isArray(this.files) && this.files.length > 0);
    }

    /**
     * Check if a resource file exist in the extension
     * @param  {String} path
     * @return {Boolean}
     */
    fileExists(path) {
      if (this.files.indexOf(path) >= 0) {
        return true;
      }
      throw new HttpError(404, null, `${path} not found.`);
    }

    /**
     * Retrieve the URL of the bundle if there is one defined in the extension
     * @return {String} the bundle URL or undefined
     * @private
     */
    findBundleUrl() {
      let bundleUrl;

      const bundlePath = this.files.find((path) => path.indexOf(VB_APP_BUNDLE_NAME) > 0);
      if (bundlePath) {
        bundleUrl = `${this.baseUrlDef}/${bundlePath}`;
        bundleUrl = requirejs.toUrl(bundleUrl);
      }

      return bundleUrl;
    }

    /**
     * Load and monitor the extension bundle
     * @param  {String} bundleUrl
     * @return {Promise} a promise that resolve when the bundle is loaded
     */
    loadBundle(bundleUrl) {
      const mo = new LoadMonitorOptions(
        LoadMonitorOptions.SPAN_NAMES.LOAD_EXTENSION_BUNDLE, `Extension ${this.id} bundle load`, this,
      );
      return this.log.monitor(mo, (extensionLoadTimer) => Utils.getResource(bundleUrl)
        .then(() => {
          this.log.greenInfo(
            'Extension', this.id, 'version:', this.version, 'is forcing a bundle load', extensionLoadTimer(),
          );
        })
        .catch((error) => {
          extensionLoadTimer(error);
          throw error;
        }));
    }

    /**
     * Given an App UI id, return the info for it
     * @param  {String} id an App UI id
     * @return {Object} the appUiInfo
     */
    getAppUiInfo(id) {
      return this.appUiInfo[id] || { id };
    }

    /**
     * Given an extension id and its base URL this function returns a mapping (object with properties/values)
     * to be used with requirejs like:
     * {
     *   'vx/ext-id': 'file://.../sources'
     * }
     * @return {Object} a mapping string
     */
    buildMapping() {
      return {
        [Utils.removeTrailingSlash(this.baseUrl)]: this.baseUrlDef,
      };
    }
  }

  return Extension;
});

