'use strict';

define('vb/types/factories/serviceDataProviderFactory',['vb/types/factories/typeFactory',
  'vb/private/types/dataProviders/dynamicServiceDataProvider'], (TypeFactory, DynamicServiceDataProvider) => {
  /**
   * A type factory implementation for the builtin type ServiceDataProvider
   */
  class ServiceDataProviderFactory extends TypeFactory {
    /**
     * Creates a standalone instance of a VB ServiceDataProvider that is initialized with the options that the caller
     * provides. The instance is a standalone and is not backed by a variable / redux framework that manages its
     * state.
     * @param {object|{}} options used to instantiate the ServiceDataProvider with, usually contains these properties
     * @property {object} options.dataProviderOptions - options used to instantiate the service data provider instance.
     * For a list of supported properties refer to the ServiceDataProvider documentation. Some exceptions are
     *  a. no expressions are allowed for properties, values must be primitives or Object / Array literals.
     *  b. properties that take callback must specify a function reference
     *
     * @property {object} options.serviceOptions - options used to instantiate the RestHelper with. RestHelper is
     * created internally by the SDP instance to fetch data
     *
     * @property {object} options.vbContext - options that provide the 'vb' context for the current call (this object
     *                                     is typically provided by a VB API or callback mechanism). If this object is
     *                                     not available, pass in an object with the format
     *                                     <i>{ extensionId: string }</i>, in which 'extensionId' is the id of the
     *                                     extension <b>executing</b> the code.
     *
     * @return Promise<DynamicServiceDataProvider> resolves with DynamicServiceDataProvider standalone instance
     *
     * @todo need to work with JET to define a context, if possible, to determine which extension
     * this is being used in (if any).
     */
    // eslint-disable-next-line no-unused-vars
    static createInstance(options = {}) {
      const { dataProviderOptions, serviceOptions, vbContext } = options;
      const dpInstance = new DynamicServiceDataProvider(dataProviderOptions, serviceOptions, vbContext);
      return dpInstance.activateAsync().then(() => {
        return dpInstance;
      });
    }
  }

  return ServiceDataProviderFactory;
});

