'use strict';

define('vb/private/services/definition/serviceDefinition',[
  'vb/private/configLoader',
  'vb/private/services/endpoint',
  'vb/private/services/serviceConstants',
  'vb/private/services/serviceUtils',
  'vb/private/services/swaggerUtils',
  'vb/private/services/definition/endpointPathNode',
  'vb/private/services/definition/openApiDefinitionObject',
  'vbsw/private/constants',
], (
  ConfigLoader,
  Endpoint,
  ServiceConstants,
  ServiceUtils,
  SwaggerUtils,
  EndpointPathNode,
  OpenApiDefinitionObject,
  SWConstants,
) => {
  // const logger = Log.getLogger('/vb/private/services/services');

  const METHOD_SEPARATOR = '/~';

  /**
   * ServiceDefinition
   */
  class ServiceDefinition extends OpenApiDefinitionObject {
    /**
     *
     * @param name
     * @param path
     * @param protocolRegistry utility that handles vb-specific protocols
     * @param catalogInfo catalog.json information, if any
     * @param openApi abstract definition model, implementation is either OpenApi 2 or OpenApi 3
     * @param relativePath container path, relative to requirejs root
     * @param requestInit fetch options
     * @param {string} namespace
     * @param isUnrestrictedRelative true for application; flows/pages may not load from parent folders (app may).
     */
    constructor(name,
      path, protocolRegistry, catalogInfo, openApi, relativePath, requestInit, namespace, isUnrestrictedRelative) {
      super(name, openApi, null, namespace, relativePath, catalogInfo, isUnrestrictedRelative);

      this._openApi = openApi;
      this._requestInit = requestInit;

      this._protocolRegistry = protocolRegistry;

      this._server = openApi.getServerForProfile(ConfigLoader.activeProfile);

      this._nameForProxy = ServiceUtils.getNameForProxy(name, path, catalogInfo.chain);

      // merge chosen server's "x-vb" info into the global ("info"-level) one
      if (this.server[ServiceConstants.VB_EXTENSIONS]) {
        // the server ones will take precedence
        const mergedExtensions = ServiceUtils
          .mergeExtensions(this.extensions, this.server[ServiceConstants.VB_EXTENSIONS]);

        this.extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo, mergedExtensions);
      } else {
        // we need the augmented info for the 'legacy' syntax, also, for http/https promotion via proxy
        this.extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo, this.extensions);
      }

      // hack for BUFP-25774 - for business objects; this replaces regex for 'ic/builder' in the plugins
      // @todo: remove business object hack when 'x-vb:authentication' handling is implemented in the runtime
      if (name === 'businessObjects' || path.endsWith('businessObjects/service.json')) {
        this.extensions.authenticationType = SWConstants.AuthenticationType.ORACLE_CLOUD;
      }

      this._endpoints = {};

      this._endpointsByPath = {};
      this._templatedPathEndpoints = new EndpointPathNode();

      // Delaying loading will (probably) not help as we only create ServiceDefinition when we need to
      // resolve an endpoint, which means that immidiatly after ServiceDefnition creation it will be
      // asked for an Endpoint. Since we are (commonly) aked to get Endpoint based on the operationID
      // we have to go over all the paths and operations within them to find the operation definition (an Endpoint).
      openApi.getPathObjects().forEach((pathObject) => {
        const pathKey = pathObject.path;

        // Get operationObject reference, but avoid creating new operationObject for now.
        // At this point we just need operation key (get, put, post...) and the operationId.
        pathObject.getOperationObjectRefs().forEach((operationObjectRef) => {
          const operationKey = operationObjectRef.name;

          const endpointId = SwaggerUtils.getEndpointId(pathKey, operationKey, operationObjectRef);

          // creating Endpoint is expensive so create it on demand
          const endpointGetter = {
            get: () => {
              const endpoint = new Endpoint({
                name: endpointId,
                service: this,
                protocolRegistry,
                pathKey,
                pathObject,
                operationKey,
                operationObject: operationObjectRef.get(),
                isUnrestrictedRelative: this.isUnrestrictedRelative,
              });

              endpointGetter.get = () => endpoint;
              return endpoint;
            },
          };

          this._endpoints[endpointId] = endpointGetter;

          // we need to tell apart the operationRefs based on their methods
          // so we include the method (operationKey) in the path mapping
          const operationRefPath = `${pathKey}${METHOD_SEPARATOR}${operationKey}`;
          if (operationRefPath.indexOf('{') !== -1) {
            // handle parameters in paths
            const pathSegments = operationRefPath.split('/');
            this._templatedPathEndpoints.addEndpoint(endpointGetter, pathSegments);
          } else {
            this._endpointsByPath[operationRefPath] = endpointGetter;
          }
        });
      });
    }

    get server() {
      return this._server;
    }

    get requestInit() {
      return this._requestInit;
    }

    get nameForProxy() {
      return this._nameForProxy;
    }

    /**
     * Gets JSON Object representing OpenApi document defining the service.
     *
     * The following is disabled:
     * If endpointReference parameter is provided, the returned document will have references resolved
     * in the operation object corresponding to the endpointReference.
     *
     * @param {EndpointReference} [endpointReference]
     * @returns {Promise<Object>} OpenApi JSON document
     */
    getDefinition(endpointReference) {
      return Promise.resolve()
        // .then(() => {
        //   if (endpointReference) {
        //     // expand metadata for the endpoint of interest and only then return the OpenApi document (VBS-31587)
        //     const endpoint = this.findEndpoint(endpointReference);
        //     if (endpoint) {
        //       return endpoint.getMetadata();
        //     }
        //   }
        //   return null;
        // })
        .then(() => this._openApi.definition);
    }

    getServers() {
      return this._openApi.getServers();
    }

    isDefined() {
      return !!this.url;
    }

    /**
     * Gets a value of named property defined in the service extension.
     * The extension value from the catalgo takes presedance over the value declared
     * in the service definition document.
     *
     * @param {string} propertyName
     * @returns
     */
    getExtensionProperty(propertyName) {
      const mergedExtensions = this.catalogInfo && this.catalogInfo.mergedExtensions;
      const catalogProperty = mergedExtensions && mergedExtensions[propertyName];
      if (catalogProperty) {
        return catalogProperty;
      }

      const definitionExtension = (this._openApi && this._openApi.getExtensions()) || {};
      return definitionExtension[propertyName];
    }

    /**
     * @return {boolean} true if this service definition is visible outside the container that defines it
     *                   (i.e., if an extension can use it).
     */
    isExtensionAccessible() {
      const definitionExtension = this._openApi && this._openApi.getExtensions();
      if ((definitionExtension && definitionExtension.extensionAccess) === true) {
        return true;
      }

      const mergedExtensions = this.catalogInfo && this.catalogInfo.mergedExtensions;
      return (mergedExtensions && mergedExtensions.extensionAccess) === true;
    }

    // eslint-disable-next-line class-methods-use-this
    getEndpointReferenceHash(endpointRef) {
      return endpointRef.operationId;
    }

    _getEndpointByPath(endpointPath, method) {
      const operationRefPath = `${endpointPath}${METHOD_SEPARATOR}${method || 'get'}`;
      const endpoint = this._endpointsByPath[operationRefPath];
      return endpoint && endpoint.get();
    }

    _getEndpointByTemplatedPath(endpointPath, method) {
      // handle params in path
      const operationRefPath = `${endpointPath}${METHOD_SEPARATOR}${method || 'get'}`;
      const endpoint = this._templatedPathEndpoints.findEndpoint(operationRefPath.split('/'));
      return endpoint && endpoint.get();
    }

    _getEndpointByRef(endpointRef) {
      const endpoint = this._endpoints[this.getEndpointReferenceHash(endpointRef)];
      return endpoint && endpoint.get();
    }

    findEndpoint(endpointRef) {
      const {
        url: operationUrl,
        operationPath,
        operationMethod,
      } = endpointRef;

      if (operationUrl) {
        const serverUrl = this.server.getUrl();
        if (operationUrl.startsWith(serverUrl)) {
          // search the paths

          // EndpointReference now parses the operationRef value
          let resolvedOperationPath = operationPath;
          // '#' is default operationRef value sent by Dynamic UI when it only passes us resolved data fetch URL
          // in that scenario we need to calculate service path from the resolved URL as the operation path will
          // be empty for that kind of (invalid) operationRef
          if (!resolvedOperationPath) {
            const refUrl = new URL(operationUrl);
            // remove ? and #
            refUrl.search = '';
            refUrl.hash = '';

            // https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#pathsObject
            // When matching URLs, concrete (non-templated) paths would be matched before their templated counterparts.
            // Removes the server part of the URL and use the remaining segment as the operation path
            resolvedOperationPath = refUrl.href.substring((new URL(serverUrl)).href.length);
          }

          if (resolvedOperationPath[0] !== '/') {
            resolvedOperationPath = `/${resolvedOperationPath}`;
          }
          return this._getEndpointByPath(resolvedOperationPath, operationMethod)
            // handle parameters in paths
            || this._getEndpointByTemplatedPath(resolvedOperationPath, operationMethod);
        }
        return null;
      }
      return this._getEndpointByRef(endpointRef);
    }
  }

  return ServiceDefinition;
});

