'use strict';

define('vb/private/stateManagement/switcherFlowMixin',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherPageMixin',
], (Mixin, SwitcherPageMixin) => {
  /**
   * Mixin to be used with flows running in a switcher
   * It modifies the original behavior of the flow to run in a oj-vb-switcher component
   */
  const SwitcherFlowMixin = (superclass) => class extends superclass {
    constructor(id, parent, path, className) {
      super(id, parent, path, className);

      this.parent.initRouter();
    }

    get rootPage() {
      return this.parent.rootPage;
    }

    get switcher() {
      return this.rootPage.switcher;
    }

    get rootRouter() {
      return this.rootPage.rootRouter;
    }

    /**
     * When a page is created in a switcher, mix in the switcher functionality
     */
    createPage(pageInfo, path) {
      return new (Mixin(this.constructor.PageClass).with(SwitcherPageMixin))(pageInfo.id, this, path);
    }

    /**
     * When a flow is created in the switcher, mix in the switcher functionality
     *
     * @param   {String}  id  the flow id
     * @param   {Container}  container  the container of the new flow
     * @return  {Flow} a flow instance
     */
    createFlow(id, container) {
      const FlowClass = this.constructor.FlowClass;

      // Construct a Flow (either Flow or PackageFlow depending the environment) with the behavior
      // required for working in the switcher component.
      return new (Mixin(FlowClass).with(SwitcherFlowMixin))(id, container);
    }

    isEmbeddable() {
      const { navigation } = this.definition;
      if (navigation && navigation.embeddable === 'enabled') {
        return true;
      }

      // Cannot go past the root page. If we reach it without finding an embeddable parent
      // this is not embeddable.
      if (this.parent === this.rootPage) {
        return false;
      }

      if (this.isDefault() && this.parent.isEmbeddable()) {
        return true;
      }

      return false;
    }

    /**
     * @return {Promise}
     */
    exit() {
      // Since exit is called by traversing the container hierarchy (see deleteElement in switcherBridge),
      // not by the router, dispose has to be called explicitely otherwise in some case the flow is never
      // disposed because the disconnect is not called on the parent page and an already exist scope error
      // will occur when re-creating the same flow.
      return super.exit().then(() => this.dispose());
    }
  };

  return SwitcherFlowMixin;
});

