'use strict';

define('vb/private/types/defaultSecurityProvider',['vb/types/securityProvider',
  'vb/private/utils',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
  'vb/private/log',
  'vb/private/types/utils/cryptoUtils',
  'urijs/URI',
  'vbsw/private/serviceWorkerManager',
  'vbsw/private/fetchHandler',
  'vbsw/private/pwa/pwaUtils',
  'vbsw/private/constants',
  'vb/private/constants',
], (SecurityProvider, Utils, Router, Application, Log, CryptoUtils, URI,
  ServiceWorkerManager, FetchHandler, PwaUtils, SwConstants, Constants) => {
  // the amount of time in milliseconds to wait for the access token from the hidden iframe
  const REFRESH_ACCESS_TOKEN_TIMEOUT = 3000;

  // the id for the hidden iframe for refreshing the access token
  const REFRESH_ACCESS_TOKEN_IFRAME = 'vb-refresh-access-token-iframe';

  /**
   * Builtin implementation of SecurityProvider for VBCS.
   */
  class DefaultSecurityProvider extends SecurityProvider {
    /**
     * Extract idcsInfo and authentication objects from config.
     *
     * @param config configuration object
     * @returns {Promise}
     */
    initialize(config) {
      return Promise.resolve().then(() => {
        this.idcsInfo = config.idcsInfo || {};

        // get the default security configuration
        this.defaultAuthentication = config.authentication;

        // get the override for the JWT clock skew tolerance
        this.jwtClockSkewTolerance = config.jwtClockSkewTolerance;

        const vbConfig = globalThis.vbInitConfig || {};
        this.enableApplicationOAuthFlow = !vbConfig.IS_DT_MODE && config.applicationOAuthFlow === 'authorizationCode';

        if (this.enableApplicationOAuthFlow) {
          this.tokenExchangeUrl = `${config.oauthUrl}/token`;

          // initiate OAuth flow
          return this.vbGetOAuthAccessToken();
        }

        return undefined;
      }).then(() => super.initialize(config));
    }

    /**
     * Fetch the current user info using the url in the config object. If no url is provided, derive the
     * url from vbInitConfig
     *
     * @param config the config object containing the url for fetching the current user
     */
    fetchCurrentUser(config) {
      const vbConfig = globalThis.vbInitConfig;

      // if no url or service endpoint ID is provided, derive url from vbInitConfig
      if (!config.url && !config.endpoint) {
        // the url is different between staged/live and DT mode
        if (!vbConfig.IS_DT_MODE) {
          // staged/live
          config.url = `${Router.baseUrl}_currentuser`;
        } else {
          // DT mode
          // get the context root from vbInitConfig and make sure it starts and ends with /
          const contextRoot = this.getContextRoot();

          config.url = `${contextRoot}resources/security/user`;
        }
      }

      // for oauth flow, use oauthUrl instead which accepts bearer token
      if (this.enableApplicationOAuthFlow) {
        config.url = config.oauthUrl;

        // ignore injected user info
        delete vbConfig.INJECTED;
      }

      return super.fetchCurrentUser(config);
    }

    /**
     * This method is overridden to work around an issue with a PWA application running in the mobile
     * Safari browser where the SSO cookie is not accessible from the service worker immediately
     * after logging in. The workaround is to have the main application, which has the SSO cookie,
     * fetch the current user information directly instead of going through the service worker.
     *
     * @param config configuration object containing the url for fetch current user information.
     * @param forceOffline force offline (used by unit tests only)
     * @returns {Promise}
     */
    fetchCurrentUserRaw(config, forceOffline) {
      // only perform the workaround for a PWA application running in the mobile Safari browser
      if (Utils.isMobileSafari() && PwaUtils.isPwaConfig(globalThis.vbInitConfig)) {
        // requests to be skipped by the service worker with and without :443 port
        const requestsToSkip = [
          {
            url: config.url.replace(':443', ''),
            method: 'GET',
          },
          {
            url: config.url,
            method: 'GET',
          },
        ];

        const isOnline = PwaUtils.isOnline();

        // register the urls to be skipped by the service worker
        return ServiceWorkerManager.getInstance().addRequestsToSkip(requestsToSkip)
          .then(() => {
            // create a fetch handler to handle the current user request
            const fetchHandler = new FetchHandler('/', { useCacheResponseWhenOfflineHeaderEnabled: true });

            // remember fetch and Request before offline toolkit overrides them
            const browserFetch = fetch;
            const browserRequest = Request;

            return fetchHandler.installPlugins([
              {
                url: 'vbsw/private/plugins/oAuthAccessTokenHandlerPlugin',
                params: {
                  useAccessToken: !!this.accessToken,
                },
              },
              'vbsw/private/plugins/authPostprocessorHandlerPlugin',
            ])
              .then(() => fetchHandler.initializePersistenceManagerWithStore())
              .then((pm) => {
                // force the persistence manager offline (used by unit tests only)
                if (forceOffline) {
                  pm.forceOffline(true);
                }

                const options = {
                  credentials: 'same-origin',
                  headers: {
                    [SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE]: true, // use offline toolkit to handle caching
                    'Cache-Control': 'no-cache, no-store', // bypass browser cache
                    Pragma: 'no-cache', // bypass IE browser cache
                    [Constants.Headers.USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE]: true,
                  },
                };

                const request = new Request(config.url, options);
                return fetchHandler.handleRequest(request)
                  .catch((error) => {
                    // VBS-30434 VBCS Mobile PWA App Offline Persistent Mode Fails To Load On First Attempt
                    // If we 'think' we're online, it may be a false positive.
                    // Force persistence manager offline and try again.
                    if (isOnline && !forceOffline) {
                      pm.forceOffline(true);
                      return fetchHandler.handleRequest(request);
                    }

                    // Otherwise, re-throw the error
                    throw error;
                  })
                  // make response match the result from the rest helper
                  .then((response) => ({ response }));
              })
              .finally(() => {
                // BUFP-42180: switch to using OPT api once available for restoring fetch and Request
                // restore fetch and Request
                fetch = browserFetch;
                Request = browserRequest;

                // Promise.finally return value is ignored unless the returned value is a rejected promise.
                return ServiceWorkerManager.getInstance().removeRequestsToSkip(requestsToSkip);
              });
          });
      }

      return super.fetchCurrentUserRaw(config);
    }

    /**
     * Returns the context root for the application.
     *
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getContextRoot() {
      const vbConfig = globalThis.vbInitConfig;
      let contextRoot = vbConfig.CONTEXT_ROOT ? vbConfig.CONTEXT_ROOT : '/';

      if (!contextRoot.startsWith('/')) {
        contextRoot = `/${contextRoot}`;
      }
      if (!contextRoot.endsWith('/')) {
        contextRoot = `${contextRoot}/`;
      }

      return contextRoot;
    }

    /**
     * Return an array of service worker plugin urls specified in userConfig.configuration.serviceWorkerConfig object
     * in the application model json file.
     *
     * @param config the userConfig.configuration.serviceWorkerConfig object
     * @param isAnonymous true if the application is anonymous
     * @returns {Array}
     */
    getServiceWorkerPlugins(config, isAnonymous = false) {
      // Get the config plugins
      return Utils.getRuntimeEnvironment()
        .then((rte) => Promise.all([super.getServiceWorkerPlugins(config, isAnonymous),
          rte.getServiceWorkerPlugins()])
          .then((pluginsArr) => {
            const plugins = pluginsArr[0];

            const vbConfig = globalThis.vbInitConfig || {};
            const versionId = vbConfig.BASE_URL_TOKEN;
            const orgId = vbConfig.ORGANIZATION_ID;
            const vbServer = vbConfig.VB_SERVER;
            const serverRoot = Utils.addTrailingSlash(Utils.cleanUpExtraSlashes(vbConfig.INGRESS_PATH || ''));

            // grab the injected CSRF token if exists
            const csrfToken = vbConfig.INJECTED && vbConfig.INJECTED.security && vbConfig.INJECTED.security.csrfToken;

            // the default plugins specific to VBCS
            const defaultPlugins = [
              {
                url: 'vbsw/private/plugins/sessionExpirePlugin',
                params: {
                  contextRoot: this.getContextRoot(),
                  idcsHost: this.idcsInfo.hostName,
                },
              },
              {
                url: 'vbsw/private/plugins/authPreprocessorHandlerPlugin',
                params: {
                  isAnonymous,
                  defaultAuthentication: this.defaultAuthentication,
                  passthroughs:
                    (config.configuration && config.configuration.passthroughs) || [],
                },
              },
              {
                url: 'vbsw/private/plugins/sessionTrackingHandlerPlugin',
                params: {
                  userId: this.userInfo.email,
                },
              },
              // if orgId is not undefined, install multiTenantCsrfTokenHandlerPlugin instead
              (orgId === undefined)
                ? {
                  url: 'vbsw/private/plugins/csrfTokenHandlerPlugin',
                  params: {
                    versionId,
                    csrfToken,
                  },
                }
                : {
                  url: 'vbsw/private/plugins/multiTenantCsrfTokenHandlerPlugin',
                  params: {
                    orgId,
                    serverRoot,
                    vbServer,
                  },
                },
              {
                url: 'vbsw/private/plugins/implicitFlowHandlerPlugin',
                params: {
                  allowedScopes: this.idcsInfo.allowedScopes ? this.idcsInfo.allowedScopes : [],
                  jwtClockSkewTolerance: this.jwtClockSkewTolerance,
                },
              },
              {
                url: 'vbsw/private/plugins/tokenRelayHandlerPlugin',
                params: {
                  jwtClockSkewTolerance: this.jwtClockSkewTolerance,
                },
              },
              {
                url: 'vbsw/private/plugins/authHeaderHandlerPlugin',
                params: {
                  isAnonymous,
                },
              },
              {
                url: 'vbsw/private/plugins/oAuthAccessTokenHandlerPlugin',
                params: {
                  useAccessToken: !!this.accessToken,
                },
              },
              {
                url: 'vbsw/private/plugins/resourceChangedPlugin',
                params: {
                  contextRoot: this.getContextRoot(),
                  versionId,
                },
              },
            ];

            // add the default plugins
            defaultPlugins.forEach((plugin) => {
              if (plugins.indexOf(plugin) === -1) {
                plugins.push(plugin);
              }
            });

            // add additional plugins provided via runtime environment
            plugins.push(...pluginsArr[1]);

            // Make sure no vb- headers used for intra plugin communication escape
            plugins.push('vbsw/private/plugins/authPostprocessorHandlerPlugin');
            // install workaround for Chrome bug and make sure we can diagnose errors
            plugins.push('vbsw/private/plugins/errorResponseHandlerPlugin');
            return plugins;
          }));
    }

    /**
     * If application OAuth flow is enabled, remove the refresh token key from storage before
     * logging out.
     *
     * @param  {String} logoutUrl  the home URL of the application
     */
    handleLogout(logoutUrl) {
      if (this.enableApplicationOAuthFlow) {
        return this.removeRefreshToken()
          .then(() => super.handleLogout(logoutUrl));
      }

      return super.handleLogout(logoutUrl);
    }

    /**
     * This method is used to handle the 'vbSessionExpired' message from the service worker.
     */
    vbSessionExpired() {
      const loginUrl = this.getLoginUrl();

      // go through runtimeEnvironment to give DT a chance to handle session expiry
      return Application.runtimeEnvironment.sessionExpired(loginUrl);
    }

    /**
     * This method is used to handle the 'vbRefreshImplicitFlowAccessToken' message from the ImplicitFlowHandlerPlugin
     * for refreshing the access token.
     *
     * @param scope the scope for which to refresh the access token
     * @returns {Promise}
     */
    vbRefreshImplicitFlowAccessToken(scope) {
      return new Promise((resolve, reject) => {
        // get the hidden iframe for refreshing the access token
        const refreshTokenIframe = document.getElementById(REFRESH_ACCESS_TOKEN_IFRAME);

        if (refreshTokenIframe) {
          let timeout;

          // listener for waiting for the access token from the hidden iframe
          const listener = (e) => {
            if (e.origin === globalThis.location.origin && e.data.method === 'vbRefreshAuthToken') {
              const accessTokenHash = e.data.args[0];

              if (accessTokenHash) {
                // clear the timeout and remove the listener
                clearTimeout(timeout);
                globalThis.removeEventListener('message', listener);

                // parse the hash containing the access token
                const fragments = Router.parseFragment(accessTokenHash);

                // extract the new access token from the hash
                const token = `${fragments.token_type} ${fragments.access_token}`;

                resolve(token);
              }
            }
          };
          globalThis.addEventListener('message', listener);

          // redirect the hidden iframe to the authorization url
          const authUrl = this.getOAuthAuthorizationUrl(scope);
          refreshTokenIframe.src = authUrl;

          timeout = setTimeout(() => {
            globalThis.removeEventListener('message', listener);
            reject(new Error('Refreshing access token timed out.'));

            // either we got redirected to the login page because the user is not logged in,
            // or the consent form if the scope is being access for the first time, simply redirect
            // the application to the authorization url so the user can log in or respond to the
            // consent form
            globalThis.location.href = authUrl;
          }, REFRESH_ACCESS_TOKEN_TIMEOUT);
        }
      });
    }

    /**
     * Obtain an OAuth access token by running the authorization code with PKCE flow.
     *
     * @returns {Promise<String>}
     */
    vbGetOAuthAccessToken() {
      return Promise.resolve().then(() => {
        if (this.accessToken) {
          return this.accessToken;
        }

        return this.retrieveRefreshToken()
          .then((refreshToken) => {
            if (!refreshToken) {
              const authCode = Router.consumeAuthCode();

              if (authCode) {
                // exchange the authorization code for an access token
                return this.exchangeOAuthAccessToken(authCode);
              }

              let vbScope = this.idcsInfo.allowedScopes
                .find((scope) => scope.endsWith('urn:opc:resource:consumer::all'));
              vbScope = `${vbScope} offline_access`; // need to append offline_access to get refresh token

              const codeVerifier = this.generateAndStoreCodeVerifier();

              return CryptoUtils.generateCodeChallenge(codeVerifier)
                .then((codeChallenge) => {
                  const authUrl = this.getOAuthAuthorizationUrl(vbScope, 'code', codeChallenge);

                  return DefaultSecurityProvider.redirectToOAuthAuthorizationUrl(authUrl);
                });
            }

            // use the refresh token to get a new access token
            return this.vbRefreshOAuthAccessToken();
          });
      });
    }

    /**
     * Redirect to the authorization url to get an authorization code which will reload the application.
     *
     * @param authUrl url for the authorization endpoint
     */
    static redirectToOAuthAuthorizationUrl(authUrl) {
      return Promise.resolve().then(() => {
        globalThis.location.href = authUrl;

        throw new Error('Redirecting to authorization url, stopping further initialization.');
      });
    }

    /**
     * Exchange an authorization code for an access token and a refresh token.
     *
     * @param authCode authorization code
     * @returns {Promise<String>}
     */
    exchangeOAuthAccessToken(authCode) {
      return Promise.resolve().then(() => {
        const codeVerifier = this.retrieveCodeVerifier();
        if (!codeVerifier) {
          throw new Error('Failed to retrieve code verifier.');
        }

        const request = new Request(this.tokenExchangeUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${authCode}&code_verifier=${codeVerifier}&scope=`,
        });

        return fetch(request)
          .then((response) => {
            if (response.ok) {
              return response.json()
                .then((tokenInfo) => {
                  this.accessToken = tokenInfo.access_token;

                  return this.storeRefreshToken(tokenInfo.refresh_token)
                    .then(() => this.accessToken);
                });
            }

            throw new Error(`Failed to exchange token with status ${response.status}`);
          });
      });
    }

    /**
     * Get a new access token by using the refresh token.
     *
     * @returns {Promise<String>}
     */
    vbRefreshOAuthAccessToken() {
      return this.retrieveRefreshToken()
        .then((refreshToken) => {
          // no refresh token found, restart the oauth flow
          if (!refreshToken) {
            return this.vbGetOAuthAccessToken();
          }

          const codeVerifier = this.retrieveCodeVerifier();
          if (!codeVerifier) {
            // restart the oauth flow if the code verifier no longer exists
            this.accessToken = null;
            return this.removeRefreshToken()
              .then(() => this.vbGetOAuthAccessToken());
          }

          const request = new Request(this.tokenExchangeUrl, {
            method: 'POST',
            credentials: 'omit',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `refresh_token=${encodeURIComponent(refreshToken)}&code_verifier=${codeVerifier}`,
          });

          return fetch(request)
            .then((response) => {
              if (response.ok) {
                return response.json()
                  .then((tokenInfo) => {
                    this.accessToken = tokenInfo.access_token;

                    return this.storeRefreshToken(tokenInfo.refresh_token)
                      .then(() => this.accessToken);
                  });
              }

              // invalidate the current access token and remove the refresh token from storage so
              // we can restart the oauth flow
              this.accessToken = null;
              return this.removeRefreshToken()
                .then(() => this.vbGetOAuthAccessToken());
            });
        });
    }

    /**
     * Initiate the OAuth2 code grant flow by redirecting to the given redirectUrl in a new tab and wait
     * for completion event to be posted from the new tab.
     *
     * @param {string} redirectUrl url for the third-party OAuth endpoint
     * @param {object} params query parameters for the redirectUrl
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    vbRunOAuth2CodeGrantFlow(redirectUrl, params) {
      return new Promise((resolve, reject) => {
        const authUri = new URI(redirectUrl);

        Object.entries(params).forEach(([key, value]) => {
          authUri.addSearch(key, value);
        });

        if (globalThis.open(authUri.href(), '_blank')) {
          let timeout;

          // listener for waiting for the code grant flow to complete
          const listener = (e) => {
            if (e.origin === globalThis.location.origin && e.data.method === 'vbOAuth2CodeGrantFlowCompleted') {
              // clear the timeout
              clearTimeout(timeout);

              resolve();
            }
          };

          globalThis.addEventListener('message', listener, { once: true });
          timeout = setTimeout(() => {
            globalThis.removeEventListener('message', listener);
            reject(new Error('OAuth2 Code Grant flow timed out.'));
          }, 60000); // TODO: make this configurable
        } else {
          reject(new Error('OAuth2 Code Grant flow failed due to inability to open a new browser tab.'));
        }
      });
    }

    /**
     * Construct an authorization url for obtaining an OAuth access token for the given scope.
     *
     * @param scope to scope for which to request an access token
     * @returns {String}
     */
    getOAuthAuthorizationUrl(scope, responseType = 'token', codeChallenge = '', codeChallengeMethod = 'S256') {
      const uri = new URI(`${this.idcsInfo.hostName}/oauth2/v1/authorize`);

      uri.addSearch('client_id', responseType === 'code'
        ? this.idcsInfo.authorizationCodeClientId : this.idcsInfo.clientId)
        .addSearch('response_type', responseType)
        .addSearch('scope', scope)
        .addSearch('redirect_uri', this.idcsInfo.oAuthRedirectEndpoint);

      if (codeChallenge) {
        uri.addSearch('code_challenge', codeChallenge);
        uri.addSearch('code_challenge_method', codeChallengeMethod);
      }

      return uri.href();
    }

    /**
     * Return an instance of key store handler registered via vbInitConfig.KEY_STORE_HANDLER.
     *
     * @returns {Promise}
     */
    getKeyStoreHandler() {
      this.keyStoreHandlerPromise = this.keyStoreHandlerPromise || Promise.resolve()
        .then(() => {
          const vbConfig = globalThis.vbInitConfig || {};
          const path = vbConfig.KEY_STORE_HANDLER;

          if (path) {
            return Utils.getResource(path)
              .then((Module) => (typeof Module === 'function' ? new Module() : Module));
          }

          // return a stub key store handler
          return {
            retrieveKey() {
              return Promise.resolve();
            },
            storeKey() {
              return Promise.resolve();
            },
            removeKey() {
              return Promise.resolve();
            },
          };
        });

      return this.keyStoreHandlerPromise;
    }

    /**
     * Returns a key for storing and retrieving the code verifier for this application.
     *
     * @returns {string}
     */
    get codeVerifierStorageKey() {
      return `orcl.vbcs.${this.idcsInfo.authorizationCodeClientId}.codeVerifier`;
    }

    /**
     * Generates and stores a code verifier in local storage.
     *
     * @returns {string}
     */
    generateAndStoreCodeVerifier() {
      const codeVerifier = CryptoUtils.generateCodeVerifier();
      localStorage.setItem(this.codeVerifierStorageKey, codeVerifier);

      return codeVerifier;
    }

    /**
     * Retrieves the code verifier from local storage.
     *
     * @returns {string}
     */
    retrieveCodeVerifier() {
      return localStorage.getItem(this.codeVerifierStorageKey);
    }

    /**
     * Use the key store handler provided by the application to retrieve the refresh token from storage.
     *
     * @returns {Promise<Promise<void> | *>}
     */
    retrieveRefreshToken() {
      return this.getKeyStoreHandler()
        .then((keyStoreHandler) => keyStoreHandler.retrieveKey());
    }

    /**
     * Use the key store handler provided by the application to save the refresh token to storage.
     *
     * @param refreshToken refresh token to store
     * @returns {Promise<Promise<void> | *>}
     */
    storeRefreshToken(refreshToken) {
      return this.getKeyStoreHandler()
        .then((keyStoreHandler) => keyStoreHandler.storeKey(refreshToken));
    }

    /**
     * Use the key store handler provided by the application to remove the refresh token from storage.
     *
     * @returns {Promise<Promise<void> | *>}
     */
    removeRefreshToken() {
      return this.getKeyStoreHandler()
        .then((keyStoreHandler) => keyStoreHandler.removeKey());
    }
  }

  return DefaultSecurityProvider;
});

