'use strict';

define('vb/private/services/definition/programmaticPluginFactory',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/services/servicesLoader',
  'vb/private/services/serviceUtils',
  'vb/private/services/definition/pluginServiceDefinition',
  'vb/private/services/definition/serviceDefFactory',
], (
  Constants,
  Log,
  ServicesLoader,
  ServiceUtils,
  PluginServiceDefinition,
  ServiceDefFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/definition/ProgrammaticPluginFactory');

  const TYPE = ServiceDefFactory.TYPES.PROGRAMMATIC_TYPE;

  /**
  * Service type can be defined anywhere on the chain.
  * @param {*} catalogInfo
  */
  const getServiceTypeBackend = (catalogInfo) => {
    if (catalogInfo && catalogInfo.chain) {
      // find backend in the chain that has a service type
      const backend = catalogInfo.chain.find((backendRef) => backendRef.serviceType);
      return backend;
    }
    return null;
  };

  /**
   * Class providing abstract Service Definition (there is no openapi3.json object behind it) that is used
   * for interfacing while evaluating endpoints from the backend supporting programmatic service.
   */
  class ProgrammaticPluginFactory extends ServiceDefFactory {
    constructor(services, options) {
      super(services);
      const {
        relativePath,
        isUnrestrictedRelative,
        protocolRegistry,
      } = options;

      this._protocolRegistry = protocolRegistry;
      this._isUnrestrictedRelative = !!isUnrestrictedRelative;
      // we need this to load local plugins
      this._relativePath = relativePath || '';

      this._catalogNames = { backends: [] };

      // serviceType -> plugin
      this._plugins = {};
    }

    /**
     * Checks if the service Id is of the "@base:businessObjects#crm/orders_getall"
     * or "boss#crm/orders_getall" format
     * @returns {boolean}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    supports(endpointReference) {
      if (endpointReference.isProgrammatic) {
        // VBS-25282: endpoint references with serviceType are of the "seviceType#moduleName/operationId" format
        if (endpointReference.serviceType) {
          const endpointNamespace = endpointReference.namespace;
          if (endpointNamespace === this.services.namespace
            || (!endpointNamespace && this.services.namespace === Constants.ExtensionNamespaces.BASE)) {
            return true;
          }
          return false;
        }
        // otherwise this is @ns:backend/opId endpoint
        return true;
      }
      return false;
    }

    /**
     * @override
     */
    findDeclaration(endpointReference) {
      // check if we can find the backend for this endpoint reference
      const backendName = this.getBackendName(endpointReference);
      if (!this._catalogNames.backends[backendName]) {
        return null;
      }

      // Since we do not have a list of possible endpoints we accept all that start with @
      // We do not need to do any caching because declaration object is only used to load
      // the service definition and then thrown away. It does not keep reference to any
      // heavy computed data.
      return {
        endpointReference,
        type: TYPE,
        backendName,
      };
    }

    /**
     * We do not know all operations we support.
     *
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    getAllServiceIds() {
      return [];
    }

    /**
     * Creates new PluginServiceDefinition for the given endpoint reference.
     * It finds backend the endpoint references, and based on the backends service type
     * loads corresponding plugin.
     *
     * @override
     */
    loadDefinition(endpointReference, declaration, serverVariables) {
      return Promise.resolve()
        .then(() => declaration.backend || this.getBackendName(endpointReference, true))
        .then((backendName) => {
          // (1) fully resolve the server for this service
          // endpointReference namespace and service namespace are the same
          const namespace = this.services.namespace;
          const url = `${Constants.VbProtocols.CATALOG}://backends/${namespace}:${backendName}/`;
          // During URL resolution this method will trigger url "opened" signal, which will update URLMapper.
          return ServicesLoader
            .getCatalogExtensions(
              this._protocolRegistry,
              url,
              backendName,
              namespace,
              serverVariables,
              undefined, // declaredHeaders
            );
        })
        .then((catalogInfo) => {
          // (2) get server's serviceType
          if (catalogInfo) {
            const serviceTypeBackend = getServiceTypeBackend(catalogInfo);
            if (!serviceTypeBackend) {
              logger.error(`${endpointReference} does not have serviceType`);
            } else {
              const catalogRegistry = this._protocolRegistry.catalogRegistry;
              // TODO: iterate over catalogInfo chain
              // eslint-disable-next-line max-len
              return Promise.all([serviceTypeBackend, catalogInfo, catalogRegistry.getCatalogObjects(serviceTypeBackend.namespace, 'serviceTypes')]);
            }
          }
          return [];
        })
        // (3) find plugin for the serviceType in the context of the service
        .then(([serviceTypeBackend, catalogInfo, catalogServiceTypes]) => Promise.all([
          serviceTypeBackend,
          catalogInfo,
          this.getServiceTypePlugin(serviceTypeBackend.serviceType, catalogServiceTypes)]))
        .then(([serviceTypeBackend, catalogInfo, plugin]) => plugin
          // (4) create ServiceDefinition using the plugin
          // eslint-disable-next-line max-len
          && this.createServiceDefinition(plugin, serviceTypeBackend, catalogInfo))
        .catch((e) => {
          logger.error('service load error: ', e);
          // throw e;
          return null; // allow the rest of the loads to pass
        });
    }

    /**
     * Gets instance of the plugin for the service type.
     * When the method is called externally catalog object is not provided.
     * @param {string} serviceType
     * @param {*} [catalog]
     * @returns
     */
    getServiceTypePlugin(serviceType, catalog) {
      if (!this._plugins[serviceType]) {
        const catalogRegistry = this._protocolRegistry.catalogRegistry;

        this._plugins[serviceType] = Promise.resolve()
          // this can be called directly by Services so we need to prime our state
          .then(() => this.updateServiceDeclarations())
          .then(() => catalog || catalogRegistry.getCatalogObjects(this.services.namespace, 'serviceTypes'))
          .then((catalogServiceTypes) => {
            // Use globally defined endpoint resolver. Do not let extensions override the implementation.
            const globalPluginDef = ServiceUtils.getServiceTypeEndpointResolverDef(serviceType);
            if (globalPluginDef) {
              return this.createPlugin(globalPluginDef, true);
            }
            const pluginDef = catalogServiceTypes.serviceTypes && catalogServiceTypes.serviceTypes[serviceType];
            if (!pluginDef || !pluginDef.path) {
              throw new Error(`serviceType "${serviceType}" does not have a plugin to resolve its endpoints`);
            }
            // TODO: we may later support providing constructor parameters
            return this.createPlugin(pluginDef);
          });
      }
      return this._plugins[serviceType];
    }

    createPlugin(pluginDef, isGlobalPlugin) {
      return Promise.resolve()
        .then(() => {
          // TODO: this will not work for local paths from other extensions
          // --------
          const fileName = isGlobalPlugin ? pluginDef.path : this.services.getDefinitionPath(pluginDef.path);
          // --------
          return ServiceUtils.loadPluginModule(fileName);
        })
        .then((PluginModule) => {
          const instance = (typeof PluginModule === 'function') ? new PluginModule() : PluginModule;
          return instance;
        });
    }

    createServiceDefinition(plugin, serviceTypeBackend, catalogInfo) {
      // used by Services, and merges with "backends" extensions in the Endpoint
      // services.extensions only contains headers currently, so we can use in Request construction directly
      const requestInit = (catalogInfo.services && catalogInfo.services.extensions);

      return new PluginServiceDefinition(
        serviceTypeBackend, // what the plugin is for
        plugin, // plugin instance
        requestInit,
        catalogInfo, this._protocolRegistry, // context
      );
    }

    /**
     * Extracts name of the backend from the endpoint reference, or search for the backend in
     * the catalog that matches endpoint reference serviceType.
     *
     * @param {EndpointReference} endpointReference
     * @param {boolean} [validate=false] when set the method will throw an error if the backend does not exists
     * @returns {string} name of the backend
     * @throws {Error} if backend can not be found and the validate parameter is set to true
     */
    getBackendName(endpointReference, validate = false) {
      // @<extensionId:backendName>#<module>/<endpointId>, or <extensionId:serviceType>#<module>/<endpointId>
      //
      // Possible serviceId formats:
      // BOSS:
      //   boss#crm/orders_getall - 'boss' is service type
      //   @base:businessObjects#crm/orders$byStatus_getall - 'businessObjects' is backend name
      // RAMP/BC
      //   @base:fa#crmRestApi/getall_accounts - 'fa' is backend name
      //   @base:crmRest/getall_accounts - 'crmRest' is backend name
      //
      // where 'base' is extensionId.

      let epBackendName = endpointReference.backendName;

      const serviceType = endpointReference.serviceType;
      if (!epBackendName && serviceType) {
        Object.keys(this._catalogNames.backends).forEach((backendName) => {
          const backendDef = this._catalogNames.backends[backendName];
          if (backendDef.serviceType === serviceType) {
            epBackendName = backendName;
          }
        });

        if (validate && !epBackendName) {
          throw new Error(
            `The backend with "${serviceType}" service type was not found in "${this.services.namespace}"`,
          );
        }
      } else if (!this._catalogNames.backends[epBackendName]) {
        if (validate) {
          throw new Error(
            `The backend "${epBackendName}" for "${endpointReference}" was not found in "${this.services.namespace}"`,
          );
        }
        epBackendName = null;
      }

      return epBackendName;
    }

    /**
     * @returns {Promise<void>}
     * @protected
     * @override
     */
    async updateServiceDeclarations() {
      if (!this._initializeBackendsPromise) {
        // We need to make sure that we register catalogs from the delegates.
        // TODO:  fix this as it is a side effect of searching for a service.
        this._initializeBackendsPromise = Promise.resolve()
          .then(() => {
            ServiceUtils.registerAllCatalogNames(this.services, this._protocolRegistry);
            return this._protocolRegistry.catalogRegistry.getCatalogObjects(this.services.namespace);
          })
          .then((names) => {
            this._catalogNames = (names && names.backends) ? names : { backends: [] };
          });
      }
      return this._initializeBackendsPromise;
    }
  }

  ProgrammaticPluginFactory.TYPE = TYPE;

  return ProgrammaticPluginFactory;
});

