'use strict';

define('vb/private/services/definition/serviceDefFactory',[
  'vb/private/services/endpointReferenceFactory',
], (
  EndpointReferenceFactory,
) => {
  /**
   *
   * @param {string} api API signature that should be implemented
   * @returns {Error}
   */
  const abstractApiError = (api) => new Error(`Subclass must implement ${api}`);

  /**
   * A base class for ServiceDefinition factories. Classes marked with * are abstract.
   *
   *
   *                                                     ServiceDefFactory*
   *                                                              |
   *                           +-------------------------------------------------------------------------+
   *                           |                                                                         |
   *                 OpenApiServiceDefFactory*                                                ProgrammaticPluginFactory
   *                           |
   *             +-------------+-------------+-------------------------+--------------------+
   *             |                           |                         |                    |
   *       ServiceMapFactory       ServiceProviderFactory    CatalogServiceFactory   [FallbackFactory]
   *             |
   *  ExtensionServiceMapFactory
   *
   *
   */
  class ServiceDefFactory {
    /**
     * Implementations of this class are responsible for resolving endpoints and loading
     * service defintions from various sources related to the Services instance.
     *
     * @param {Services} services
     * @abstract
     */
    constructor(services) {
      this._services = services;
    }

    /**
     * Services this factory is used in.
     * @type {Services}
     */
    get services() {
      return this._services;
    }

    /**
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    supports(endpointReference) {
      return false;
    }

    /**
     * Gets a service declaration object from the factory which can be later used to load a ServiceDefinition
     * that can resolve (and fetch) the endpoint reference.
     *
     * @param {EndpointReference} endpointReference
     * @returns {Promise<Object>}
     * @see {ServiceDefFactory.loadDefinition}
     */
    getDeclaration(endpointReference) {
      return this.updateServiceDeclarations().then(() => this.findDeclaration(endpointReference));
    }

    /**
     * Gets all ServiceDefinition objects this factory can create.
     *
     * @returns {Promise<ServiceDefinition[]>}
     */
    loadAllDefinitions() {
      return this.updateServiceDeclarations().then(() => {
        const serviceIds = this.getAllServiceIds();
        return Promise.all(serviceIds.map((serviceId) => {
          const endpointRef = EndpointReferenceFactory.getServiceOnlyReference(serviceId);
          const decl = this.findDeclaration(endpointRef);
          return this.loadDefinition(endpointRef, decl);
        }));
      });
    }

    /**
     * Loads service definition if possible, otherwise returns Promise.reject. It should not throw.
     *
     * @param {EndpointReference} endpointReference
     * @param {Object} declaration Service declaration
     * @param {Object} [serverVariables]
     * @returns {Promise<ServiceDefinition>}
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    loadDefinition(endpointReference, declaration, serverVariables) {
      return Promise.reject(abstractApiError('loadDefinition(endpointReference, serviceDeclaration, serverVariables)'));
    }

    /**
     * Gets a service declaration for the service ID of the given endpoint reference.
     * Service declaration is an object that holds all information this factory needs to load its definition
     * in the context of the Services.
     *
     * @returns {Promise<Object>}
     * @protected
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    findDeclaration(endpointReference) {
      return Promise.reject(abstractApiError('findDefinition(endpointReference)'));
    }

    /**
     * Gets list of service IDs this factory can load Service Definitions for.
     *
     * @returns {string[]}
     * @protected
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this
    getAllServiceIds() {
      throw abstractApiError('getAllServiceIds()');
    }

    /**
     * @returns {Promise<void>}
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    updateServiceDeclarations() {
      return Promise.resolve();
    }
  }

  ServiceDefFactory.TYPES = {
    CATALOG_TYPE: 'catalogServices',
    PROGRAMMATIC_TYPE: 'programmaticPlugin',
    SERVICE_MAP_TYPE: 'serviceMap',
    SERVICE_PROVIDER_TYPE: 'serviceProvider',
  };

  return ServiceDefFactory;
});

