'use strict';

define('vb/private/services/operationRefEndpointProvider',[
  'vb/private/log',
  'vb/private/configLoader',
  'vb/private/services/operationRefEndpoint',
  'vb/private/services/serviceResolverFactory',
  'vb/private/services/servicesManager',
  'vb/private/services/services',
  'vb/private/constants',
], (Log, ConfigLoader, OperationRefEndpoint, ServiceResolverFactory, ServicesManager, Services, Constants) => {
  //
  const logger = Log.getLogger('/vb/private/services/operationRefEndpointProvider');

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions:
  // string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  // We do not want to escape ".", "*", "{" and "}" as we need them
  const REG_EXP_ESCAPE = /[+?^$()|[\]\\]/g;
  const escapeRegExp = (string) => string.replace(REG_EXP_ESCAPE, '\\$&');

  // if it has "{}" then replace the param name within it with ".*" expression and try to match the RegExp
  const regexTemplates = /{(.*?)}/gi;

  class OperationRefEndpointProvider {
    // typical mapping object
    // {
    //   "urlToFetch": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api",
    //   "id": "incidents",
    //   "nameForProxy": "incidents",
    //   "headers": {
    //   "vb-allow-anonymous-access": true
    // },
    //   "baseUrl": "",
    //   "serviceName": "incidents"
    // }"

    /**
     * This is called by AbstractRestHelper._getEndpoint()
     *
     * @param {EndpointReference} endpointReference
     * @param {Container|ServiceContext} [serviceContext] - typically the container which defines the scope in which
     *                                                      the endpoint is being invoked.
     * @param [serverVariables]
     * @returns {Promise<OperationRefEndpoint>} Initialized instance of OperationRefEndpoint
     */
    static async getEndpoint(endpointReference, serviceContext, serverVariables) {
      logger.info('Getting OperationRef endpoint for reference \'', endpointReference, '\' starting from \'',
        serviceContext && serviceContext.extensionId, '\' namespace');

      let service = null;
      try {
        let def;

        // if app provided metadata that matched fetched URL use the result of that match
        let serviceName = await OperationRefEndpointProvider.getMDMappedServiceName(endpointReference, serviceContext);
        if (serviceName) {
          endpointReference.adjustEndpoint({ serviceName });
          def = await ServicesManager.FindDefinition(endpointReference, serverVariables, false);
        }

        // if operationRef has absolute URL of the openApi doc, use it to locate/load the service definition
        if (!def && endpointReference.serviceUrl) {
          const urlToFind = ConfigLoader.urlMapper.constructor.normalizeUrl(endpointReference.serviceUrl);
          def = await ServicesManager.FindDefinitionByUrl(urlToFind, serviceContext, serverVariables);
        }

        // if we could not load/find service by its location, or by mapping in the service metadata
        // try reversed URL mapping
        if (!def) {
          // fall back to the old behavior
          serviceName = await OperationRefEndpointProvider.getMapping(endpointReference)
            .then((mapping) => mapping && mapping.serviceName);

          if (serviceName) {
            endpointReference.adjustEndpoint({ serviceName });
            // Backward compatibility: disable service access check as we may be mapping to
            //  a wrong service which has not been exposed to extensions.
            //
            // For example: when an LOV maps to any of the services from a RAMP backend
            //  the service will have RAMP transform configured. That is good enough of the
            //  URL mapping as we will end up running correct transform. RAMP transforms do not
            //  need information about endpoint metadata, so it is OK not to have it resolved.
            def = await ServicesManager.FindDefinition(endpointReference, serverVariables, false);
          }
        }
        service = def && def.service;
      } catch (err) {
        logger.warn('Error while loading service of the endpoint: ', endpointReference, err);
      }

      const endpoint = new OperationRefEndpoint(endpointReference, serviceContext);
      return endpoint.init(service);
    }

    /**
     *
     * @param {EndpointReference} endpointReference
     * @returns {Promise<string>}
     * @private
     */
    static async getMDMappedServiceName(endpointReference, serviceContext) {
      const url = endpointReference.url;
      if (url) {
        const refNamespace = ServiceResolverFactory.getNamespace(serviceContext);
        const matched = await OperationRefEndpointProvider.findMatchInMetadata(url, refNamespace);

        if (matched) {
          logger.info('\'', matched.serviceName, '\' service found in \'',
            refNamespace, '\' metadata matching: ', endpointReference);
          return matched.serviceName;
        }
      }
      return undefined;
    }

    /**
     * Finds a "url mapping hint" object defined in the catalog metadata that is matching given URL.
     *
     * @param {string} url URL to match
     * @param {string} refNamespace Namespace context in which the resolution should be done
     * @returns {Promise<object>}
     */
    static async findMatchInMetadata(url, refNamespace) {
      const metadata = await ConfigLoader.catalogRegistry.getAllMetadata(refNamespace);

      // search one namespace at a time
      const matched = Object.keys(metadata).reduce(async (currMatchPromise, ns) => {
        const currentMatch = await currMatchPromise;
        return currentMatch || OperationRefEndpointProvider.findMatch(url, ns, metadata[ns].urlMappingHints);
      },
      Promise.resolve(null));

      return matched;
    }

    /**
     * Finds a  object defined in the list of metadata objects that is matching given URL.
     * If necessary it resolves the vb-catalog:// type of URL in the given metadata before
     * matching it with the URL.
     *
     * @param {string} url URL to match
     * @param {string} namespace Namespace context in which the resolution should be done
     * @param {object[]} [metadata] Metadata objects to match. Their 'url' property is used for matching
     * @returns {Promise<object>} Promise of matched metadata object or null value
     */
    static async findMatch(url, namespace, metadata = []) {
      const needsResolution = metadata.some((map) => !map.resolvedUrl);
      // we want to avoid repeated resolution of vb-catalog:// URLs
      // we do it only once and then store the resolved value
      if (needsResolution) {
        const handler = ConfigLoader.protocolRegistry.getHandler(Constants.VbProtocols.CATALOG);
        const resolved = await handler.resolveUrls(metadata
          .map((mapping) => mapping && (mapping.resolvedUrl || mapping.url)), namespace);
        resolved.forEach((resolvedUrl, index) => {
          // eslint-disable-next-line no-param-reassign
          metadata[index].resolvedUrl = resolvedUrl;
        });
      }
      return metadata.find((hint) => (hint.resolvedUrl
        && OperationRefEndpointProvider.matches(url, hint.resolvedUrl)
        && hint.serviceName));
    }

    /**
     * Checks if the resolved URL matches given URL. THe two URLs match if the beginning of the URL
     * matches the resolved URL. Resolved URL may contain arbitrary template fragments, eg. "{version}"
     * in "vb-catalog://backends/extA:backendFoo/{version}/data".
     *
     * @param {string} url
     * @param {string} resolvedUrl
     * @returns {boolean}
     */
    static matches(url, resolvedUrl) {
      if (url.startsWith(resolvedUrl)) {
        return true;
      }

      // if it has "{}" then replace the param name within it with ".*" expression and try to match the RegExp
      const regExpUrl = escapeRegExp(resolvedUrl.replaceAll(regexTemplates, '.*'));
      return (new RegExp(regExpUrl)).test(url);
    }

    /**
     * try to map the url to a catalog service, or already-opened openapi3/swagger
     * @param {EndpointReference} endpointReference
     * @returns {Promise<*>}
     */
    static getMapping(endpointReference) {
      return ConfigLoader.urlMapper.getUrlMapping(endpointReference.url);
    }

    /**
     * creates a Services model, for the Endpoint that represents the raw, unmapped URL.
     * @param name
     * @param operationRefUrl
     * @returns {Services}
     */
    static createServicesModel(name, operationRefUrl) {
      return new Services({
        relativePath: '',
        serviceFileMap: { [name]: operationRefUrl },
        expressionContext: {},
        protocolRegistry: ConfigLoader.protocolRegistry,
      });
    }
  }

  return OperationRefEndpointProvider;
});

