'use strict';

define('vb/private/vx/noFetchExtensionAdapter',[
  'vb/private/constants',
], (
  Constants,
) => {
  class NoFetchExtensionAdapter {
    /**
     * @param {Object} vbInitConfig
     */
    constructor(vbInitConfig = globalThis.vbInitConfig || {}) {
      /** @type {Object} */
      this.vbInitConfig = vbInitConfig;
    }

    convertDigestPromise() {
      return this.vbInitConfig.DIGEST_PROMISE
        .then((response) => {
          if (!response.ok) {
            throw new Error('Response not OK when fetching partial extension manifest');
          }

          return response.json();
        })
        .then((result) => [
          Constants.Digest.RUNTIME,
          Constants.Digest.REQUIREJS_INFO,
          Constants.Digest.APP_UI_INFO,
        ].map((type) => Promise.resolve(result.digest[type])));
    }

    /**
     * @param {Object}  files
     */
    convertVbFiles(vbFiles) {
      const files = [];
      this.buildPathsForRuntime(files, vbFiles);
      return files;
    }

    /**
     * Builds paths from a vb-bundle format
     *
     * @param {[String]}  files
     * @param {Object}    folders
     * @param {String}    currentPath
     */
    buildPathsForRuntime(files, folders, currentPath = '') {
      if (!folders || typeof folders !== 'object') {
        return;
      }

      Object.keys(folders).forEach((name) => {
        // If "files" was a folder name, it's type would not be an array
        if (name === 'files' && Array.isArray(folders.files)) {
          folders.files.forEach((file) => {
            files.push(`${currentPath}${file}`);
          });
        } else {
          this.buildPathsForRuntime(files, folders[name], `${currentPath}${name}/`);
        }
      });
    }
  }

  return NoFetchExtensionAdapter;
});

