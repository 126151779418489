'use strict';

define('vb/private/services/catalogHandler',['vb/private/utils',
  'vb/private/constants',
  'vb/private/services/serviceConstants',
  'vb/private/log',
  'vb/private/pathHandler',
  'vbc/private/constants',
  'vb/private/services/endpoint',
  'vb/private/services/catalogRegistry',
  'vb/private/services/swaggerUtils',
  'vb/private/services/trapData',
  'vb/private/services/readers/openApiObjectFactory',
  'vb/private/services/readers/openApiUtils',
  'urijs/URI'],
(Utils, Constants, ServiceConstants, Log, PathHandler, CommonConstants, Endpoint, CatalogRegistry,
  SwaggerUtils,
  TrapData,
  OpenApiObjectFactory, OpenApiUtils, URI) => {
  const logger = Log.getLogger('/vb/private/services/catalogHandler');

  /**
   * ideally, we can keep the dependency between ProtocolRegistry and CatalogHandler one-way, and not have
   * this depend on ProtocolRegistry use.
   *
   * But with the introduction of Endpoint creation, we need one; for now, do NOT support metadata
   * "paths" definitions that reference registered protocols.
   * The should be path fragments anyway; it doesn't make sense for the "paths" object to need to reference the catalog.
   * this represents a 'minimal' protocolRegistry duck-type for getting the endpoint info
   * for the "services" fragment.
   */
  const stubProtocolRegistry = {
    // return the same default object that the protocolRegistry would return, if we were using the real one
    getResolvedInfoOrDefault: (url, namespace = Constants.ExtensionNamespaces.BASE) => Promise.resolve({
      default: true,
      url,
      namespace,
      // version is used for proxy/tokeRelay URL resolution; the result of this StubRegistry are not used for that
      version: 'N/A',
      extensions: {
        [ServiceConstants.ExtensionTypes.SERVICES]: {},
        [ServiceConstants.ExtensionTypes.BACKENDS]: {},
      },
      chain: [],
    }),
  };

  /**
   * the CatalogHandler uses the catalog.json for adding extensions to a 'static' service definition (swagger, openapi),
   * and also defining server paths using variable templates.
   *
   * For OpenAPI 3 support, we have one service definition for both design-time and runtime, and the server
   * (ideally) does not inject extensions into service defs that are based on the environment.
   * The catalog.json allows app developers to add application-specific extensions to the service defs,
   * and also specify values for server path template substitutions.
   *
   * The catalog cannot override anything in the service definition; it is only meant to be additive.
   *
   * there are two sections:
   *   "services":
   *     applies to the entire service; defines security for both service metadata and data endpoints
   *
   *   "backends":
   *      defines additional headers/extension info for data endpoints.
   *      backends are meant to be re-usable by different services.
   */

  // new syntax for services in 19.3.1
  /*
  "services": {
      "service1": {
          "openapi": "3.0",
          "info": {
               "title": "My Cool Service"
          },
          "servers": [
              {
                  "url": "vb-catalog://backends/backend1/"
              },
              {
                  "url": "https://test.notreal/other/",
                  "x-vb": {
                      "headers": {
                          "header4": "header4Value"
                      },
                      "authentication": {
                      },
                      "profiles": [ "test" ]
                  }
              }
          ],
          "paths": {
              "/metadata/openapi3.json": {
                  "get": {
                      "x-vb": {
                          "headers": {
                              "header3": "header3Value"
                          }
                      }
                  }
              }
          }
      }
  }
  */

  class CatalogHandler {
    /**
     * @param {Object|null} config
     *  - 'catalog': optional, path to catalog.json (uses requireJS to load). defaults to '../../services/catalog.json'
     *    currently, this is unused (I think), since the server would not be able to handle alternate locations
     *    for the catalog (the server does some injection if it finds one in the default location).
     *    If you did have a need to define one in an alternate location, it would be configured in the app-flow.json as:
     *      "configuration": {
     *         "catalog": {
     *           "path": "../../myfolder/catalog.json"
     *         }
     *      }
     *
     *  - 'services.catalog.backends/services: optional objects that contain the values for server path tokens
     *
     * @param {string} profile current profile, defaults to empty string (means 'use the first server').
     *    note that this setting is in the config.profile, but we centralize where that's determined, since
     *    other parts of the services layer also require it.
     *
     * there is a third 'protocol' parameter, but we don't use it, since we only handle one protocol.
     *
     * @param {Object} tenantCatalog Not used. {@see vbExtensionHandler}
     * @param {CatalogRegistry} catalogRegistry
     */
    constructor(config, profile, tenantCatalog, catalogRegistry) {
      this.config = config || {};

      if (this.config.catalog && this.config.catalog.path) {
        this.catalogPath = new PathHandler(this.config.catalog.path,
          this.config.relativePath || '', { allowParent: true }).getResolvedPath();
      }
      this.catalogPath = this.catalogPath || CatalogHandler.getDefaultPath();

      this.profile = profile || '';

      this.registry = catalogRegistry;

      this._pendingCatalogs = {};
      // @todo: eventually, we can register the tenant catalog here also, when it exists
      this.registerCatalog(this.catalogPath);
    }

    /**
     * always use this handler
     * @returns true if this handler should be installed
     * @see ProtocolRegistry#init
     */
    static shouldInstall(/* config */) {
      // return !!(config && config.catalog);
      return true;
    }

    /**
     * for now, DT assumes a hard-coded path; if the file doesn't exist, this 'plugin' will fail,
     * but protocol resolution will be the default behavior - none.
     * @returns {string}
     */
    /* eslint-disable no-underscore-dangle */
    static getDefaultPath() {
      // using a static property so we can clear it in unit tests
      if (!CatalogHandler._defaultCatalogPath) {
        CatalogHandler._defaultCatalogPath = Constants.DefaultPaths.CATALOG_JSON;
      }
      return CatalogHandler._defaultCatalogPath;
    }

    /**
     * remove the load Promise, so it can be refreshed.
     */
    dispose() {
      this.loadPromise = null;
      this.registry.dispose();
    }

    /**
     * Register location of a catalog for the given namespace.
     * The registered catalog is loaded by the CatalogRegistry when CatalogHandler needs
     * it to resolve URL referencing objects within it.
     *
     * @param {string} catalogPath location of the catalog
     * @param {string} [namespace='base'] ID of the extension catalog belongs to
     * @param {boolean} [immediately=true] If true make CatalogRegistry load the catalog immediately
     */
    registerCatalog(catalogPath, namespace = CatalogRegistry.ROOT_CATALOG, immediately = true) {
      if (!immediately) {
        this._pendingCatalogs[namespace] = catalogPath;
      } else {
        this.registry.register(namespace, catalogPath);
      }
    }

    /**
     * Makes sure that the catalog for the given extension is loaded by the CatalogRegistry.
     *
     * @param {string} namespace
     */
    async ensureCatalog(namespace) {
      const pendingCatalog = this._pendingCatalogs[namespace];
      if (pendingCatalog) {
        delete this._pendingCatalogs[namespace];
        this.registerCatalog(pendingCatalog, namespace);
        await this.registry.loadPending();
      }
    }

    /**
     * @param {string} url
     * @param {string} namespace
     * @param {object} parsedInfo - from URIjs
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @param {object} [protocolRegistry]
     * @param {boolean} [fullyResolve] set to true to force resolution for URLs not previously resolvable.
     * @return {Promise}
     */
    getResolvedObject(url, namespace, parsedInfo, serverVariables, protocolRegistry, fullyResolve) {
      return Promise.resolve()
        .then(() => {
          // vb-catalog://<services/backends>/<[namespace:]id>/rest/of/path....

          const catalogType = parsedInfo.hostname;
          const pathParts = (parsedInfo.path || '').split(Constants.PATH_SEPARATOR);
          const id = pathParts[1] || '';

          // "containerNamespace" is the id of the extension/container/"scope" in which this endpoint is being used -
          // for example, it will be 'extA' if this method is invoked because of an action chain in extension 'extA'.
          // Never undefined, defaults to 'base'.
          const containerNamespace = namespace || Constants.ExtensionNamespaces.BASE;

          // Check if the objectId (backendId or serviceId) encodes a namespace.
          const { prefix: encodedNamespace, main: encodedId } = Utils.parseQualifiedIdentifier(id);

          // If there is an encodedNamespace and if it's different from the containerNamespace, we need to
          // check if encodedNamespace is visible to the containerNamespace - it is visible it encodedNamespace is a
          // dependency of containerNamespace or if it is base.
          if (encodedNamespace
            && containerNamespace !== encodedNamespace
            && !this.registry.isVisibleExtension(containerNamespace, encodedNamespace)) {
            return Promise.reject(new Error(`The extension '${encodedNamespace}' is not visible from '${namespace}'`));
          }

          // Use encodedNamespace if available or containerNamespace. Also, the '!encodedNamespace' argument means that,
          // if the objectId does not define a specific namespace, we need to search the dependencies and base if that
          // objectId is not defined on the catalog of containerNamespace.
          return this._resolve(
            encodedId,
            encodedNamespace || containerNamespace,
            parsedInfo,
            catalogType,
            !encodedNamespace,
            serverVariables,
            protocolRegistry,
            fullyResolve,
          )
            .then((result) => {
              // If the specified containerNamespace is not the namespace of the result, we need to check if the
              // extensions are V1 or if the result's object is marked as accessible to extensions.
              if (result
                && containerNamespace !== result.namespace
                && !result.extensionAccess) {
                // eslint-disable-next-line max-len
                throw new Error(`The catalog object '${encodedId}' from '${encodedNamespace}' is not exported so it cannot be used by '${containerNamespace}'`);
              }
              return result;
            });
        });
    }

    /**
     * Resolve list of vb-catalog:// URLs to the real URLs. If a URL can not be fully resolved,
     * it's original value or the last value in the resolution chain is returned.
     *
     * If a URL is not fully qualified, eg vb-catalog://backends/crmRest/, given
     * namespace parameter is used as the context for the name resolution.
     *
     * @param {string[]} urls List of vb-catalog:// URLs to resolve
     * @param {string} [namespace] Context in which to resolve unqualified references
     * @returns {Promise<string[]>} Promise of the resolved urls
     */
    async resolveUrls(urls = [], namespace) {
      await this.registry.loadPending();
      return Promise.all(urls.map((url) => this._resolveUrl(url, namespace)));
    }

    /**
     *
     * Note: This method is only called when the CatalogRegistry is primed and we can access it in a synchronous way.
     *
     * @param {string} url
     * @param {string} namespace
     * @returns {string}
     */
    async _resolveUrl(url, namespace) {
      const parsedInfo = URI.parse(url);
      if (parsedInfo.protocol !== CommonConstants.VbProtocols.CATALOG) {
        // nothing to resolve
        return url;
      }

      const catalogType = parsedInfo.hostname;
      const pathParts = (parsedInfo.path || '').split(Constants.PATH_SEPARATOR);
      const id = pathParts[1] || '';

      // "containerNamespace" is the id of the extension/container/"scope" in which this endpoint is being used -
      // for example, it will be 'extA' if this method is invoked because of an action chain in extension 'extA'.
      // Never undefined, defaults to 'base'.
      const containerNamespace = namespace || Constants.ExtensionNamespaces.BASE;

      // Check if the objectId (backendId or serviceId) encodes a namespace.
      const { prefix: encodedNamespace, main: encodedId } = Utils.parseQualifiedIdentifier(id);

      // If there is an encodedNamespace and if it's different from the containerNamespace, we need to
      // check if encodedNamespace is visible to the containerNamespace - it is visible if encodedNamespace is a
      // dependency of containerNamespace or if it is base.
      if (encodedNamespace
        && containerNamespace !== encodedNamespace
        && !this.registry.isVisibleExtension(containerNamespace, encodedNamespace)) {
        logger.warn('Can not resolve', url, '. The extension', encodedNamespace, 'is not visible from', namespace);
        return url;
      }
      const found = await this._findObjectInNamespaces(encodedId, namespace, catalogType, !encodedNamespace);
      if (!found) {
        logger.warn('Can not resolve', url, '. Reference does not exists.');
        return url;
      }
      const server = this._resolveServerSync(catalogType, namespace, id, found.catalogObject);
      if (!server) {
        return url;
      }

      // followed one level of protocol indirection, but do not do template substitution
      const referencedUrl = server.getUrl(this.config, parsedInfo, catalogType, encodedId,
        {} /* serverVariables */, {} /* resolvedVariables */);

      return this._resolveUrl(referencedUrl, encodedNamespace);
    }

    /**
     * follows vb-catalog references until the final backend,
     * or rather, no more vb-catalog references, which is defined to necessarily be a backend (currently?).
     *
     * @param id
     * @param namespace
     * @param originalUrlInfo from URI.parse()
     * @param catalogObjectType "backends" or "services"
     * @param searchDelegates true to search on delegates (extensions and base)
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @param {object} [protocolRegistry]
     * @param {boolean} [fullyResolve] set to true to force resolution for URLs not previously resolvable.
     * @returns {Promise<Object>}
     * @private
     */
    _resolve(id, namespace, originalUrlInfo, catalogObjectType, searchDelegates, serverVariables,
      protocolRegistry, fullyResolve) {
      // load missing catalogs only if we have fullyResolve=true
      return (fullyResolve ? this.ensureCatalog(namespace) : Promise.resolve())
        .then(() => this.registry.loadPendingForType(catalogObjectType))
        .then(() => this._findObjectInNamespaces(id, namespace, catalogObjectType, fullyResolve, searchDelegates))
        .then((found) => {
          if (found) {
            // eslint-disable-next-line no-param-reassign
            found.catalogObject = CatalogHandler._filterCatalogObject(found.catalogObject, catalogObjectType);
            return Promise.all([found,
              CatalogHandler.createOpenApiMetadata(id, found.catalogObject, this.catalogPath, protocolRegistry)]);
          }

          if (this._pendingCatalogs[namespace]) {
            throw new Error(`The catalog object '${catalogObjectType}/${id}' can not be resolved.`
              + ` Catalog for '${namespace}' is not loaded yet.`);
          } else {
            throw new Error(`The catalog object '${catalogObjectType}/${id}' was not found in '${namespace}'`);
          }
        })
        .then(([found, metadata]) => {
          if (found.catalogObject) {
            if (metadata) {
              found.catalogObject.metadata = metadata; // eslint-disable-line no-param-reassign
            }
            return Promise.all([found,
              this._resolveServer(catalogObjectType, namespace, id, found.catalogObject, fullyResolve)]);
          }
          return [];
        })
        .then(([found, server]) => {
          if (found.catalogObject) {
            // capture resolved variables too
            const resolvedVariables = {};
            // followed one level of protocol indirection, but do not do template substitution
            const referencedUrl = server.getUrl(this.config, originalUrlInfo, catalogObjectType, id,
              serverVariables, resolvedVariables);

            // 'server' (in 'resolved') will contain the chosen server (from 'servers')
            // the url in this object has tokens replaced

            const resolved = this._createResolvedInfo(
              found,
              server,
              referencedUrl,
              catalogObjectType,
              id,
            );
            resolved.variables = resolvedVariables || {};

            logger.info('resolving', `${catalogObjectType}/${namespace}:${id}`, '=>', resolved.url);
            // we still need to merge its extensions (x-vb) with any referenced catalog object.
            return resolved;
          }
          logger.error('No catalog object matching type and id', catalogObjectType, id, 'was found');
          return null;
        })
        .catch((error) => {
          // if we are intentionally omitting the catalog loading log an info message and continue
          if (this._pendingCatalogs[namespace]) {
            logger.info('Catalog for \'', namespace, '\' not yet loaded. ', error.message);
            return null;
          }
          throw error;
        });
    }

    /**
     * backends do not directly use namespaces;  the external reference (outside catalog.json) to the service does,
     * but one we are resolving "vb-catalog" urls, we just look
     * - a) first in the catalog in the namespace of the external reference
     * - b) then 'base' (if (a) wasn't already 'base').
     * - c) @todo: implement tenant catalog.
     *
     * find the object type; look first in our namespace, then 'base'
     *
     * (eventually, we will likely support 'tenant' also)
     * see: https://confluence.oraclecorp.com/confluence/display/ABCS/
     *     Services+Extension+Dependency+Scenarios#ServicesExtensionDependencyScenarios-Backends.2
     *
     *
     *
     * @param {string} id
     * @param {string} namespace
     * @param {string} type
     * @param {boolean} [searchDelegates]
     * @returns {Promise<Object|undefined>}
     * @private
     */
    async _findObjectInNamespaces(id, namespace, type, fullyResolve, searchDelegates) {
      // list of namespaces that still need to be searched
      const searchQueue = [namespace];
      // map of all encountered namespaces
      const processedNamespaces = {};
      processedNamespaces[namespace] = true;

      // Searches catalog for the given namespace and adds
      // any required namespaces to the search queue
      const search = async (extensionId) => {
        // load missing catalogs only if we have fullyResolve=true
        if (fullyResolve) {
          await this.ensureCatalog(extensionId);
        }

        const catalog = this.registry.getCatalogObjectsSync(extensionId, type);
        const catalogObject = catalog && catalog[type] && catalog[type][id];
        if (catalogObject) {
          return {
            catalogObject,
            namespace: extensionId,
            version: catalog.version,
            path: catalog.path, // needed for later resolving of relative transforms paths
          };
        }

        // If we didn't find it on the namespace, try the delegates (dependencies + base) if allowed.
        if (searchDelegates) {
          // The 'getRequiredExtensionIds' does not return 'base' as a delegate.
          // We will look for the backend on all dependencies never looking at base by setting 'skipBase' to true.
          // Then we look on base after traversing all dependencies.
          const extensionIds = this.registry.getRequiredExtensionIds(extensionId);
          extensionIds.forEach((extId) => {
            if (!processedNamespaces[extId]) {
              searchQueue.push(extId);
              processedNamespaces[extId] = true;
            }
          });
        }
        return undefined;
      };

      let extensionObject = null;

      // search all the namespaces until find the catalog object
      while (searchQueue.length && !extensionObject) {
        const extensionId = searchQueue.shift();
        // eslint-disable-next-line no-await-in-loop
        extensionObject = await search(extensionId);
      }

      return extensionObject || search(Constants.ExtensionNamespaces.BASE);
    }

    /**
     * remove any 'illegal' properties. protecting against misuse (or confusion).
     *
     * the ‘catalogObject’ is actually a sub-object from the original parsed JSON, so this is doing a shallow copy
     * to be prudent to keep the original, whole, object intact.
     *
     * For SERVICES objects, if its a NEW syntax (contains an 'openapi3' object),
     * just log a warning if this is not using the new 'openapi' "paths" syntax
     *
     * For BACKENDS,
     * We are deleting the properties to prevent them from being using in a merge.
     * “services” and “backends” are nearly identical objects. and when they are merged together,
     * the differences need to be respected.
     *
     * For OLDER-SYNTAX SERVICES objects, there is a difference in how the headers are treated –
     * service and backend headers are kept separate, and the service headers are used ONLY for fetching the metadata.
     *
     * (Note: new syntax service headers are merged with backend headers).
     *
     * Also, backends *should not* define proxyUrls/tokenRelayUrls, so delete them.
     * So, basically:
     * - no, we don’t strictly need to do the shallow copy, and
     * - no, we don’t need to delete the proxyUrls, and hopefully they are never there.
     * @param catalogObject {Object} the services or backends object found in the catalog
     * @param catalogObjectType {string} 'services' or 'backends'
     * @returns {Object}
     * @private
     */
    static _filterCatalogObject(catalogObject, catalogObjectType) {
      let obj = catalogObject;
      if (obj) {
        obj = Object.assign({}, obj);

        if (catalogObjectType === ServiceConstants.ExtensionTypes.BACKENDS) {
          delete obj.proxyUrls;
          delete obj.tokenRelayUrls;
          if (Array.isArray(obj.servers)) {
            obj.servers = obj.servers.map((server) => {
              const extensions = server[ServiceConstants.VB_EXTENSIONS];
              if (extensions && (extensions.proxyUrls || extensions.tokenRelayUrls)) {
                logger.error('service catalog backends should not contain proxyUrls or tokenRelayUrls;',
                  'removing and continuing');
                const newExtensions = Object.assign({}, extensions);
                delete newExtensions.proxyUrls;
                delete newExtensions.tokenRelayUrls;
                return Object.assign({}, server, { [ServiceConstants.VB_EXTENSIONS]: newExtensions });
              }
              return server;
            });
          }
        } else if (catalogObjectType === ServiceConstants.ExtensionTypes.SERVICES) {
          // if we have this attribute, we also mat have a "paths" object, which can be used to specify how to fetch
          // the metadata (swagger/openapi). this is a standard openapi "paths" syntax, but should only
          // have ONE path, and that should have only ONE method (typically a get).
          // that path will be appended to the "servers" object path.
          if (!catalogObject.openapi) {
            logger.warn('deprecated catalog services syntax. headers will not be merged with backends');
          }
        }
      }
      return obj;
    }

    /**
     * A "services" object can optionally use an "openapi" fragment syntax to specify the operation used to
     * get the service metadata (openapi3, swagger2).
     *
     * From that openapi3 fragment, create an Endpoint to fetch the metadata, and some additional properties,
     * to simplify the metadata load.
     *
     * This resolves with this structure (and the caller sets this as the 'metadata' property of the catalog info)
     * {
     *   path?: {string}
     *   query?: {string}
     *   method?: {string} lowercase
     *   extensions?: {Object} the "x-vb" contents
     *   openapi: {string} the version string
     * }
     * @param {string} id catalogObject ID
     * @param {object} catalogObject
     * @param {string} catalogPath
     * @param [protocolRegistry]
     * @returns {Promise<object>}
     */
    static createOpenApiMetadata(id, catalogObject, catalogPath, protocolRegistry) {
      // if we have this attribute, we also may have a "paths" object, which can be used to specify how to fetch
      // the metadata (swagger/openapi). this is a standard openapi "paths" syntax, but should only
      // have ONE path, and that should have only ONE method (typically a get).
      // that path will be appended to the "servers" object path.
      return Promise.resolve()
        .then(() => {
          if (!catalogObject) {
            throw new Error(`Catalog object "${id}" not found in catalog.json.`);
          }
          return catalogObject.openapi
            ? CatalogHandler.getEndpointConfiguration(id, catalogObject, catalogPath, protocolRegistry)
            : null;
        })
        .then((endpointConfig) => {
          if (endpointConfig) {
            const pathFrag = new URI(endpointConfig.url);
            const path = pathFrag.path();
            // now that this uses an Endpoint, the query is endcoded, so decode the 'query' to match previous behavior.
            const query = decodeURIComponent(pathFrag.query());

            const method = endpointConfig.method.toLowerCase();
            // @todo: might make sense to include this as an object in the endpoint.getConfiguration?
            const extensionsHeader = endpointConfig.headers[CommonConstants.Headers.VB_INFO_EXTENSION];

            return {
              openapi: catalogObject.openapi,
              path,
              query,
              extensions: extensionsHeader ? SwaggerUtils.parseServiceText(extensionsHeader) : {},
              method,
            };
          }

          if (catalogObject.openapi) {
            // if we didn't have enough metadata to create an endpoint,
            // at least track that it was an openapi3 fragment (the new syntax, for a "services' object)
            return {
              openapi: catalogObject.openapi,
            };
          }
          return null; // this wasn't an openapi3 fragment, the caller should ignore our results
        });
    }

    /**
     * follows the protocol indirection, does not do any token substitution
     *
     * @param serverUrl url from the current "servers" object that is being procesed for the
     *  referenced services/backends obejct
     * @param originalUrlInfo URIjs info for the result of the current expansion; this is what
     * refers to the services/backends whose "servers" url is 'serverUrl'
     *
     *
     * @returns {string}
     * @private
     */
    static _resolvedUrl(serverUrl, originalUrlInfo) {
      // create a new URL by appending the original 'path' part to the found server url
      // can't use URI().segment(), because it will encode the braces in the tokens!
      // At this point it is OK for the serverUrl to be a template like 'http://foo:{port}'.
      const serverUri = Utils.uriSafeOperation((SafeURI) => new SafeURI(serverUrl));

      // get the prefix without the query string; we need to manage those ourselves, to preserve templates (?x={foo})
      const uriPrefix = serverUri.clone().search('').toString();
      const endsWith = uriPrefix.endsWith(Constants.PATH_SEPARATOR);

      // need to remove the service/backend 'id' from the path portion
      const pathNoLeadingSlash = originalUrlInfo.path.startsWith(Constants.PATH_SEPARATOR)
        ? originalUrlInfo.path.substring(1)
        : originalUrlInfo.path;
      const pathParts = pathNoLeadingSlash.split(Constants.PATH_SEPARATOR);
      pathParts.shift();

      let pathSuffix = pathParts.join(Constants.PATH_SEPARATOR);
      if (pathSuffix.startsWith(Constants.PATH_SEPARATOR)) {
        pathSuffix = pathSuffix.substring(1);
      }

      let newUrl;
      if (pathSuffix) {
        const delim = !endsWith ? Constants.PATH_SEPARATOR : '';
        newUrl = `${uriPrefix}${delim}${pathSuffix}`;
      } else {
        // remove trailing slashes if URI added it
        newUrl = endsWith && (uriPrefix === `${serverUrl}${Constants.PATH_SEPARATOR}`)
          ? uriPrefix.substring(0, uriPrefix.length - 1) : uriPrefix;
      }
      // check if there are query parms;  not using URIjs because we are preserving template strings ("{mytemplate}");
      // template replacement is handled at the end.
      const qparams = [originalUrlInfo.query, serverUri.query()].filter((q) => !!q);
      if (qparams.length) {
        newUrl = `${newUrl || ''}?${qparams.join('&')}`;
      }

      return newUrl;
    }

    /**
     * 'merge' the extensions, where 'extension' means one of two places that an extension can be defined
     * within a single catalog object (backend, service).
     *
     * (a) When called from CatalogHandler, the 'extensions' are the two levels where extension properties can
     * be defined within one catalog object. Either top-level, or 'x-vb'.
     *
     * (b) When called from ProtocolHandler, the extensions are the service or backend objects.
     * It is assumed that we have already resolved case (a), and are dealing with a flat extension object for
     * each catalog object.
     *
     * When merging different service catalog objects, we ONLY merge 'services' to 'backends', and we DO NOT
     * merge the 'services' headers. Backend extension are not merged to services here, though the caller can
     * do so.
     *
     * We also have to account for mutually-exclusive properties; keep a list of the closest property found,
     * and delete the rest at the end.
     *
     * Since the end of 19.3.1, the separation of 'backends' and 'services' is (mostly?) legacy, for deprecated syntax.
     * We merged services into backends, but not backends into services, to keep services headers as being for metadata
     * (swagger/openapi3) fetch only.
     * Since moving to the "paths" openapi3-snippet syntax to encapsulate anything specific to metadata fetching,
     * we could just merge all backends and services into one object (making things more uniform, etc).
     * But, for now, while in 'transition', continue to keep them separate here, and let the consumer merge services
     * to backends when appropriate.
     *
     * @param extensions
     * @returns {*}
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    mergeExtensions(...extensions) {
      // merge catalog object headers (those used to get the service definition (openapi).
      // first, remove any 'undefined' properties.
      extensions.forEach((extension) => {
        Object.keys(extension).forEach((prop) => {
          if (extension[prop] === undefined) {
            delete extension[prop]; // eslint-disable-line no-param-reassign
          }
        });
      });

      const headers = extensions.reduce((accum, current) => Object.assign(accum, (current && current.headers) || {}),
        {});

      // headers from extensions are combined; otherwise the second extensions overrides the first, etc.
      const merged = Object.assign({}, ...extensions, { headers });
      return merged;
    }

    /**
     * when merging services to backends, we don't want to merge everything
     * headers for service are used for loading the service def, headers for backends are for (backend) endpoint fetch.
     * @param {{url, extensions: {services: {}, backends: {}}, metadata: Object}} referringObject
     *         the current catalog model object whose url refers to the object that needs merging.
     * @param {Object} sourceProperties source object for the merge; a 'services' or 'backends' extensions object,
     *          a selected subset of the referringObject.
     * @param {string} sourceType for now, only 'services'
     * @param {string} destType for now, only 'backends'
     * @returns {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    getPropertiesForCrossObjectMerge(referringObject, sourceProperties, sourceType, destType) {
      const newProps = Object.assign({}, sourceProperties);
      if (sourceType === ServiceConstants.ExtensionTypes.SERVICES
          && destType === ServiceConstants.ExtensionTypes.BACKENDS) {
        // if we're using the new metadata syntax, do not prevent "services" headers from merging into "backends"
        // (don't delete the headers from the source "services" object) (bufp-31121)
        const newSyntax = referringObject
          && referringObject.metadata
          && referringObject.metadata.services
          && referringObject.metadata.services.openapi;
        if (!newSyntax) {
          delete newProps.headers;
        }
      } else {
        // shouldn't happen
        logger.warn('unexpected source or destination types. source =', sourceType, 'destination =', destType);
      }
      return newProps;
    }

    /**
     * choose the proper server from the list.
     * Uses 'profile' if available, and return the first match. Otherwise, just use the first one.
     * @param servers
     * @returns {*}
     * @private
     */
    _findServer(servers = []) {
      const server = OpenApiUtils.findServerForProfile(servers, this.profile);
      return server;
    }

    /**
     * Returns the server object that should be used for the given catalog object.
     * It consults any given overerrides and applies active profile.
     *
     * @param {string} catalogObjectType 'backends' or 'services'
     * @param {string} namespace='base' extension Id or 'base'
     * @param {string} id name of the service or of the backend
     * @param {Object} catalogObject
     * @param {boolean} fullyResolve trigger loading (fetching) of  extra information if necessary
     * @returns {Promise<Object>}
     */
    _resolveServer(catalogObjectType, namespace = Constants.ExtensionNamespaces.BASE, id, catalogObject, fullyResolve) {
      let declaredServer;
      return Promise.resolve()
        .then(() => {
          declaredServer = this._findServer(catalogObject.servers);
          // let defined servers allow to be overriden by trap
          if (TrapData.getTrapData().canOverrideServer(declaredServer, catalogObject)) {
            if (fullyResolve) {
              return this.registry.getCatalogOverride(catalogObjectType, namespace, id);
            }
            return this.registry.getCatalogOverrideSync(catalogObjectType, namespace, id);
          }
          return declaredServer;
        })
        .then((server) => {
          // let TRAP provide server override, but fallback on the one declared in the catalog
          const resolvedServer = server || declaredServer;
          if (resolvedServer) {
            logger.info('using server with url', resolvedServer.getUrl(), 'for ',
              `${catalogObjectType}/${namespace}:${id}`, ' profile', this.profile);
            return resolvedServer;
          }

          if (fullyResolve) {
            if (this.profile && catalogObject.servers && catalogObject.servers.length) {
              logger.error(`${catalogObjectType}/${namespace}:${id}`,
                'has no server for the profile', this.profile);
            } else {
              logger.error(`${catalogObjectType}/${namespace}:${id}`, 'has no defined servers');
            }
          }
          return {
            getUrl: () => {
              if (fullyResolve) {
                throw new Error(`Server not configured for ${namespace}:${id}.`);
              }
              return './';
            },
          };
        });
    }

    /**
     * Returns the server object that should be used for the given catalog object.
     * It consults any given overerrides and applies active profile.
     * If server can not be resolved returns null.
     *
     * @param {string} catalogObjectType 'backends' or 'services'
     * @param {string} namespace='base' extension Id or 'base'
     * @param {string} id name of the service or of the backend
     * @param {Object} catalogObject
     * @returns {Object|null}
     */
    _resolveServerSync(catalogObjectType, namespace = Constants.ExtensionNamespaces.BASE, id, catalogObject) {
      let resolvedServer;

      const declaredServer = this._findServer(catalogObject.servers);
      if (TrapData.getTrapData().canOverrideServer(declaredServer, catalogObject)) {
        const overridenServer = this.registry.getCatalogOverrideSync(catalogObjectType, namespace, id);
        resolvedServer = overridenServer || declaredServer;
      } else {
        resolvedServer = declaredServer;
      }

      if (resolvedServer) {
        logger.info('using server with url', resolvedServer.getUrl(), 'for ',
          `${catalogObjectType}/${namespace}:${id}`, ' profile', this.profile);
        return resolvedServer;
      }

      logger.info('catalog handler could not find', `${catalogObjectType}/${namespace}:${id}`,
        'server for profile', this.profile);
      return null;
    }

    /**
     * create an object that contains information about what we have resolved for the current object.
     *
     * This is merging the headers/transforms information from the current "service" or "backend" object,
     * with ALL the "server" extension information form the SAME object.
     * The server is chosen from the array using a profile, or [0].
     *
     * {
     *   url: resolved url,
     *   type: backends/services, unused
     *   name: id, unused
     *   extensions: {
     *     // contain either a 'services' or 'backends' property, depending on the type
     *     // the extension for the backends and services are not merged together here, but in ProtocolRegistry.
     *   }
     * }
     * @param catalogObject {object} current object in the chain (backend or server) we are following
     * @param serverObj {object} current server object on the chain
     * @param url {string}
     * @param catalogObjectType {string}
     * @param catalogObjectName {string}
     * @param namespace {string}
     * @returns {*}
     * @private
     */
    _createResolvedInfo(foundCatalogObject, serverObj, url, catalogObjectType, catalogObjectName) {
      const catalogObject = foundCatalogObject.catalogObject;
      const namespace = foundCatalogObject.namespace;
      const version = foundCatalogObject.version;
      // 'combine' the headers and transforms of the base object and chosen server object

      // the new services object syntax allows extensions (headers, transforms) defined
      // in the (openapi3) "info.x-vb" section.
      // the old syntax used to just allow a "headers" property at the root. respect them both.
      // old/original:
      //   "services": "myService": { { "headers": {...}, "servers": [...], ...} }
      // new: "services":
      //   { "myServices": { "info": { "x-vb": { "headers": {...}, transforms: {...} } }, "servers": [...], ...}

      const infoExtensions = (catalogObject.info && catalogObject.info[ServiceConstants.VB_EXTENSIONS]) || {};

      // its possible to define 'headers' and 'transforms' outside of an 'x-vb', as top-level props
      // @todo: do we still support the 'top-level' headers/transforms, and should we deprecate these? they ARE used :(
      const topLevelExtensionsForMerge = {
        headers: catalogObject.headers,
        transforms: catalogObject.transforms,
      };

      /* precedence is low to high:
       * - top-level props from catalog object, which only allows "headers" and "transforms"
       * - "info.x-vb" object from the openapi3 fragment in the catalog object
       * - "x-vb" from "servers" array object
       */
      const mergedExtensions = this.mergeExtensions({},
        topLevelExtensionsForMerge,
        infoExtensions,
        serverObj[ServiceConstants.VB_EXTENSIONS] || {});

      const extensionAccess = catalogObjectType === ServiceConstants.ExtensionTypes.SERVICES
        ? (mergedExtensions && mergedExtensions.extensionAccess) === true
        : catalogObject.extensionAccess === true;

      if (foundCatalogObject.path) {
        // in order to be able to resolve relative paths of the transforms files
        // we need to keep track of where were they referenced from
        if (mergedExtensions.transforms) {
          // make sure we are dealing with an object not just a string
          if (typeof mergedExtensions.transforms === 'string') {
            mergedExtensions.transforms = {
              path: mergedExtensions.transforms,
            };
          }
          if (mergedExtensions.transforms.path
            && mergedExtensions.transforms.path.startsWith(Constants.RELATIVE_FOLDER_PREFIX)) {
            mergedExtensions.transforms.origin = {
              path: foundCatalogObject.path,
            };
          }
        }
      }

      const newServerObj = Object.assign({}, {
        namespace,
        version,
        type: catalogObjectType,
        name: catalogObjectName,
        url,
        extensionAccess,
        extensions: {
          [catalogObjectType]: mergedExtensions,
        },
        metadata: {
          [catalogObjectType]: catalogObject.metadata,
        },
        serviceType: catalogObject.serviceType, // used by url mapping
      });

      return newServerObj;
    }

    /**
     * Get a list of named objects of both backends and services, so we can programmatically iterate resolutions of
     * vb-catalog urls.
     * This includes all catalog obejcts, with enough information to resolve URLs.
     *
     * For example: for URL "vb-catalog://services/foo", if service "foo" refers to "vb-catalog://backends//bar",
     * the we could find the service "foo" and backend "bar" in the returned arrays, and resolve URLs.
     *
     * This was the API before the CatalogRegistry existed, and all access was abstracted
     * though the ProtocolRegistry.
     * As the role of Catalog is expanding, that abstraction is becoming awkward,
     * and clients may want to access the CatalogRegistry directly (via ConfigLoader).
     *
     * @todo: we make want to have the clients go directly to the CatalogRegistry in the future, and not through
     * the ProtocolRegistry.
     *
     * @see CatalogRegistry.getAllNames for an example
     *
     * @param {string} [namespace] If value is provided only objects for the given namespace are returned
     * @param {'services'|'backends'|'serviceTypes'|'metadata'} [catalogObjectType] If value is provided
     *  only object of the given type are returned.
     * @returns {Promise<Array<{services: Object[], backends: Object[], namespace: string}>>}
     */
    getNames(namespace, catalogObjectType) {
      if (!namespace) {
        return this.registry.getAllNames();
      }
      return this.registry.getNames(namespace, catalogObjectType)
        .then((names) => {
          // eslint-disable-next-line no-param-reassign
          names.namespace = namespace;
          return [names];
        });
    }

    /*
     *
     * @param id catalogObject name
     * @param catalogObject
     * @param catalogPath
     * @param [protocolRegistry]
     * @returns {Promise<Object>}
     * @see Endpoint#getConfig
     */
    static getEndpointConfiguration(id, catalogObject, catalogPath, protocolRegistry) {
      return Promise.resolve()
        .then(() => {
          const op = OpenApiObjectFactory.get(catalogObject, { definitionUrl: catalogPath });
          const pathObject = op.pathObjects[0];
          if (!pathObject) {
            return null;
          }
          const pathKey = pathObject.name;
          const operationObjectRefs = pathObject.getOperationObjectRefs();
          const operationObjectRef = operationObjectRefs[0];
          if (!operationObjectRef) {
            return null;
          }

          const operationKey = operationObjectRef.name;

          // a stub service, to make sure name (used for proxy url, if needed) is put in the headers
          const service = Endpoint.createEmptyService(id);

          const params = {
            name: `_internal_${id}`, // unused, meaningless
            service,
            protocolRegistry: protocolRegistry || stubProtocolRegistry,
            pathKey,
            pathObject,
            operationKey,
            operationObject: operationObjectRef.get(),
          };

          // At this point we do not have have enough information to correctly calculate proxy/tokenRelay endpoints.
          // To do that we need resolved server metadata, which we do not consider here.
          // That is why we want to skip proxy/tokenRelay metadata from the service as it may later on win over
          // other metadata in be incorrectly used.
          return new Endpoint(params).getConfig({}, { skipTrapExtensions: true });
        });
    }
  }

  /**
   * using a static property so we can clear it in unit tests
   * @type {string|null}
   */
  // eslint-disable-next-line no-underscore-dangle
  CatalogHandler._defaultCatalogPath = undefined;

  CatalogHandler.PROTOCOLS = [CommonConstants.VbProtocols.CATALOG];

  return CatalogHandler;
});

