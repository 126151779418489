'use strict';

define('vb/private/vx/localExtensionAdapter',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/vx/baseExtensionAdapter',
], (
  Constants,
  Utils,
  BaseExtensionAdapter,
) => {
  /**
   * The extension adapter used to load extension from local file
   */
  class LocalExtensionAdapter extends BaseExtensionAdapter {
    /**
     * Load the specified types from the remote extension digest
     * For the local extension adapter, this function consist of loading the .json files mocking
     * the extension manager registry.
     * @param {String[]} types
     * @returns {Array<Promise<Array<Object>>>}
     */
    loadDigests(types) {
      this.log.info('Loading extensions from local file', this.registryUrl);

      const promise = Utils.loadAndParse(this.registryUrl)
        .then((registry) => {
          const reg = registry.extensions || [];
          const ref = {};
          const promises = [];

          // Reference the ui part and build the promises
          const isUnitTest = Utils.isUnitTestMode(this.vbInitConfig);
          reg.forEach((ext) => {
            ref[ext.id] = ext;
            if (isUnitTest) {
              // in unit tests if available use 'baseUrl-unitTest' for loading extensions
              promises.push(Utils.loadAndParse(`${ext['baseUrl-unitTest'] || ext.baseUrl}/manifest.json`));
            } else {
              promises.push(Utils.loadAndParse(`${ext.baseUrl}/manifest.json`));
            }
          });

          // Load all the vb-extension.json
          return Promise.all(promises).then((extensions) => {
            const manifest = {};
            if (types.includes(Constants.Digest.RUNTIME)) {
              manifest[Constants.Digest.RUNTIME] = [];
            }
            if (types.includes(Constants.Digest.APP_UI_INFO)) {
              manifest[Constants.Digest.APP_UI_INFO] = [];
            }
            if (types.includes(Constants.Digest.REQUIREJS_INFO)) {
              manifest[Constants.Digest.REQUIREJS_INFO] = [];
            }
            if (types.includes(Constants.Digest.PWA_INFO)) {
              manifest[Constants.Digest.PWA_INFO] = [];
            }

            extensions.forEach((ext) => {
              if (manifest[Constants.Digest.RUNTIME]) {
                // Copy only those manifest properties that the backend extension manager copies.
                // https://confluence.oraclecorp.com/confluence/display/ABCS/Visual+Extension+REST+API
                // Copy offline from manifest.json
                // Filed https://bug.oraclecorp.com/pls/bug/webbug_edit.edit_info_top?rptno=36816666
                // Enh 36816666 - COPY 'OFFLINE' FLAG FROM MANIFEST.JSON TO RUNTIME DIGEST
                const runtime = {
                  id: ext.id,
                  version: ext.version,
                  description: ext.description,
                  offline: ext.offline,
                };

                const vbFiles = ext[Constants.Runtime.VB_FILES];
                if (vbFiles) {
                  runtime.files = this.convertVbFiles(vbFiles);
                } else {
                  runtime.files = ext.files;
                }

                // Insert the baseUrl part in the extension
                // in unit tests if available use 'baseUrl-unitTest' for loading extensions
                runtime.baseUrl = (isUnitTest && ref[ext.id]['baseUrl-unitTest']) || ref[ext.id].baseUrl;

                // Transform from the Array format expected by vb-build into the Object format returned by
                // the backend extension manager and expected by RT extension manager.
                if (Array.isArray(ext.dependencies)) {
                  runtime.dependencies = {};
                  ext.dependencies.forEach(({ id, version }) => {
                    runtime.dependencies[id] = version;
                  });
                } else {
                  runtime.dependencies = ext.dependencies;
                }

                // Transform from the Array format expected by vb-build into the Object format returned by
                // the backend extension manager and expected by RT extension manager.
                if (Array.isArray(ext.dependencies)) {
                  const dependenciesObj = {};
                  ext.dependencies.forEach(({ id, version }) => {
                    dependenciesObj[id] = version;
                  });
                  ext.dependencies = dependenciesObj;
                }

                /* eslint-enable no-param-reassign */
                manifest[Constants.Digest.RUNTIME].push(runtime);
              }

              if (manifest[Constants.Digest.APP_UI_INFO] && ext[Constants.Digest.APP_UI_INFO]) {
                manifest[Constants.Digest.APP_UI_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.APP_UI_INFO].metadata,
                });
              }

              if (manifest[Constants.Digest.REQUIREJS_INFO] && ext[Constants.Digest.REQUIREJS_INFO]) {
                manifest[Constants.Digest.REQUIREJS_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.REQUIREJS_INFO].metadata,
                });
              }

              if (manifest[Constants.Digest.PWA_INFO] && ext[Constants.Digest.PWA_INFO]) {
                manifest[Constants.Digest.PWA_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.PWA_INFO].metadata,
                });
              }
            });

            return manifest;
          });
        });

      return types.map((type) => promise.then((manifest) => manifest[type]));
    }
  }

  return LocalExtensionAdapter;
});

