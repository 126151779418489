'use strict';

define('vb/private/vx/registryConfig',[
  'vbc/private/utils',
  'vb/private/constants',
  'vb/private/vx/noFetchExtensionAdapter',
  'vb/private/vx/endpointExtensionAdapter',
  'vb/private/vx/remoteExtensionAdapter',
  'vb/private/vx/extensionRegistry',
], (Utils, Constants, NoFetchExtensionAdapter, EndpointExtensionAdapter, RemoteExtensionAdapter, ExtensionRegistry) => {
  /**
   * A config object with the class name for the extension adapter to use
   * as well as the registry URL and the extension manager
   */
  class RegistryConfig {
    /**
     * @param {Object} vbInitConfig
     * @param {String} digestState = 'partial'
     */
    constructor(vbInitConfig, digestState = Constants.DigestState.PARTIAL) {
      this.localAdapter = null;
      this.digestLoader = null;
      this.fetchManifestPromise = null;
      this.digestState = digestState;

      // This is when using the single AppUi digest. It depends on a fetch promise already defined
      // in index.html.
      if (digestState === Constants.DigestState.PARTIAL && vbInitConfig.DIGEST_PROMISE instanceof Promise) {
        this.digestLoader = new NoFetchExtensionAdapter();
        this.fetchManifestPromise = this.digestLoader.convertDigestPromise();
        return;
      }

      this.digestState = Constants.DigestState.FULL;
      // Preference is given to the DIGEST_ENDPOINT property set when using SUIS
      this.url = Utils.cleanString(vbInitConfig.DIGEST_ENDPOINT);

      if (this.url) {
        this.digestLoader = new EndpointExtensionAdapter(this.url);
      }

      // If it's not using DIGEST_ENDPOINT, it might be using REGISTRY_URL
      if (!this.url) {
        this.url = Utils.cleanString(vbInitConfig.REGISTRY_URL);

        if (this.url) {
          if (this.url.indexOf('http') === 0) {
            this.digestLoader = new RemoteExtensionAdapter(this.url);
          } else {
            this.localAdapter = 'vb/private/vx/localExtensionAdapter';
          }
        }
      }

      // For DIGEST_ENDPOINT and REGISTRY_URL case, we can initiate the fetch immediately
      if (this.digestLoader) {
        // Only store the promise, don't block on it.
        this.fetchManifestPromise = this.digestLoader.initiateFetchManifest();
      }
    }

    async init() {
      // Temporary support for local adapter
      if (!this.digestLoader && this.url && this.localAdapter) {
        const Adapter = await Utils.getResource(this.localAdapter);
        this.digestLoader = new Adapter(this.url);
        // Only store the promise, don't block on it.
        this.fetchManifestPromise = this.digestLoader.initiateFetchManifest();
      }
    }

    /**
     * @return {ExtensionRegistry}
     */
    createExtensionRegistry() {
      return new ExtensionRegistry(this);
    }

    /**
     * Return true if the application is using a registry
     * @return {Boolean}
     */
    hasRegistry() {
      return !!this.digestLoader;
    }
  }

  return RegistryConfig;
});

