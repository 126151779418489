// eslint-disable-next-line max-classes-per-file
'use strict';

define('vb/private/stateManagement/storeManager',['vb/private/utils'],
  (Utils) => {
    class Store {
      constructor(scope) {
        this._state = {};

        // Initialize each variable with their initial state
        scope.variablesDef.forEach((variable) => {
          const property = variable.stateProperty;
          this._state[property] = variable.getInitialState();
        });

        // All the properties are created and the shape and configurability of
        // the object will not change, so seal it.
        Object.seal(this._state);
      }

      getState(variable) {
        return this._state[variable.stateProperty];
      }

      updateState(variable, value) {
        const property = variable.stateProperty;

        if (this._state[property] !== value) {
          this._state[property] = Utils.cloneObject(value);
        }

        variable.trigger();
      }
    }

    /**
     * A base class to manage store.
     */
    class StoreManager {
      /**
       * Add a scope to the store.
       * @param {Scope} scope a scope object
       */
      static addScopeToStore(scope) {
        if (scope.isStoreInitialized) {
          throw new Error(`Scope ${scope.name} already has store.`);
        }

        // If no variable in the scope yet, nothing to do
        if (Object.keys(scope.variablesDef).length === 0) {
          return;
        }

        scope.syncWithStore(new Store(scope));
      }
    }

    return StoreManager;
  });

