'use strict';

define('vb/private/services/definition/catalogFactory',[
  'vb/private/services/definition/openApiServiceDefFactory',
  'vb/private/services/definition/serviceDefFactory',
  'vb/private/services/serviceUtils',
  'vbc/private/constants',
], (
  OpenApiServiceDefFactory,
  ServiceDefFactory,
  ServiceUtils,
  CommonConstants,
) => {
  const TYPE = ServiceDefFactory.TYPES.CATALOG_TYPE;

  /**
   * Class responsible finding and loading ServiceDefinitions declared in catalog.json files.
   */
  class CatalogFactory extends OpenApiServiceDefFactory {
    constructor(services, options) {
      super(services, options);
      const {
        extensions,
      } = options;

      // map of namespace => catalog-path for this factory
      this._catalogPaths = extensions && extensions.catalogPaths;

      // flag indicating that the factory catalogs were loaded by the CatalogRegistry
      this._catalogLoaded = false;

      this._initializeServiceMapPromise = null;
    }

    /**
     * Loads service declarations from catalog entries.
     * @override
     */
    updateServiceDeclarations() {
      if (!this._initializeServiceMapPromise) {
        const serviceFileMap = this._serviceFileMap;

        // first we load the catalog then add any new service entries to the serviceMap
        this._initializeServiceMapPromise = Promise.resolve()
          // We need to make sure that we register catalog names from the delegates.
          // TODO:  fix this as it is a side effect of searching for a service.
          .then(() => {
            ServiceUtils.registerAllCatalogNames(this.services, this._protocolRegistry);
            const catalogHandler = this._protocolRegistry.getHandler(CommonConstants.VbProtocols.CATALOG);
            return catalogHandler.getNames(this.services.namespace, 'services');
          })
          .then((catalogNames) => {
            // and add catalog "services", if any
            if (catalogNames && catalogNames.length && Array.isArray(catalogNames[0].services)) {
              catalogNames[0].services.forEach((name) => {
                const serviceId = name;
                if (!serviceFileMap[serviceId]) {
                  serviceFileMap[serviceId] = {
                    path: `${CommonConstants.VbProtocols.CATALOG}://services/${serviceId}`,
                    name,
                    type: TYPE,
                  };
                }
              });
            }
          });
      }
      return this._initializeServiceMapPromise.then(() => super.updateServiceDeclarations());
    }

    /**
     * Register this factory's catalog(s) with the CatalogHandler.
     *
     * @param {boolean} immediately If true force loading of the catalog(s) ASAP.
     */
    registerCatalogNames(immediately) {
      if (this._catalogPaths) {
        const catalogHandler = this._protocolRegistry.getHandler(CommonConstants.VbProtocols.CATALOG);
        // we should really have only one _catalogPaths key, which is matching our services namespace
        // see baseExtensionRegistry.loadServicesModel
        Object.keys(this._catalogPaths).forEach((namespace) => {
          // 'key' is (currently) same as extensionId aka this.namespace
          catalogHandler.registerCatalog(this._catalogPaths[namespace], namespace, immediately);
        });
        // makes sure we register it only once
        this._catalogPaths = null;
        this._catalogLoaded = immediately;
      } else if (immediately && !this._catalogLoaded) {
        // we already registered our catalog(s) but not with immediately flag
        // make sure that the catalog is loaded
        const catalogHandler = this._protocolRegistry.getHandler(CommonConstants.VbProtocols.CATALOG);
        catalogHandler.ensureCatalog(this.services.namespace);
        this._catalogLoaded = true;
      }
    }
  }

  CatalogFactory.TYPE = TYPE;
  return CatalogFactory;
});

